#faq_div {
    background-color: #f5f5f5;
}

.section-questions {
    color: #333;
    text-align: center;
    padding: 24px 0 87px 0;
}

.question-custom {
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
    margin-bottom: 0;
}

.question-border-bottom {
    border-bottom: 1px solid #ccc;
}

.question-header-custom {
    padding: 15px 0;
}

.question-body-custom {
    color: #555;
    margin-left: 2%;
    text-align: justify;
}