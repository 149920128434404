#megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .navbar {
  margin: 0;
  background: #fff;
}
#megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu {
  min-height: 42px;
}
#megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .nav,
#megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .dropup,
#megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .dropdown,
#megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .collapse {
  position: static;
}
#megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .navbar-collapse,
#megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .container {
  position: relative;
}
#megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .navbar-collapse {
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  overflow-x: hidden;
  background: #fff;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .mega-menu-fullwidth-collapse {
  padding-left: 0;
  padding-right: 0;
}
#megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .dropdown-menu {
  left: auto;
  z-index: 500;
}
#megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .dropdown-menu > li {
  display: block;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .nav.pull-right
  .dropdown-menu {
  right: 0;
}
#megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .mega-menu-content {
  padding: 20px 30px;
  *zoom: 1;
}
#megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .mega-menu-content:before,
#megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .mega-menu-content:after {
  display: table;
  content: "";
  line-height: 0;
}
#megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .mega-menu-content:after {
  clear: both;
}
#megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .nav > li > a {
  float: none;
  padding: 10px 15px 10px;
  color: #3d3d3d;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: none;
}
#megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .nav > li > a:focus,
#megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .nav > li > a:hover {
  color: #195d8d;
  background-color: #fff;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  li.cc-desktop-dropdown:hover
  > ul.dropdown-menu {
  display: block;
  top: auto;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .nav
  li.dropdown.open
  > .dropdown-toggle {
  background: #fff;
  color: #195d8d;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .nav
  li.dropdown
  > .dropdown-toggle
  .caret {
  border-top-color: #3d3d3d;
  border-bottom-color: #3d3d3d;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .nav
  li.dropdown.open
  > .dropdown-toggle
  .caret {
  border-top-color: #195d8d;
  border-bottom-color: #195d8d;
  color: #fff;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .nav
  li.dropdown
  > a:hover
  .caret,
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .nav
  li.dropdown
  > a:focus
  .caret {
  border-top-color: #195d8d;
  border-bottom-color: #195d8d;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .nav
  > li
  > .dropdown-menu:after,
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .nav
  > li
  > .dropdown-menu:before {
  display: none;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .dropdown.mega-menu-fullwidth
  .dropdown-menu {
  background: #fff;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  margin: 0 -1px;
  min-height: 250px;
  padding: 20px 0 20px 10px;
  left: 0;
  right: 0;
  border-top: #195d8d solid 3px;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .dropdown.mega-menu-fullwidth
  .dropdown-menu
  a {
  color: #3d3d3d;
  font-size: 12px;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .dropdown.mega-menu-fullwidth
  .dropdown-menu
  a:hover {
  color: #195d8d;
  text-decoration: none;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .dropdown.mega-menu-fullwidth
  .dropdown-menu
  .category-header {
  font-weight: bold;
  display: block;
  height: 100%;
  text-transform: uppercase;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .dropdown.mega-menu-fullwidth
  .dropdown-menu
  .child-category-container {
  min-height: 5em;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .dropdown.mega-menu-fullwidth
  .dropdown-menu
  .child-category-container
  ul {
  margin-left: 15px;
  padding-left: 0;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .dropdown.mega-menu-fullwidth
  .dropdown-menu
  .child-category-container
  ul
  li {
  padding-top: 5px;
  color: #195d8d;
  padding-left: 10px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .dropdown.mega-menu-fullwidth
  .dropdown-menu
  .child-category-container
  ul
  li:before {
  margin: 9px 0 0 -23px;
  vertical-align: middle;
  display: inline-block;
  width: 4px;
  height: 4px;
  background: #000;
  content: "";
  position: absolute;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .dropdown.mega-menu-fullwidth
  .dropdown-menu
  .child-category-container
  ul
  ul {
  margin-left: 10px;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .dropdown.mega-menu-fullwidth
  .dropdown-menu
  .child-category-container
  ul
  ul
  li {
  padding-top: 5px;
  color: #195d8d;
  padding-left: 10px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .dropdown.mega-menu-fullwidth
  .dropdown-menu
  .child-category-container
  a:hover {
  background-color: #fff;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .dropdown.mega-menu-fullwidth
  .dropdown-menu
  .child-category-container:nth-child(7),
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .dropdown.mega-menu-fullwidth
  .dropdown-menu
  .child-category-container:nth-child(13),
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .dropdown.mega-menu-fullwidth
  .dropdown-menu
  .child-category-container:nth-child(19) {
  clear: both;
}
#megaMenu_v6-megaMenuInst_v6
  #CC-megaMenu
  .mega-menu
  .dropdown.mega-menu-fullwidth
  .dropdown-menu
  .dropdown-menu
  .last {
  margin-left: 0;
}
@media (max-width: 991px) {
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .mega-menu
    .dropdown.mega-menu-fullwidth
    .dropdown-menu {
    width: auto;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .mega-menu-content {
    padding-left: 0;
    padding-right: 0;
  }
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .mega-menu
    .dropdown-menu
    > li
    > ul {
    display: block;
  }
}
@media (max-width: 991px) {
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu div.navbar-inner {
    background: none;
    background-image: none;
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .navbar {
    margin: 0;
    background: #fff;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .navbar .navbar-toggle {
    display: block;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .navbar .icon-bar {
    background-image: none;
    color: #333;
    background-color: #fff;
  }
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .navbar
    .btn-navbar:hover
    .icon-bar {
    background: #fff;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .navbar .btn-navbar,
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .navbar .btn-navbar:hover,
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .navbar .btn-navbar:focus {
    background: #333;
    border: 1px solid #333;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.065);
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.65);
    margin-right: 0;
    float: left;
    padding: 7px 10px;
    margin-left: 0;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu #mobile-menu {
    background: #fff;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    width: 100%;
    left: 0;
    right: 0;
    color: #333;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu #mobile-menu.collapse {
    display: none !important;
    overflow: hidden !important;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu #mobile-menu.collapse.in {
    display: block !important;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .navbar-nav {
    float: none !important;
    margin: 7.5px -15px !important;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    border: 0;
    box-shadow: none;
  }
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .navbar-nav
    .open
    .dropdown-menu
    > li
    > a {
    line-height: 20px;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .nav > li {
    float: none;
    display: block;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .nav > li > a {
    border-top: 1px solid #282b2d;
    padding: 15px 5px;
    margin: 0;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .nav > li > a:hover {
    color: #195d8d;
    background-color: #fff;
    text-decoration: none;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    border: 0;
    box-shadow: none;
  }
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .navbar-nav
    .open
    .dropdown-menu
    > li
    > a {
    line-height: 20px;
  }
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .navbar-nav
    .open
    .dropdown-menu
    > li
    > a,
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .navbar-nav
    .open
    .dropdown-menu
    .dropdown-header {
    padding: 5px 15px 5px 25px;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .navbar-collapse {
    max-height: none;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .nav-collapse .nav {
    float: none;
    margin: 0;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .nav-collapse .dropdown-menu a {
    padding-left: 0;
  }
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .nav-collapse
    .dropdown-menu
    li
    + li
    a {
    margin-bottom: 0;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .nav-collapse .nav > li > a:hover,
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .nav-collapse .nav > li > a:focus,
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .nav-collapse
    .dropdown-menu
    a:hover,
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .nav-collapse
    .dropdown-menu
    a:focus {
    background-color: transparent;
  }
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .mega-menu
    .dropdown.mega-menu-fullwidth
    .dropdown-menu {
    min-height: 20px;
    padding: 0;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .nav-collapse .nav > li > a:hover,
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .nav-collapse .nav > li > a:focus,
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .nav-collapse
    .dropdown-menu
    a:hover,
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .nav-collapse
    .dropdown-menu
    a:focus {
    background-color: #fff;
  }
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .nav-collapse
    .nav
    > li
    > a:hover.Level2,
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .nav-collapse
    .nav
    > li
    > a:focus.Level2,
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .nav-collapse
    .dropdown-menu
    a:hover.Level2,
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .nav-collapse
    .dropdown-menu
    a:focus.Level2 {
    background-color: #fff;
  }
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .nav-collapse
    .nav
    > li
    > a:hover.Level3,
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .nav-collapse
    .nav
    > li
    > a:focus.Level3,
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .nav-collapse
    .dropdown-menu
    a:hover.Level3,
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .nav-collapse
    .dropdown-menu
    a:focus.Level3 {
    background-color: #fff;
  }
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .mega-menu
    .dropdown.mega-menu-fullwidth
    .dropdown-menu
    .first {
    margin-left: 0;
  }
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .mega-menu
    .dropdown.mega-menu-fullwidth
    .dropdown-menu
    .category-header {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border: none;
    font-weight: bold;
    padding: 0 10px 0 0;
    height: 100%;
  }
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .mega-menu
    .dropdown.mega-menu-fullwidth
    .dropdown-menu
    .caret {
    border-top-color: #195d8d;
    border-bottom-color: #195d8d;
  }
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .mega-menu
    .dropdown.mega-menu-fullwidth
    .dropdown-menu
    .child-category-container {
    float: left;
    margin-left: 0;
    min-height: 20px;
    padding-bottom: 20px;
  }
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .dropdown.mega-menu-fullwidth
    .dropdown-menu
    ul
    li {
    padding-left: 10px;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .dropdown-menu > li > a:hover,
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .dropdown-menu > li > a:focus,
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .dropdown-submenu:hover > a,
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .dropdown-submenu:focus > a {
    background-image: none;
    background: transparent;
  }
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .mega-menu
    .dropdown.mega-menu-fullwidth
    .dropdown-menu {
    background: #fff;
    color: #6f7178;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .mega-menu .dropdown-menu > li a {
    padding-left: 10px;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu .submenu > li a {
    padding-left: 20px;
  }
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .mega-menu
    .dropdown-menu
    .open
    > a:after {
    border: none;
    display: inline-block;
    width: 0;
    height: 0;
    vertical-align: top;
    border-top: 6px solid #000;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    content: "";
    border-top-color: #dfdfdf;
    border-bottom-color: #dfdfdf;
    color: #195d8d;
  }
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .mega-menu
    .dropdown.mega-menu-fullwidth
    .dropdown-menu
    .child-category-container
    a:hover {
    background-color: #fff;
  }
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .mega-menu
    .dropdown.mega-menu-fullwidth
    .dropdown-menu
    > a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    border-left-color: #ccc;
    margin-top: 5px;
    margin-right: -10px;
  }
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .mega-menu
    .dropdown.mega-menu-fullwidth
    .dropdown-menu
    a.Level3:after {
    display: none;
  }
  #megaMenu_v6-megaMenuInst_v6 #CC-megaMenu li.dropdown-menu.submenu.closed ul {
    display: none;
  }
}
@media (max-width: 480px) {
  #megaMenu_v6-megaMenuInst_v6
    #CC-megaMenu
    .mega-menu
    .dropdown.mega-menu-fullwidth
    .dropdown-menu
    .child-category-container {
    float: none;
    margin-left: 10px;
  }
}
#notifications_v2-notificationsInst #cc-notifications #cc-notifications-panel {
  left: 0;
  right: 0;
}
#notifications_v2-notificationsInst #cc-notifications.fixed-to-top {
  position: fixed;
  top: 0;
  z-index: 1000;
}
#footer-footerInst #CC-footerWidget .footer-region .footer {
  padding: 0 0 0 0;
}
#footer-footerInst #CC-footerWidget .footer-region .footer p {
  margin-bottom: 0;
  color: #ccc;
  font-size: 0.9em;
}
#footer-footerInst #CC-footerWidget .footer-region .footer-btm {
  padding: 0 0 0 0;
  font-size: 0.9em;
}
#footer-footerInst #CC-footerWidget .footer-region .footer-padding {
  padding: 10px 0 10px 0;
}
#footer-footerInst #CC-footerWidget .footer-region .footer-links {
  margin: 10px 0;
  padding-bottom: 10px;
}
#headerWidget_v11-headerWidgetInst #region-header {
  position: static;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget .header-line {
  left: 0;
  right: 0;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget .header-background {
  min-height: 20px;
  padding-top: 25px;
  font-size: 11.9px;
  left: 0;
  right: 0;
  z-index: 2;
}
@media (max-width: 767px) {
  #headerWidget_v11-headerWidgetInst #CC-headerWidget .header-background {
    background: #fff;
  }
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #header {
  position: relative;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #header
  a[class*="btn-"]:hover {
  color: #fff;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #header
  a[class="cc-button-primary"]:hover {
  color: #fff;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #header
  a[class="cc-button-secondary"]:hover {
  color: #3d3d3d;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget .header-links {
  padding-bottom: 10px;
  padding-top: 10px;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget .sub-links {
  margin: 10px 0;
  display: block;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget .sub-links li {
  display: inline;
  margin-right: 10px;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget .sub-links-mobile {
  margin: 0 0 15px 0;
  display: block;
  text-align: center;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget .sub-links-mobile li {
  display: inline;
  margin-right: 10px;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget .logo {
  margin: 0;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 0;
  vertical-align: middle;
}
@media (max-width: 767px) {
  #headerWidget_v11-headerWidgetInst #CC-headerWidget .logo {
    margin-top: 5px;
    margin-bottom: 20px;
  }
}
/* #headerWidget_v11-headerWidgetInst #CC-headerWidget .bottom-line {
  background: url(/img/header-btm-line.png) center bottom no-repeat;
  padding-bottom: 35px;
  position: relative;
  z-index: 1;
} */
#headerWidget_v11-headerWidgetInst #CC-headerWidget .cc-center {
  display: table;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  #headerWidget_v11-headerWidgetInst #CC-headerWidget .cc-modal-dialog {
    width: 400px;
  }
}
@media (max-width: 767px) {
  #headerWidget_v11-headerWidgetInst #CC-headerWidget .cc-message-box {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media (max-width: 979px) {
  #headerWidget_v11-headerWidgetInst
    #CC-headerWidget
    .storefront-modal-controls {
    width: 90%;
  }
  #headerWidget_v11-headerWidgetInst
    #CC-headerWidget
    .storefront-modal-controls
    input.span5 {
    width: 100%;
  }
  #headerWidget_v11-headerWidgetInst
    #CC-headerWidget
    .storefront-modal-controls
    div.span5 {
    width: 97%;
    margin-left: 6%;
  }
}
@media (min-width: 980px) {
  #headerWidget_v11-headerWidgetInst
    #CC-headerWidget
    .storefront-modal-controls {
    width: 90%;
  }
  #headerWidget_v11-headerWidgetInst
    #CC-headerWidget
    .storefront-modal-controls
    input.span5 {
    width: 100%;
  }
  #headerWidget_v11-headerWidgetInst
    #CC-headerWidget
    .storefront-modal-controls
    div.span5 {
    width: 97%;
    margin-left: 6%;
  }
}
@media (max-width: 480px) {
  #headerWidget_v11-headerWidgetInst
    #CC-headerWidget
    .storefront-modal-controls {
    width: 90%;
  }
  #headerWidget_v11-headerWidgetInst
    #CC-headerWidget
    .storefront-modal-controls
    input.span5 {
    width: 100%;
  }
  #headerWidget_v11-headerWidgetInst
    #CC-headerWidget
    .storefront-modal-controls
    div.span5 {
    width: 97%;
    margin-left: 6%;
  }
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget .text-underline {
  text-decoration: underline;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #CC-header-checkout {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  border-radius: 4px;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget .cc-cartlink-xs {
  padding: 15px 0 0 0;
  vertical-align: bottom;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget .skip-content button {
  text-align: center;
  float: left;
  width: 0;
  height: 0;
  position: relative;
  left: -999em;
  border: solid #d4d4d4;
  background-color: #fff;
  z-index: 1000;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget .text-muted {
  color: #696969;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #cc-btn-skipToContent-desktop {
  margin-top: -22px;
  margin-bottom: -15px;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #cc-btn-skipToContent-desktop:focus {
  width: 150px;
  height: 36px;
  left: 13px;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #cc-btn-skipToContent-phone:focus {
  width: 120px;
  height: 21px;
  line-height: 18px;
  left: 0;
  font-size: 12px;
  padding-top: 0;
  padding-bottom: 0;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #cc-btn-skipToContent-desktop:blur,
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #cc-btn-skipToContent-phone:blur {
  left: -999em;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart {
  z-index: 998;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart .heading {
  margin: 0;
  padding: 10px;
  border: 1px solid transparent;
  border-bottom: 0;
  line-height: 1.4;
  text-align: right;
  height: 34px;
  display: inline-block;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart h4 {
  font-size: 11.9px;
  margin: 0;
  padding-bottom: 10px;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart .firstColumn {
  text-align: left !important;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart .heading a {
  text-decoration: none;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #dropdowncart
  .heading
  a:hover {
  text-decoration: underline;
}
/* #headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart #cart-total {
  background: transparent url("/img/arrow_down_dark.png") 100% 50% no-repeat;
  cursor: pointer;
} */
#headerWidget_v11-headerWidgetInst #CC-headerWidget #cart-total > span {
  padding-right: 12px;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart .content {
  display: none;
  position: absolute;
  right: 0;
  min-height: 140px;
  min-width: 360px;
  margin-top: -1px;
  margin-right: 15px;
  padding: 16px;
  border: 1px solid #e3e3e3;
  background: #fff;
  webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  width: 100%;
}
@media (min-width: 981px) {
  #headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart .content {
    width: 430px;
  }
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #dropdowncart
  .cc-text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart .table th {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  padding: 2px;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart .table td {
  text-align: center;
  padding: 2px;
  word-break: break-word;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart .table {
  margin-bottom: 20px;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart .table tbody {
  border-bottom: 1px solid #e3e3e3;
  height: 80px;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #dropdowncart
  .dropdowncartItems {
  padding-top: 2px;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart .scroll {
  background-color: #e3e3e3;
  border: 1px solid #999;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart .linkTag {
  display: block;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #dropdowncart
  .scrollActive {
  color: #000;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #dropdowncart
  .scrollInactive {
  color: #a9a8a8;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #dropdowncart
  .scrollDivActive {
  background-color: #fff;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart .fa {
  font-size: 2.5em;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart .shiftRight {
  right: 10px;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #dropdowncart
  .item
  .imageSize {
  max-height: 75px;
  max-width: 75px;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart .item {
  border-bottom: 1px dashed #e3e3e3;
  height: 80px;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #dropdowncart
  .item-style
  .imageSize {
  max-height: 75px;
  max-width: 75px;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart .item-style {
  height: 85px;
  padding-top: 5px;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #dropdowncart
  .item-content {
  border-bottom: 1px solid #e3e3e3;
  height: 80px;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #dropdowncart
  .item-no-content {
  border-bottom: 1px solid #e3e3e3;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #dropdowncart.active
  .content {
  display: block !important;
  display: none;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #dropdowncart.active
  .heading {
  background: #fff;
  border: 1px solid #e3e3e3;
  border-bottom: 0;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  z-index: 1;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart .total {
  border-collapse: collapse;
  padding: 5px;
  float: right;
  clear: left;
  margin-bottom: 8px;
  text-align: right;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #dropdowncart
  .total
  td.labels {
  padding-right: 6px;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart .total th {
  padding-right: 6px;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #dropdowncart
  .content
  .checkout {
  text-align: right;
  clear: both;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart .empty {
  padding-top: 50px;
  text-align: center;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #closecart {
  float: left;
  position: absolute;
  bottom: 6px;
  left: 9px;
  display: none;
  opacity: 0.65;
  -moz-opacity: 0.65;
  filter: alpha(opacity=65);
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget .removeFromCartIcon {
  padding: 0;
  cursor: pointer;
  background: transparent;
  vertical-align: text-bottom;
  border: 0;
  -webkit-appearance: none;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #dropdowncart
  .gwpQualifiedMessage {
  border-bottom: 1px solid #e3e3e3;
  padding-bottom: 20px;
  padding-top: 7px;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #dropdowncart .giftIcon {
  font-size: 5em;
  padding-left: 5px;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #languagedropdown {
  z-index: 999;
  position: relative;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #languagedropdown .heading {
  margin: 0;
  padding: 10px;
  border: 1px solid transparent;
  border-bottom: 0;
  line-height: 0;
  text-align: right;
  height: 25px;
  display: inline-block;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #languagedropdown h4 {
  font-size: 11.9px;
  margin: 0;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #languagedropdown
  .heading
  a {
  text-decoration: none;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #languagedropdown
  .heading
  a:hover {
  text-decoration: underline;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #languagedropdown .content {
  display: none;
  position: absolute;
  right: 0;
  min-height: 60px;
  min-width: 160px;
  margin-top: -1px;
  margin-right: 0;
  padding: 16px;
  border: 1px solid #e3e3e3;
  background: #fff;
  webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  overflow: scroll;
  max-height: 450px;
  width: 100%;
  overflow: auto;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #languagedropdown.active
  .content {
  display: block !important;
  display: none;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #languagedropdown.active
  .heading {
  background: #fff;
  border: 1px solid #e3e3e3;
  border-bottom: 0;
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  z-index: 1;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #languagedropdown .empty {
  padding-top: 50px;
  text-align: center;
}
#headerWidget_v11-headerWidgetInst #CC-headerWidget #headerCurrencyPicker {
  z-index: 999;
  position: absolute;
  padding-left: 5px;
  padding-right: 2px;
  border: 1px solid #fff;
  width: 74px;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #headerCurrencyPicker
  .heading
  span:hover,
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #headerCurrencyPicker
  .content
  a
  span:hover {
  text-decoration: underline;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #headerCurrencyPicker.active {
  position: absolute;
  background: #fff;
  border: 1px solid #eee;
  webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  z-index: 999;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #headerCurrencyPicker
  .caret {
  color: #b2b2b2;
  border-width: 6px;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #headerCurrencyPicker
  .paddingBottom {
  padding-bottom: 3px;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #headerCurrencyPicker
  .currencyCodeWidth {
  display: inline-block;
  width: 26px;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #headerCurrencyPicker
  #CC-header-currency-link {
  background: #fff;
}
#headerWidget_v11-headerWidgetInst
  #CC-headerWidget
  #headerCurrencyPicker
  .content {
  background: #fff;
  overflow: auto;
  max-height: 450px;
  width: 100%;
}
@media (max-width: 767px) {
  #headerWidget_v11-headerWidgetInst #CC-headerWidget #headerCurrencyPicker {
    right: 65px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  #headerWidget_v11-headerWidgetInst #CC-headerWidget #headerCurrencyPicker {
    width: 70px;
    left: 1px;
  }
}
