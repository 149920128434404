/* @import "/file/thirdparty/css/fontawesome.min.css";
@import "/file/thirdparty/css/slick.min.css";
@import "/file/thirdparty/css/slick-theme.min.css"; */
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
.hide {
  display: none !important;
}
.show {
  display: block !important;
}
.invisible {
  visibility: hidden;
}
.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.hidden {
  display: none !important;
  visibility: hidden !important;
}
.affix {
  position: fixed;
}
.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.btn:hover,
.btn:focus {
  color: #333;
  text-decoration: none;
}
.btn:active,
.btn.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  color: #333;
  background-color: #ebebeb;
  border-color: #adadad;
}
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
  background-color: #fff;
  border-color: #ccc;
}
.btn-default .badge {
  color: #fff;
  background-color: #333;
}
.btn-primary {
  color: #fff;
  background-color: #428bca;
  border-color: #357ebd;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #3276b1;
  border-color: #285e8e;
}
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #428bca;
  border-color: #357ebd;
}
.btn-primary .badge {
  color: #428bca;
  background-color: #fff;
}
.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  color: #fff;
  background-color: #47a447;
  border-color: #398439;
}
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-image: none;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #5cb85c;
  border-color: #4cae4c;
}
.btn-success .badge {
  color: #5cb85c;
  background-color: #fff;
}
.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  color: #fff;
  background-color: #39b3d7;
  border-color: #269abc;
}
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-image: none;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #5bc0de;
  border-color: #46b8da;
}
.btn-info .badge {
  color: #5bc0de;
  background-color: #fff;
}
.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #ed9c28;
  border-color: #d58512;
}
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-image: none;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  background-color: #f0ad4e;
  border-color: #eea236;
}
.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}
.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #d2322d;
  border-color: #ac2925;
}
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-image: none;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-danger .badge {
  color: #d9534f;
  background-color: #fff;
}
.btn-link {
  color: #428bca;
  font-weight: normal;
  cursor: pointer;
  border-radius: 0;
}
.btn-link,
.btn-link:active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  border-color: transparent;
}
.btn-link:hover,
.btn-link:focus {
  color: #2a6496;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled] .btn-link:focus {
  color: #999;
  text-decoration: none;
}
.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
}
.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-block {
  display: block;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.btn-block + .btn-block {
  margin-top: 5px;
}
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}
.datepicker {
  padding: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  direction: ltr;
}
.datepicker-inline {
  width: 220px;
}
.datepicker.datepicker-rtl {
  direction: rtl;
}
.datepicker.datepicker-rtl table tr td span {
  float: right;
}
.datepicker-dropdown {
  top: 0;
  left: 0;
}
.datepicker-dropdown:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}
.datepicker-dropdown:after {
  content: "";
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-top: 0;
  position: absolute;
}
.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}
.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}
.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}
.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}
.datepicker-dropdown.datepicker-orient-top:before {
  top: -7px;
}
.datepicker-dropdown.datepicker-orient-top:after {
  top: -6px;
}
.datepicker-dropdown.datepicker-orient-bottom:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999;
}
.datepicker-dropdown.datepicker-orient-bottom:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}
.datepicker > div {
  display: none;
}
.datepicker.days div.datepicker-days {
  display: block;
}
.datepicker.months div.datepicker-months {
  display: block;
}
.datepicker.years div.datepicker-years {
  display: block;
}
.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.datepicker td,
.datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: none;
}
.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}
.datepicker table tr td.day:hover {
  background: #eee;
  cursor: pointer;
}
.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #999;
}
.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #999;
  cursor: default;
}
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background: #fdd49a;
  color: #000;
}
.datepicker table tr td.today:hover:hover {
  color: #000;
}
.datepicker table tr td.today.active:hover {
  color: #fff;
}
.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
  background: #eee;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
  background: #f3c17a;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
  background-color: #b3b3b3;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.datepicker table tr td span:hover {
  background: #eee;
}
.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #999;
  cursor: default;
}
.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #999;
}
.datepicker th.datepicker-switch {
  width: 145px;
}
.datepicker thead tr:first-child th,
.datepicker tfoot tr th {
  cursor: pointer;
}
.datepicker thead tr:first-child th:hover,
.datepicker tfoot tr th:hover {
  background: #eee;
}
.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}
.datepicker thead tr:first-child th.cw {
  cursor: default;
  background-color: transparent;
}
.input-append.date .add-on i,
.input-prepend.date .add-on i {
  display: block;
  cursor: pointer;
  width: 16px;
  height: 16px;
}
.input-daterange input {
  text-align: center;
}
.input-daterange input:first-child {
  -webkit-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.input-daterange input:last-child {
  -webkit-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.input-daterange .add-on {
  display: inline-block;
  width: auto;
  min-width: 16px;
  height: 1.42857143;
  padding: 4px 5px;
  font-weight: normal;
  line-height: 1.42857143;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  vertical-align: middle;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px;
}
.input-append .datepicker {
  white-space: normal;
}
.datepicker TABLE THEAD TR td,
.datepicker TABLE THEAD TR th {
  background-color: transparent;
}
.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover,
.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  cursor: not-allowed;
}
.datepicker-dropdown {
  margin-top: 5px !important;
}
.datepicker-dropdown:before {
  top: -7px !important;
  left: 10px !important;
}
.datepicker-dropdown:after {
  top: -6px !important;
  left: 11px !important;
}
.datepicker-dropdown th.prev.disabled,
.datepicker-dropdown th.next.disabled {
  opacity: 0.45;
  filter: alpha(opacity=45);
}
.datepicker-dropdown th.prev.disabled:hover,
.datepicker-dropdown th.next.disabled:hover {
  background-color: transparent;
}
.datepicker-dropdown th.prev i:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f053";
}
.datepicker-dropdown th.next i:before {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f054";
}
.toggleswitch {
  position: relative;
  width: 60px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.toggleswitch .toggleswitch-checkbox {
  display: none;
}
.toggleswitch .toggleswitchdisabled {
  opacity: 0.7 !important;
  cursor: not-allowed !important;
}
.toggleswitch .switchdisabled {
  cursor: not-allowed !important;
}
.toggleswitch .toggleswitch-label {
  display: block;
  overflow: hidden;
  cursor: default;
  border: 1px solid #909095;
  border-radius: 4px;
}
.toggleswitch .toggleswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  cursor: pointer;
}
.toggleswitch .toggleswitch-inner:before,
.toggleswitch .toggleswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 18px;
  padding: 0;
  line-height: 18px;
  font-size: 12px;
  color: #fff;
  font-family: Arial, san-serif;
  font-weight: bold;
  box-sizing: border-box;
}
.toggleswitch .toggleswitch-inner:before {
  font-family: FontAwesome;
  font-size: 1em;
  display: inline-block;
  content: "\f00c";
  padding-left: 0.3em;
  background-color: #195d8e;
  color: #fff;
}
.toggleswitch .toggleswitch-inner:after {
  font-family: FontAwesome;
  font-size: 1em;
  display: inline-block;
  content: "\f00d";
  padding-right: 0.3em;
  background-color: #ccc;
  color: #333;
  text-align: right;
}
.toggleswitch .toggleswitch-switch {
  display: block;
  width: 20px;
  margin: 0;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  cursor: pointer;
  right: 42px;
  border-top: 1px solid #909095;
  border-radius: 4px;
  border-right: 1px solid #909095;
  border-bottom: 1px solid #909095;
  border-left: 1px solid #909095;
  transition: all 0.3s ease-in 0s;
}
.toggleswitch
  .toggleswitch-checkbox:checked
  + .toggleswitch-label
  .toggleswitch-inner {
  margin-left: 0;
}
.toggleswitch
  .toggleswitch-checkbox:checked
  + .toggleswitch-label
  .toggleswitch-switch {
  right: 0;
  border-radius: 4px;
}
#siteHead {
  min-height: 50px;
  padding-top: 20px;
}
#siteBody {
  min-height: 400px;
}
#siteNav {
  min-height: 50px;
}
#siteFooter {
  min-height: 250px;
}
.templateRegion_Active {
  border: #666 dotted 1px;
}
.spanfix {
  min-height: 30px;
  display: block;
  float: left;
  margin-left: 2.12765957%;
  *margin-left: 2.07446809%;
}
.spanfirst {
  margin-left: 0;
}
html,
body {
  height: 100%;
}
body {
  width: 100%;
  color: #3d3d3d;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
#page {
  display: table;
  table-layout: fixed;
  height: 100%;
}
#page.preview {
  padding-top: 45px;
}
.page-row {
  display: table-row;
  height: 1px;
}
.page-row-expanded {
  height: 100%;
}
.page-header {
  margin-top: 0;
  padding-top: 0;
}
.social {
  margin-bottom: 15px;
}
.accordion-heading,
.accordion-toggle {
  background: #f5f5f5;
}
.accordion-inner,
.accordion-body {
  background: #fffefe;
}
.cart {
  font-size: 11.9px;
  padding: 3px 5px;
}
.label-hidden,
.a11y,
.cc-reader-text,
label.inline {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
a {
  outline: 0;
}
.alert-danger,
.alert-error,
.alert-danger h4,
.alert-error h4 {
  color: #a6413f;
}
::-webkit-input-placeholder {
  color: #000;
}
::-moz-placeholder {
  color: #000;
}
:-ms-input-placeholder {
  color: #000;
}
input:-moz-placeholder {
  color: #000;
}
.form-control::-moz-placeholder {
  color: #767676;
}
.form-control:-ms-input-placeholder {
  color: #767676;
}
.form-control::-webkit-input-placeholder {
  color: #767676;
}
.form-control:-moz-placeholder {
  color: #767676;
}
div input.invalid::-moz-placeholder {
  color: #656565;
}
div input.invalid:-ms-input-placeholder {
  color: #656565;
}
div input.invalid::-webkit-input-placeholder {
  color: #656565;
}
div input.invalid:-moz-placeholder {
  color: #656565;
}
div input.invalid,
div textarea.invalid,
div select.invalid {
  color: #373737;
  background-color: #f2dede;
  border-color: #ee5f5b;
}
input.invalid:focus,
textarea.invalid:focus,
select.invalid:focus {
  background-color: #fff;
  border-color: #e9322d;
  -webkit-box-shadow: 0 0 6px #f8b9b7;
  -moz-box-shadow: 0 0 6px #f8b9b7;
  box-shadow: 0 0 6px #f8b9b7;
}
.no-outline {
  outline: 0;
}
.tab-content {
  background: #fff;
  margin: 0;
}
.tab-pane {
  padding: 10px;
}
.nav-tabs {
  margin-bottom: 0;
}
.cc-area-controls {
  padding: 10px;
  background-color: #efefef;
  border: 1px solid #d4d4d4;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  border-radius: 4px 4px 0 0;
}
.cc-list-tab {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}
.dropdown-submenu.closed > ul.dropdown-menu {
  display: none;
}
@media (min-width: 980px) {
  .dropdown-submenu b.caret {
    display: none;
  }
}
@media (max-width: 979px) {
  .dropdown-submenu b.caret {
    display: inline-block;
  }
  .nav li.dropdown.open .caret,
  .nav li.dropdown.open.active .caret,
  .nav li.dropdown.open a:hover .caret {
    border-top-color: #555;
    border-bottom-color: #555;
  }
  .nav-collapse .dropdown-menu {
    margin: 0 0;
  }
}
.accordion-heading,
.accordion-toggle {
  background: #f1fafe;
}
.accordion-inner,
.accordion-body {
  background: #fff;
}
@media (min-width: 1200px) {
  .storefront-container {
    max-width: 970px;
  }
  .storefront-sidenav {
    width: 258px;
  }
}
@media (max-width: 980px) {
  body > .navbar-fixed-top .brand {
    float: left;
    margin-left: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
  .quick-links li {
    display: inline-block;
    margin: 5px;
  }
  .storefront-sidenav {
    top: 0;
    margin-top: 30px;
    margin-right: 0;
  }
}
@media (min-width: 768px) and (max-width: 980px) {
  body {
    padding-top: 0;
  }
  .storefront-sidenav {
    width: 166px;
    margin-top: 20px;
  }
  .storefront-sidenav.affix {
    top: 0;
  }
}
@media (max-width: 767px) {
  body {
    padding: 0;
  }
  .show-grid [class*="span"] {
    margin-bottom: 5px;
  }
  .storefront-sidenav {
    width: auto;
    margin-bottom: 20px;
  }
  .storefront-sidenav.affix {
    position: static;
    width: auto;
    top: 0;
  }
  .footer {
    padding-left: 20px;
    padding-right: 20px;
  }
  .footer p {
    margin-bottom: 9px;
  }
}
@media (max-width: 480px) {
  body {
    padding: 0;
  }
  .footer .pull-right {
    float: none;
  }
}
.row-payments span {
  -moz-box-sizing: border-box;
  display: inline-block;
  width: 31%;
  margin-left: 2.5641%;
  float: left;
  text-align: center;
  min-height: 30px;
  margin-bottom: 10px;
}
.row-payments span.row-first {
  margin-left: 0;
}
.cc-widget-rtl .row-payments span {
  margin-right: 2.5641%;
  margin-left: auto;
  float: right;
}
.cc-widget-rtl .row-payments span.row-first {
  margin-right: 0;
  margin-left: auto;
}
.hidden-widget {
  display: none;
}
#loadingModal .cc-spinner {
  background-color: transparent;
  top: 25%;
}
#loadingModal .cc-spinner-css div {
  background: #fff;
}
.loadingIndicator {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  opacity: 0.8;
  z-index: 10000;
}
.collapse input,
.collapse select {
  visibility: hidden;
}
.collapse.in input,
.collapse select {
  visibility: visible;
}
.strikethroughText {
  text-decoration: line-through;
}
.discountDescText {
  font-size: 10.5px;
  word-wrap: break-word;
  display: block;
}
.discountContainer {
  margin-top: 12px;
}
.sf-display-error {
  display: none;
  clear: both;
  font-size: 0.9em;
  color: #ec0009;
  padding: 4px;
  border: 2px solid #ec0009;
  border-bottom: none;
}
.sf-display-error .sf-error-title {
  font-weight: bold;
}
.sf-error {
  border: 2px solid #ec0009;
}
@media (min-width: 768px) {
  .cc-config-modal-dialog {
    width: 75%;
  }
}
.configFrame {
  height: 800px;
  border: 0 none;
  width: 100%;
}
a.configDetailsLink {
  font-weight: normal !important;
  color: #195d8d !important;
}
a.configDetailsLink:before {
  display: inline;
  content: "\2212";
}
a.configDetailsLink.collapsed:before {
  content: "\2b";
  display: inline;
}
.cc-welcome {
  font-size: 11.9px;
}
@media (min-width: 768px) {
  #CC-headermodalpane .cc-modal-dialog {
    width: 400px;
  }
}
@media (min-width: 300px) {
  #CC-headermodalpane .cc-modal-dialog {
    width: 95%;
  }
}
#CC-headermodalpane .CC-header-modal-footer {
  text-align: center;
  background-color: #fff;
  border-top: 0;
  margin-top: 0;
  border-bottom: 0;
  border-radius: 0 0 6px 6px;
}
#CC-headermodalpane .cc-modal-body {
  padding-bottom: 0;
}
#CC-headermodalpane #CC-signup {
  text-align: center;
  margin-top: 10px;
  padding-bottom: 25px;
}
#CC-headermodalpane #CC-signup hr {
  margin-top: 5px;
  margin-bottom: 5px;
}
#CC-headermodalpane .CC-header-modal {
  max-width: 400px;
  background-color: #fff;
}
#CC-headermodalpane .CC-header-modal-heading {
  text-align: center;
  background-color: #fff;
  border-bottom: 0;
  border-radius: 6px 6px 0 0;
}
#CC-headermodalpane .CC-header-modal-heading h3 {
  margin: 0;
}
#CC-headermodalpane .modal-backdrop {
  background: #fff;
}
#CC-headermodalpane .CC-createAccount-div {
  padding-left: 25px;
  padding-right: 25px;
}
#CC-headermodalpane .cc-error-message {
  font-weight: bold;
  padding: 8px 0 8px 0;
}
#CC-headermodalpane .cc-header-firstName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: inline;
  max-width: 132px;
  padding-right: 3px;
}
#CC-headermodalpane #CC-userLoginSubmit {
  border-radius: 4px;
}
#CC-headermodalpane #CC-userLoginCancel {
  border-radius: 4px;
}
#CC-headermodalpane #CC-loginHeader-createAccount {
  border-radius: 4px;
}
#CC-headermodalpane #CC-userRegistrationSubmit {
  border-radius: 4px;
}
#CC-headermodalpane #CC-userRegistrationCancel {
  border-radius: 4px;
}
#CC-headermodalpane #CC-emailPasswordSubmit,
#CC-headermodalpane #CC-createNewPasswordSave,
#CC-headermodalpane #CC-createNewPasswordMsgContinue {
  border-radius: 4px;
}
#CC-headermodalpane #CC-emailPasswordCancel,
#CC-headermodalpane #CC-messagePaneClose,
#CC-headermodalpane #CC-createNewPasswordCancel {
  border-radius: 4px;
}
#CC-headermodalpane #CC-forgotPassword-message,
#CC-headermodalpane #CC-createNewPasswordMsg-message {
  color: #696969;
}
#CC-headermodalpane #CC-linkForgotPasswd,
#CC-headermodalpane #CC-linkSsoLogin {
  font-size: 0.8em;
}
#CC-headermodalpane #CC-header-forgotPasswd {
  padding-left: 0;
}
#CC-headermodalpane #CC-header-sso-login {
  padding-right: 0;
  text-align: right;
}
.search {
  padding-top: 20px;
  padding-bottom: 20px;
  vertical-align: bottom;
}
@media (max-width: 979px) {
  .search ul.typeahead li,
  .search ul.typeahead li span {
    float: none;
    width: 100%;
    margin-left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  .search ul.typeahead li span.typeaheadProductName {
    float: left;
    display: inline;
    width: 70%;
    padding-left: 0;
    padding-right: 0;
  }
  .search ul.typeahead li span.typeaheadProductPrice {
    float: right;
    display: inline;
    width: 30%;
    padding-right: 0;
    padding-left: 0;
  }
}
.typeahead {
  padding: 10px;
  border: 2px solid #ddd;
}
.typeahead li {
  margin: 0;
}
.search ul.typeahead li,
.search ul.typeahead li,
.search ul.typeahead li.active,
.search ul.typeahead li.active {
  background: #fff;
}
.form-search div.input-append {
  text-align: right;
}
.typeahead li a {
  padding: 10px;
}
.typeahead li a:before,
.typeahead li a:after {
  content: " ";
  display: table;
}
.typeahead li a:after {
  clear: both;
}
.typeahead li a:before,
.typeahead li a:after {
  content: " ";
  display: table;
}
.typeahead li a:after {
  clear: both;
}
.typeaheadAllProducts {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 992px) {
  .typeaheadAllProducts {
    float: left;
    width: 66.66666667%;
  }
}
.typeaheadClose {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 30px;
  display: block;
  float: left;
  margin-left: 2.12765957%;
  *margin-left: 2.07446809%;
  float: right !important;
}
@media (min-width: 992px) {
  .typeaheadClose {
    float: left;
    width: 33.33333333%;
  }
}
.typeahead li.typeaheadAllProducts a {
  text-align: left;
  padding: 5px 10px;
  margin-bottom: 5px;
  color: #195d8d;
  white-space: normal;
}
.typeahead li.typeaheadClose a {
  text-align: right;
  padding: 5px 10px;
  margin-bottom: 5px;
  color: #195d8d;
  white-space: normal;
}
@media (max-width: 979px) {
  .typeahead li.typeaheadClose a {
    padding-right: 0;
    padding-left: 0;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.typeahead li.typeaheadAllProducts.active a,
.typeahead li.typeaheadClose.active a {
  color: #fff;
}
.typeahead li.typeaheadClose a {
  text-align: right;
}
.typeahead .typeaheadProduct {
  border-bottom: 1px dotted #ddd;
}
.typeahead .firstResult {
  clear: both;
  border-top: 1px dotted #ddd;
}
.typeaheadProductThumbnail {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 30px;
  display: block;
  float: left;
  margin-left: 2.12765957%;
  *margin-left: 2.07446809%;
  margin-left: 0;
}
@media (min-width: 992px) {
  .typeaheadProductThumbnail {
    float: left;
    width: 25%;
  }
}
.typeaheadProductName {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  white-space: normal;
  padding-top: 5px;
  word-wrap: break-word;
}
@media (min-width: 992px) {
  .typeaheadProductName {
    float: left;
    width: 50%;
  }
}
.typeaheadProductPrice {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: right !important;
  text-align: right;
  padding-left: 0;
  padding-right: 17px;
  padding-top: 5px;
  padding-bottom: 0;
  white-space: normal;
}
@media (min-width: 992px) {
  .typeaheadProductPrice {
    float: left;
    width: 25%;
  }
}
#CC-headerWidget-Search-Mobile {
  font-size: 16px;
}
#CC-headerWidget-Search-Desktop {
  font-size: 16px;
}
#CC-MobileSearchForm {
  margin-bottom: 5px;
}
#CC-MobileSearchForm .input-append {
  width: 100%;
}
#CC-MobileSearchForm .input-append input[type="text"] {
  width: 90% !important;
}
#CC-MobileSearchForm .input-append button {
  width: 10%;
}
#CC-MobileSearchForm .input-append input[type="text"],
#CC-MobileSearchForm .input-append button {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
#CC-MobileSearchForm .input-append button {
  padding-left: 0;
  padding-right: 0;
}
#searchSubmit {
  min-width: 40px;
}
html[dir="rtl"] #searchSubmit {
  border-radius: 4px 0 0 4px;
}
html:not([dir="rtl"]) #searchSubmit {
  border-radius: 0 4px 4px 0;
}
.cc-image img {
  max-width: 100%;
}
.cc-scrollable-menu {
  height: auto;
  max-height: 120px;
  width: 180px;
  overflow-x: hidden;
}
div#CC-newPurchaseList-modal-dialog {
  width: 40% !important;
  top: 20% !important;
}
.hr-without-margin {
  margin-top: 0;
  margin-bottom: 0;
}
.cc-alert {
  display: none;
}
#headerOrganizationPicker {
  position: absolute;
  padding-left: 5px;
  padding-right: 2px;
  width: 200px;
}
#headerOrganizationPicker #CC-organizationDropdown #CC-organization-search {
  width: 95%;
  height: 30px;
  margin-left: 5px;
  margin-top: 3px;
  margin-bottom: 2px;
  line-height: 1.42857143;
  color: #555;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-style: inherit;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition-property: border-color, box-shadow;
  transition-duration: 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out;
  transition-delay: initial, initial;
  padding: 6px 6px;
  font-size: 12px;
}
#headerOrganizationPicker .heading span:hover,
#headerOrganizationPicker .content a span:hover {
  text-decoration: underline;
}
#headerOrganizationPicker.active .content {
  position: absolute;
  background: #fff;
  border: 1px solid #eee;
  webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  z-index: 999;
}
#headerOrganizationPicker .caret {
  color: #b2b2b2;
  border-width: 6px;
  vertical-align: text-top;
}
#headerOrganizationPicker .paddingLeft {
  padding-left: 5px;
}
#headerOrganizationPicker .paddingBottom {
  padding-bottom: 3px;
}
#headerOrganizationPicker .content {
  background: #fff;
  overflow: auto;
  width: 100%;
}
#CC-loginHeader-welcomeText {
  padding-right: 0;
}
#CC-loginHeader-organizationPicker {
  padding-left: 0;
}
#headerOrganizationPicker #CC-organizationDropdown #CC-organizations-list {
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 300px;
  margin-left: 5px;
  width: 97%;
}
#headerOrganizationPicker .ellipsis {
  max-width: 87%;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 0;
  overflow: hidden;
  display: inline-block;
}
#headerOrganizationPicker
  #CC-organizationPicker-header
  #CC-organizationPicker-seperator {
  display: inline-block;
  vertical-align: top;
}
.cc-spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 0;
  text-align: center;
}
.cc-spinner .cc-spinner-animation {
  -webkit-animation: cc-rotation 2s infinite linear;
  -moz-animation: cc-rotation 2s infinite linear;
  -ms-animation: cc-rotation 2s infinite linear;
  -o-animation: cc-rotation 2s infinite linear;
  animation: cc-rotation 2s infinite linear;
  font-size: large;
  color: #008000;
}
@-webkit-keyframes cc-rotation {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}
@-moz-keyframes cc-rotation {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}
@-ms-keyframes cc-rotation {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}
@-o-keyframes cc-rotation {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}
@keyframes cc-rotation {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    -moz-transform: rotate(359deg);
    -ms-transform: rotate(359deg);
    -o-transform: rotate(359deg);
  }
}
.cc-spinner-css {
  background-color: #808080;
  width: 90px;
  height: 90px;
  border-radius: 6px;
  position: relative;
}
.cc-spinner-css div {
  width: 5%;
  height: 13%;
  top: 42%;
  background: #fff;
  position: absolute;
  left: 47%;
  opacity: 0;
  -webkit-animation: cc-fade 1s linear infinite;
  -moz-animation: cc-fade 1s linear infinite;
  -ms-animation: cc-fade 1s linear infinite;
  -o-animation: cc-fade 1s linear infinite;
  animation: cc-fade 1s linear infinite;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}
.cc-spinner-css .cc-spinner-css-1 {
  transform: rotate(0deg) translate(0, -142%);
  -webkit-transform: rotate(0deg) translate(0, -142%);
  -moz-transform: rotate(0deg) translate(0, -142%);
  -ms-transform: rotate(0deg) translate(0, -142%);
  -o-transform: rotate(0deg) translate(0, -142%);
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -ms-animation-delay: 0s;
  -o-animation-delay: 0s;
}
.cc-spinner-css .cc-spinner-css-2 {
  transform: rotate(30deg) translate(0, -142%);
  -webkit-transform: rotate(30deg) translate(0, -142%);
  -moz-transform: rotate(30deg) translate(0, -142%);
  -ms-transform: rotate(30deg) translate(0, -142%);
  -o-transform: rotate(30deg) translate(0, -142%);
  animation-delay: -0.9167s;
  -webkit-animation-delay: -0.9167s;
  -moz-animation-delay: -0.9167s;
  -ms-animation-delay: -0.9167s;
  -o-animation-delay: -0.9167s;
}
.cc-spinner-css .cc-spinner-css-3 {
  transform: rotate(60deg) translate(0, -142%);
  -webkit-transform: rotate(60deg) translate(0, -142%);
  -moz-transform: rotate(60deg) translate(0, -142%);
  -ms-transform: rotate(60deg) translate(0, -142%);
  -o-transform: rotate(60deg) translate(0, -142%);
  animation-delay: -0.833s;
  -webkit-animation-delay: -0.833s;
  -moz-animation-delay: -0.833s;
  -ms-animation-delay: -0.833s;
  -o-animation-delay: -0.833s;
}
.cc-spinner-css .cc-spinner-css-4 {
  transform: rotate(90deg) translate(0, -142%);
  -webkit-transform: rotate(90deg) translate(0, -142%);
  -moz-transform: rotate(90deg) translate(0, -142%);
  -ms-transform: rotate(90deg) translate(0, -142%);
  -o-transform: rotate(90deg) translate(0, -142%);
  animation-delay: -0.75s;
  -webkit-animation-delay: -0.75s;
  -moz-animation-delay: -0.75s;
  -ms-animation-delay: -0.75s;
  -o-animation-delay: -0.75s;
}
.cc-spinner-css .cc-spinner-css-5 {
  transform: rotate(120deg) translate(0, -142%);
  -webkit-transform: rotate(120deg) translate(0, -142%);
  -moz-transform: rotate(120deg) translate(0, -142%);
  -ms-transform: rotate(120deg) translate(0, -142%);
  -o-transform: rotate(120deg) translate(0, -142%);
  animation-delay: -0.667s;
  -webkit-animation-delay: -0.667s;
  -moz-animation-delay: -0.667s;
  -ms-animation-delay: -0.667s;
  -o-animation-delay: -0.667s;
}
.cc-spinner-css .cc-spinner-css-6 {
  transform: rotate(150deg) translate(0, -142%);
  -webkit-transform: rotate(150deg) translate(0, -142%);
  -moz-transform: rotate(150deg) translate(0, -142%);
  -ms-transform: rotate(150deg) translate(0, -142%);
  -o-transform: rotate(150deg) translate(0, -142%);
  animation-delay: -0.5833s;
  -webkit-animation-delay: -0.5833s;
  -moz-animation-delay: -0.5833s;
  -ms-animation-delay: -0.5833s;
  -o-animation-delay: -0.5833s;
}
.cc-spinner-css .cc-spinner-css-7 {
  transform: rotate(180deg) translate(0, -142%);
  -webkit-transform: rotate(180deg) translate(0, -142%);
  -moz-transform: rotate(180deg) translate(0, -142%);
  -ms-transform: rotate(180deg) translate(0, -142%);
  -o-transform: rotate(180deg) translate(0, -142%);
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
  -moz-animation-delay: -0.5s;
  -ms-animation-delay: -0.5s;
  -o-animation-delay: -0.5s;
}
.cc-spinner-css .cc-spinner-css-8 {
  transform: rotate(210deg) translate(0, -142%);
  -webkit-transform: rotate(210deg) translate(0, -142%);
  -moz-transform: rotate(210deg) translate(0, -142%);
  -ms-transform: rotate(210deg) translate(0, -142%);
  -o-transform: rotate(210deg) translate(0, -142%);
  animation-delay: -0.41667s;
  -webkit-animation-delay: -0.41667s;
  -moz-animation-delay: -0.41667s;
  -ms-animation-delay: -0.41667s;
  -o-animation-delay: -0.41667s;
}
.cc-spinner-css .cc-spinner-css-9 {
  transform: rotate(240deg) translate(0, -142%);
  -webkit-transform: rotate(240deg) translate(0, -142%);
  -moz-transform: rotate(240deg) translate(0, -142%);
  -ms-transform: rotate(240deg) translate(0, -142%);
  -o-transform: rotate(240deg) translate(0, -142%);
  animation-delay: -0.333s;
  -webkit-animation-delay: -0.333s;
  -moz-animation-delay: -0.333s;
  -ms-animation-delay: -0.333s;
  -o-animation-delay: -0.333s;
}
.cc-spinner-css .cc-spinner-css-10 {
  transform: rotate(270deg) translate(0, -142%);
  -webkit-transform: rotate(270deg) translate(0, -142%);
  -moz-transform: rotate(270deg) translate(0, -142%);
  -ms-transform: rotate(270deg) translate(0, -142%);
  -o-transform: rotate(270deg) translate(0, -142%);
  animation-delay: -0.25s;
  -webkit-animation-delay: -0.25s;
  -moz-animation-delay: -0.25s;
  -ms-animation-delay: -0.25s;
  -o-animation-delay: -0.25s;
}
.cc-spinner-css .cc-spinner-css-11 {
  transform: rotate(300deg) translate(0, -142%);
  -webkit-transform: rotate(300deg) translate(0, -142%);
  -moz-transform: rotate(300deg) translate(0, -142%);
  -ms-transform: rotate(300deg) translate(0, -142%);
  -o-transform: rotate(300deg) translate(0, -142%);
  animation-delay: -0.1667s;
  -webkit-animation-delay: -0.1667s;
  -moz-animation-delay: -0.1667s;
  -ms-animation-delay: -0.1667s;
  -o-animation-delay: -0.1667s;
}
.cc-spinner-css .cc-spinner-css-12 {
  transform: rotate(330deg) translate(0, -142%);
  -webkit-transform: rotate(330deg) translate(0, -142%);
  -moz-transform: rotate(330deg) translate(0, -142%);
  -ms-transform: rotate(330deg) translate(0, -142%);
  -o-transform: rotate(330deg) translate(0, -142%);
  animation-delay: -0.0833s;
  -webkit-animation-delay: -0.0833s;
  -moz-animation-delay: -0.0833s;
  -ms-animation-delay: -0.0833s;
  -o-animation-delay: -0.0833s;
}
@-webkit-keyframes cc-fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}
@-moz-keyframes cc-fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}
@-ms-keyframes cc-fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}
@-o-keyframes cc-fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}
@keyframes cc-fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}
.ie-show {
  display: none;
}
.alert.cc-notification {
  padding: 8px 14px 8px 0;
  margin-bottom: 0 !important;
}
.alert.cc-notification .close {
  right: -10px;
  opacity: 1;
}
.cc-notification {
  font-size: 12px;
  border-radius: 0;
}
.cc-notification.alert-confirm {
  -webkit-box-shadow: 0 3px 3px 0 #aaa;
  -moz-box-shadow: 0 3px 3px 0 #aaa;
  box-shadow: 0 3px 3px 0 #aaa;
}
.cc-notification-header {
  margin-top: 0;
  font-weight: bold;
  height: 32px;
  line-height: 25px;
  font-size: 20px;
  padding-left: 40px;
  margin-bottom: 10px;
  text-shadow: 1px 1px #ccc;
  border-radius: 0;
}
.cc-notification-message {
  line-height: 14px;
}
.cc-notification-add-to-cart-message {
  width: 340px;
  margin-left: 40px;
}
.cc-notification-add-to-cart-message-header {
  color: #333;
  font-size: 17.5px;
  line-height: 20px;
  margin-top: 10px;
}
.cc-notification-add-to-cart-message-buttons {
  margin-top: 10px;
}
.cc-notification-growl {
  position: fixed;
  top: 65px;
  right: 20px;
  width: 380px;
  z-index: 1041;
  border: 1px solid #999;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 5px 0 #aaa;
  -moz-box-shadow: 0 0 5px 0 #aaa;
  box-shadow: 0 0 5px 0 #aaa;
  word-wrap: break-word;
  transition: top 0.5s;
}
@media (max-width: 480px) {
  .cc-notification-growl {
    width: 210px;
  }
  .cc-notification-header {
    height: auto;
  }
  .cc-notification-add-to-cart-message {
    width: 150px;
  }
}
@media (min-width: 1200px) {
  .cc-notification-message.container {
    width: 1130px;
    padding-left: 0;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .cc-notification-message.container {
    width: 920px;
    padding-left: 0;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .cc-notification-message.container {
    width: 700px;
    padding-left: 0;
  }
}
@media (max-width: 767px) and (min-width: 481px) {
  .cc-notification-message.container {
    width: 510px;
    padding-left: 0;
  }
}
@media (max-width: 480px) and (min-width: 440px) {
  .cc-notification-message.container {
    width: 390px;
    padding-left: 0;
  }
}
@media (max-width: 439px) {
  .cc-notification-message.container {
    width: 280px;
    padding-left: 0;
  }
}
.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
}
.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.alert-error {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
}
.alert-confirm {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
/* .alert-info .cc-notification-header {
  background: transparent url(/img/icons-32x32/icon-info-32x32.png) no-repeat 0
    0;
}
.alert-success .cc-notification-header {
  background: transparent url(/img/icons-32x32/icon-check-32x32.png) no-repeat 0
    0;
}
.alert-error .cc-notification-header {
  background: transparent url(/img/icons-32x32/icon-alert-alt-32x32.png)
    no-repeat 0 0;
}
.alert-confirm .cc-notification-header {
  background: transparent url(/img/icons-32x32/icon-alert-32x32.png) no-repeat 0
    0;
} */
.alert-confirm {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}
.cc-notification-actions {
  margin: 15px 0 5px;
  text-align: right;
}
.cc-notification-actions BUTTON {
  margin: 0 0 0 10px;
}
#previewBar {
  position: fixed;
  top: 0;
  height: 45px;
  width: 100%;
  z-index: 999;
  background-color: #151515;
}
#previewBar #previewBarLayoutName,
#previewBar #previewBarText {
  height: 35px;
  padding: 7px 15px 0;
  font-size: 11.9px;
  color: #f8f8f8;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  flex-grow: 1;
}
#previewBar #previewBarLayoutName {
  text-align: center;
}
#previewBar #previewBarText {
  text-align: left;
  font-weight: bold;
  padding-left: 0;
  color: #ffaf50;
}
#previewBar #previewBarText span {
  padding-left: 6px;
}
#previewBar .oj-form-control {
  margin-bottom: 0;
}
#previewBar .oj-combobox-input,
#previewBar .oj-combobox-choice {
  color: #f8f8f8;
  background-color: #151515;
  border-color: #151515;
  font-size: 11.9px;
  height: 2.2rem;
}
#previewBar input.oj-combobox-input {
  height: 100%;
}
#previewBar #previewMenuButton {
  color: #f8f8f8;
  height: 33px;
  background-color: #151515;
  border-radius: 0;
  border: 0;
  margin-bottom: 0;
}
#previewBar #previewMenuButton span.oj-button-icon {
  font-size: 17.5px;
  margin-bottom: 1px;
}
#previewBar #previewMenuButton span.oj-button-text {
  font-size: 11.9px;
}
#previewBar #previewBarSeparator {
  height: 10px;
  width: 100%;
  /* background-image: url("/file/ccimg/hazardstripe.png"); */
}
#previewDebugToolsMenu {
  opacity: 0.95;
  border: 0;
  background-color: 0;
}
#previewDebugToolsMenu #previewMinifyOption {
  font-size: 11.9px;
  background-color: #151515;
  color: #f8f8f8;
}
#previewDebugToolsMenu #previewMinifyOption div.oj-switch {
  padding-top: 10px;
}
#previewDebugToolsMenu #previewMinifySwitchLabel {
  font-size: 11.9px;
  color: #f8f8f8;
  font-weight: normal;
  margin-left: 10px;
  margin-right: 40px;
}
#oj-listbox-drop {
  color: #f8f8f8;
  background-color: #151515;
  border-color: #151515;
}
#oj-listbox-drop .siteSelectionOption {
  margin: 12px 12px 0 4px;
}
#oj-listbox-drop .siteSelectionOption .title {
  font-size: 11.9px;
  line-height: 16pt;
}
#oj-listbox-drop .siteSelectionOption .info {
  font-size: 10.5px;
  line-height: 10pt;
}
#oj-listbox-drop .oj-hover {
  background-color: #0572ce;
}
html {
  font-size: 14px;
}
body {
  background: #fff none top center no-repeat;
  background-attachment: fixed;
  color: #333;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  text-align: left;
  text-decoration: none;
}
html[dir="rtl"] body {
  text-align: right;
}
#page {
  background-color: #fff;
}
a:link {
  color: #195d8d;
}
a:visited {
  color: #195d8d;
}
a:hover {
  color: #114062;
}
h1 {
  color: inherit;
  line-height: 150%;
  font-family: inherit;
  font-size: 2.75rem;
  font-weight: bold;
  font-style: inherit;
  text-align: inherit;
  text-decoration: inherit;
}
h2 {
  color: inherit;
  font-family: inherit;
  font-size: 2.25rem;
  font-style: inherit;
  font-weight: bold;
  line-height: 150%;
  text-align: inherit;
  text-decoration: inherit;
}
h3 {
  color: inherit;
  font-family: inherit;
  font-size: 1.75rem;
  font-style: inherit;
  font-weight: bold;
  line-height: 150%;
  text-align: inherit;
  text-decoration: inherit;
}
h4 {
  color: inherit;
  font-family: inherit;
  font-size: 1.25rem;
  font-style: inherit;
  font-weight: bold;
  line-height: 150%;
  text-align: inherit;
  text-decoration: inherit;
}
h5 {
  color: inherit;
  font-family: inherit;
  font-size: 1rem;
  font-style: inherit;
  font-weight: normal;
  line-height: 150%;
  text-align: inherit;
  text-decoration: inherit;
}
h6 {
  color: inherit;
  font-family: inherit;
  font-size: 0.85rem;
  font-style: inherit;
  font-weight: normal;
  line-height: 150%;
  text-align: inherit;
  text-decoration: inherit;
}
p {
  color: inherit;
  font-family: inherit;
  font-size: 1rem;
  font-style: inherit;
  font-weight: normal;
  line-height: 150%;
  text-align: inherit;
  text-decoration: inherit;
}
.cc-button-primary {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #195d8e;
  white-space: normal;
  border: 1px solid #0e324d;
  color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  border-radius: 4px;
}
.cc-button-primary:focus,
.cc-button-primary:active:focus,
.cc-button-primary.active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.cc-button-primary:hover,
.cc-button-primary:focus {
  color: #333;
  text-decoration: none;
}
.cc-button-primary:active,
.cc-button-primary.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.cc-button-primary.disabled,
.cc-button-primary[disabled],
fieldset[disabled] .cc-button-primary {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cc-button-primary:hover,
.cc-button-primary:focus {
  background-color: #114163;
  color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.cc-button-primary:disabled:hover,
.cc-button-primary.disabled:hover,
.cc-button-primary.disabled:focus {
  background-color: #195d8e;
  background-image: none;
}
.cc-button-primary:link {
  color: #fff;
  font-style: normal;
  text-decoration: none;
}
.cc-button-primary:visited {
  color: #fff;
  font-style: normal;
  text-decoration: none;
}
.cc-button-primary:hover {
  background-color: #114163;
  color: #fff;
  font-style: normal;
  text-decoration: none;
}
.cc-button-primary:focus,
.cc-button-primary:active:focus,
.cc-button-primary.active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.cc-button-primary:hover,
.cc-button-primary:focus {
  color: #333;
  text-decoration: none;
}
.cc-button-primary:active,
.cc-button-primary.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.cc-button-primary.disabled,
.cc-button-primary[disabled],
fieldset[disabled] .cc-button-primary {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cc-button-primary:hover,
.cc-button-primary:focus {
  background-color: #114163;
  color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.cc-button-primary:disabled:hover,
.cc-button-primary.disabled:hover,
.cc-button-primary.disabled:focus {
  background-color: #195d8e;
  background-image: none;
}
.cc-button-primary:link {
  color: #fff;
  font-style: normal;
  text-decoration: none;
}
.cc-button-primary:visited {
  color: #fff;
  font-style: normal;
  text-decoration: none;
}
.cc-button-primary:hover {
  background-color: #114163;
  color: #fff;
  font-style: normal;
  text-decoration: none;
}
.cc-button-secondary {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #f7f7f7;
  white-space: normal;
  border: 1px solid #d1d1d1;
  color: #3d3d3d;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  border-radius: 4px;
}
.cc-button-secondary:focus,
.cc-button-secondary:active:focus,
.cc-button-secondary.active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.cc-button-secondary:hover,
.cc-button-secondary:focus {
  color: #333;
  text-decoration: none;
}
.cc-button-secondary:active,
.cc-button-secondary.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.cc-button-secondary.disabled,
.cc-button-secondary[disabled],
fieldset[disabled] .cc-button-secondary {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cc-button-secondary:hover,
.cc-button-secondary:focus {
  background-color: #dedede;
  color: #3d3d3d;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.cc-button-secondary:disabled:hover,
.cc-button-secondary.disabled:hover,
.cc-button-secondary.disabled:focus {
  background-color: #f7f7f7;
  background-image: none;
}
.cc-button-secondary:link {
  color: #3d3d3d;
  font-style: normal;
  text-decoration: none;
}
.cc-button-secondary:visited {
  color: #3d3d3d;
  font-style: normal;
  text-decoration: none;
}
.cc-button-secondary:hover {
  background-color: #dedede;
  color: #3d3d3d;
  font-style: normal;
  text-decoration: none;
}
.cc-button-secondary:focus,
.cc-button-secondary:active:focus,
.cc-button-secondary.active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.cc-button-secondary:hover,
.cc-button-secondary:focus {
  color: #333;
  text-decoration: none;
}
.cc-button-secondary:active,
.cc-button-secondary.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.cc-button-secondary.disabled,
.cc-button-secondary[disabled],
fieldset[disabled] .cc-button-secondary {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cc-button-secondary:hover,
.cc-button-secondary:focus {
  background-color: #dedede;
  color: #3d3d3d;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.cc-button-secondary:disabled:hover,
.cc-button-secondary.disabled:hover,
.cc-button-secondary.disabled:focus {
  background-color: #f7f7f7;
  background-image: none;
}
.cc-button-secondary:link {
  color: #3d3d3d;
  font-style: normal;
  text-decoration: none;
}
.cc-button-secondary:visited {
  color: #3d3d3d;
  font-style: normal;
  text-decoration: none;
}
.cc-button-secondary:hover {
  background-color: #dedede;
  color: #3d3d3d;
  font-style: normal;
  text-decoration: none;
}
#region-header #siteHead {
  /* background: #fff url(/img/header-btm-line.png) bottom center repeat-x; */
  background-attachment: scroll;
}
.header-background {
  background: #fff none top left repeat-x;
  background-attachment: scroll;
}
footer.footer {
  background: #fff;
  background-position: top left;
  background-repeat: repeat-x;
  background-image: none;
  background-attachment: scroll;
  border-top-color: #383938;
}
@-webkit-keyframes placeHolderShimmer {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 300px 0;
  }
}
@keyframes placeHolderShimmer {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 300px 0;
  }
}
.ccLazyLoad-animated-background {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: #eee;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, #eee),
    color-stop(18%, #ddd),
    color-stop(33%, #eee)
  );
  background: -webkit-linear-gradient(left, #eee 8%, #ddd 18%, #eee 33%);
  background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  position: relative;
}
.ccLazyLoad-background {
  background: #e9eaed;
}
img.ccLazyLoad {
  opacity: 0.1;
}
@media (max-width: 979px) {
  body {
    padding-top: 0;
  }
  .navbar-fixed-top,
  .navbar-fixed-bottom {
    position: static;
  }
  .navbar-fixed-top {
    margin-bottom: 20px;
  }
  .navbar-fixed-bottom {
    margin-top: 20px;
  }
  .navbar-fixed-top .navbar-inner,
  .navbar-fixed-bottom .navbar-inner {
    padding: 5px;
  }
  .navbar .container {
    width: auto;
    padding: 0;
  }
  .navbar .brand {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 0 0 -5px;
  }
  .nav-collapse {
    clear: both;
  }
  .nav-collapse .nav {
    float: none;
    margin: 0 0 10px;
  }
  .nav-collapse .nav > li {
    float: none;
  }
  .nav-collapse .nav > li > a {
    margin-bottom: 2px;
  }
  .nav-collapse .nav > .divider-vertical {
    display: none;
  }
  .nav-collapse .nav .nav-header {
    color: #777;
    text-shadow: none;
  }
  .nav-collapse .nav > li > a,
  .nav-collapse .dropdown-menu a {
    padding: 9px 15px;
    font-weight: bold;
    color: #777;
  }
  .nav-collapse .btn {
    padding: 4px 10px 4px;
    font-weight: normal;
  }
  .nav-collapse .dropdown-menu li + li a {
    margin-bottom: 2px;
  }
  .nav-collapse .nav > li > a:hover,
  .nav-collapse .nav > li > a:focus,
  .nav-collapse .dropdown-menu a:hover,
  .nav-collapse .dropdown-menu a:focus {
    background-color: #fff;
  }
  .navbar-inverse .nav-collapse .nav > li > a,
  .navbar-inverse .nav-collapse .dropdown-menu a {
    color: #999;
  }
  .navbar-inverse .nav-collapse .nav > li > a:hover,
  .navbar-inverse .nav-collapse .nav > li > a:focus,
  .navbar-inverse .nav-collapse .dropdown-menu a:hover,
  .navbar-inverse .nav-collapse .dropdown-menu a:focus {
    background-color: #111;
  }
  .nav-collapse.in .btn-group {
    margin-top: 5px;
    padding: 0;
  }
  .nav-collapse .dropdown-menu {
    position: static;
    top: auto;
    left: auto;
    float: none;
    display: none;
    max-width: none;
    margin: 0 15px;
    padding: 0;
    background-color: transparent;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .nav-collapse .open > .dropdown-menu {
    display: block;
  }
  .nav-collapse .dropdown-menu:before,
  .nav-collapse .dropdown-menu:after {
    display: none;
  }
  .nav-collapse .dropdown-menu .divider {
    display: none;
  }
  .nav-collapse .nav > li > .dropdown-menu:before,
  .nav-collapse .nav > li > .dropdown-menu:after {
    display: none;
  }
  .nav-collapse .navbar-form,
  .nav-collapse .navbar-search {
    float: none;
    padding: 10px 15px;
    margin: 10px 0;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
      0 1px 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
      0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-inverse .nav-collapse .navbar-form,
  .navbar-inverse .nav-collapse .navbar-search {
    border-top-color: #111;
    border-bottom-color: #111;
  }
  .navbar .nav-collapse .nav.pull-right {
    float: none;
    margin-left: 0;
  }
  .nav-collapse,
  .nav-collapse.collapse {
    overflow: hidden;
    height: 0;
  }
  .navbar .btn-navbar {
    display: block;
  }
  .navbar-static .navbar-inner {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (min-width: 979px + 1) {
  .nav-collapse.collapse {
    height: auto !important;
    overflow: visible !important;
  }
} /*!OCC_LESS [{"assetId":"100001","link":"/occs-admin/#/css/100001","assetName":"Telecom Theme","assetType":"Variables de estilo"}] */ /*!OCC_LESS [{"assetId":"100001","link":"/occs-admin/#/css/100001","assetName":"Telecom Theme","assetType":"Hoja de estilos"}] */
html {
  font-size: 14px;
}
body {
  background: #fff none top center no-repeat;
  background-attachment: fixed;
  color: #333;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  text-align: left;
  text-decoration: none;
}
html[dir="rtl"] body {
  text-align: right;
}
#page {
  background-color: #fff;
}
a:link {
  color: #195d8d;
}
a:visited {
  color: #195d8d;
}
a:hover {
  color: #114062;
}
h1 {
  color: inherit;
  line-height: 150%;
  font-family: inherit;
  font-size: 2.75rem;
  font-weight: bold;
  font-style: inherit;
  text-align: inherit;
  text-decoration: inherit;
}
h2 {
  color: inherit;
  font-family: inherit;
  font-size: 2.25rem;
  font-style: inherit;
  font-weight: bold;
  line-height: 150%;
  text-align: inherit;
  text-decoration: inherit;
}
h3 {
  color: inherit;
  font-family: inherit;
  font-size: 1.75rem;
  font-style: inherit;
  font-weight: bold;
  line-height: 150%;
  text-align: inherit;
  text-decoration: inherit;
}
h4 {
  color: inherit;
  font-family: inherit;
  font-size: 1.25rem;
  font-style: inherit;
  font-weight: bold;
  line-height: 150%;
  text-align: inherit;
  text-decoration: inherit;
}
h5 {
  color: inherit;
  font-family: inherit;
  font-size: 1rem;
  font-style: inherit;
  font-weight: normal;
  line-height: 150%;
  text-align: inherit;
  text-decoration: inherit;
}
h6 {
  color: inherit;
  font-family: inherit;
  font-size: 0.85rem;
  font-style: inherit;
  font-weight: normal;
  line-height: 150%;
  text-align: inherit;
  text-decoration: inherit;
}
p {
  color: inherit;
  font-family: inherit;
  font-size: 1rem;
  font-style: inherit;
  font-weight: normal;
  line-height: 150%;
  text-align: inherit;
  text-decoration: inherit;
}
.cc-button-primary {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #195d8e;
  white-space: normal;
  border: 1px solid #0e324d;
  color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  border-radius: 4px;
}
.cc-button-primary:focus,
.cc-button-primary:active:focus,
.cc-button-primary.active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.cc-button-primary:hover,
.cc-button-primary:focus {
  color: #333;
  text-decoration: none;
}
.cc-button-primary:active,
.cc-button-primary.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.cc-button-primary.disabled,
.cc-button-primary[disabled],
fieldset[disabled] .cc-button-primary {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cc-button-primary:hover,
.cc-button-primary:focus {
  background-color: #114163;
  color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.cc-button-primary:disabled:hover,
.cc-button-primary.disabled:hover,
.cc-button-primary.disabled:focus {
  background-color: #195d8e;
  background-image: none;
}
.cc-button-primary:link {
  color: #fff;
  font-style: normal;
  text-decoration: none;
}
.cc-button-primary:visited {
  color: #fff;
  font-style: normal;
  text-decoration: none;
}
.cc-button-primary:hover {
  background-color: #114163;
  color: #fff;
  font-style: normal;
  text-decoration: none;
}
.cc-button-primary:focus,
.cc-button-primary:active:focus,
.cc-button-primary.active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.cc-button-primary:hover,
.cc-button-primary:focus {
  color: #333;
  text-decoration: none;
}
.cc-button-primary:active,
.cc-button-primary.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.cc-button-primary.disabled,
.cc-button-primary[disabled],
fieldset[disabled] .cc-button-primary {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cc-button-primary:hover,
.cc-button-primary:focus {
  background-color: #114163;
  color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.cc-button-primary:disabled:hover,
.cc-button-primary.disabled:hover,
.cc-button-primary.disabled:focus {
  background-color: #195d8e;
  background-image: none;
}
.cc-button-primary:link {
  color: #fff;
  font-style: normal;
  text-decoration: none;
}
.cc-button-primary:visited {
  color: #fff;
  font-style: normal;
  text-decoration: none;
}
.cc-button-primary:hover {
  background-color: #114163;
  color: #fff;
  font-style: normal;
  text-decoration: none;
}
.cc-button-secondary {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #f7f7f7;
  white-space: normal;
  border: 1px solid #d1d1d1;
  color: #3d3d3d;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  border-radius: 4px;
}
.cc-button-secondary:focus,
.cc-button-secondary:active:focus,
.cc-button-secondary.active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.cc-button-secondary:hover,
.cc-button-secondary:focus {
  color: #333;
  text-decoration: none;
}
.cc-button-secondary:active,
.cc-button-secondary.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.cc-button-secondary.disabled,
.cc-button-secondary[disabled],
fieldset[disabled] .cc-button-secondary {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cc-button-secondary:hover,
.cc-button-secondary:focus {
  background-color: #dedede;
  color: #3d3d3d;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.cc-button-secondary:disabled:hover,
.cc-button-secondary.disabled:hover,
.cc-button-secondary.disabled:focus {
  background-color: #f7f7f7;
  background-image: none;
}
.cc-button-secondary:link {
  color: #3d3d3d;
  font-style: normal;
  text-decoration: none;
}
.cc-button-secondary:visited {
  color: #3d3d3d;
  font-style: normal;
  text-decoration: none;
}
.cc-button-secondary:hover {
  background-color: #dedede;
  color: #3d3d3d;
  font-style: normal;
  text-decoration: none;
}
.cc-button-secondary:focus,
.cc-button-secondary:active:focus,
.cc-button-secondary.active:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.cc-button-secondary:hover,
.cc-button-secondary:focus {
  color: #333;
  text-decoration: none;
}
.cc-button-secondary:active,
.cc-button-secondary.active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.cc-button-secondary.disabled,
.cc-button-secondary[disabled],
fieldset[disabled] .cc-button-secondary {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.cc-button-secondary:hover,
.cc-button-secondary:focus {
  background-color: #dedede;
  color: #3d3d3d;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}
.cc-button-secondary:disabled:hover,
.cc-button-secondary.disabled:hover,
.cc-button-secondary.disabled:focus {
  background-color: #f7f7f7;
  background-image: none;
}
.cc-button-secondary:link {
  color: #3d3d3d;
  font-style: normal;
  text-decoration: none;
}
.cc-button-secondary:visited {
  color: #3d3d3d;
  font-style: normal;
  text-decoration: none;
}
.cc-button-secondary:hover {
  background-color: #dedede;
  color: #3d3d3d;
  font-style: normal;
  text-decoration: none;
}
#region-header #siteHead {
  /* background: #fff url(/img/header-btm-line.png) bottom center repeat-x; */
  background-attachment: scroll;
}
.header-background {
  background: #fff none top left repeat-x;
  background-attachment: scroll;
}
footer.footer {
  background: #fff;
  background-position: top left;
  background-repeat: repeat-x;
  background-image: none;
  background-attachment: scroll;
  border-top-color: #383938;
}
@-webkit-keyframes placeHolderShimmer {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 300px 0;
  }
}
@keyframes placeHolderShimmer {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 300px 0;
  }
}
.ccLazyLoad-animated-background {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: #eee;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(8%, #eee),
    color-stop(18%, #ddd),
    color-stop(33%, #eee)
  );
  background: -webkit-linear-gradient(left, #eee 8%, #ddd 18%, #eee 33%);
  background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  position: relative;
}
.ccLazyLoad-background {
  background: #e9eaed;
}
img.ccLazyLoad {
  opacity: 0.1;
} /*!OCC_LESS [{"assetId":"100001","link":"/occs-admin/#/css/100001","assetName":"Telecom Theme","assetType":"CSS adicional"}] */
.font-awesome {
  font-family: FontAwesome !important;
}
.font-awesome-pro {
  font-family: FontAwesomePro !important;
}
.font-awesome-pro-solid {
  font-family: FontAwesomeProSolid !important;
}
.font-awesome-pro-brand {
  font-family: FontAwesomeProBrand !important;
}
.font-glyphicons {
  font-family: Glyphicons !important;
}
.font-roboto-light {
  font-family: Roboto-Light !important;
}
.font-roboto-regular {
  font-family: Roboto-Regular !important;
}
.font-roboto-medium {
  font-family: Roboto-Medium !important;
}
.font-roboto-bold {
  font-family: Roboto-Bold !important;
}
.font-roboto-black {
  font-family: Roboto-Black !important;
}
.font-lato-light {
  font-family: Lato-Light !important;
}
.font-lato-regular {
  font-family: Lato-Regular !important;
}
.font-lato-bold {
  font-family: Lato-Bold !important;
}
.font-lato-black {
  font-family: Lato-Black !important;
}
#CC-checkoutAddressBook,
#CC-checkoutOrderSummary,
#checkout-registration,
#checkoutCartSummary,
#checkoutPaymentDetails,
#orderConfirmation,
#confirmation-summary {
  display: none;
}
.tooltip-custom-style {
  margin-top: 5px;
  margin-bottom: 5px;
}
.tooltip-custom-style:hover {
  background-color: #0094ff;
}
.text-color__white {
  color: #fff;
}
.text-color__white.text-color__white {
  color: #fff !important;
}
.text-color__black {
  color: #333;
}
.text-color__black.text-color__black {
  color: #333 !important;
}
.text-color__dodger-blue {
  color: #0094ff;
}
.text-color__dodger-blue.text-color__dodger-blue {
  color: #0094ff !important;
}
.text-color__pinkish-grey {
  color: #ccc;
}
.text-color__pinkish-grey.text-color__pinkish-grey {
  color: #ccc !important;
}
.text-color__background-gray-100-interfaz {
  color: #f5f5f5;
}
.text-color__background-gray-100-interfaz.text-color__background-gray-100-interfaz {
  color: #f5f5f5 !important;
}
.h-40 {
  height: 40px;
}
.h-48 {
  height: 48px;
}
.button--width-208 {
  width: 208px;
}
.button--width-230 {
  width: 230px;
}
.button--width-240 {
  width: 240px;
}
.button--width-250 {
  width: 250px;
}
.button--width-328 {
  width: 328px;
}
.button--width-386 {
  width: 386px;
}
.button--rounded-1 {
  border-radius: 1px;
}
.button--rounded-1.button--rounded-1 {
  border-radius: 1px !important;
}
.button--rounded-2 {
  border-radius: 2px;
}
.button--rounded-2.button--rounded-2 {
  border-radius: 2px !important;
}
.button--rounded-3 {
  border-radius: 3px;
}
.button--rounded-3.button--rounded-3 {
  border-radius: 3px !important;
}
.button--rounded-4 {
  border-radius: 4px;
}
.button--rounded-4.button--rounded-4 {
  border-radius: 4px !important;
}
.button--rounded-5 {
  border-radius: 5px;
}
.button--rounded-5.button--rounded-5 {
  border-radius: 5px !important;
}
.position-absolute {
  position: absolute !important;
}
.flex-centered {
  display: flex;
  justify-content: center;
}
.flex-centered-aligned,
.telecom__button--dodger-blue__filled,
.telecom__button--dodger-blue__outlined,
.telecom__button--color-flow-primario__outlined,
.telecom__button--white__outlined {
  display: flex;
  justify-content: center;
  align-items: center;
}
.row-wrap {
  flex-flow: row wrap;
}
.background_black {
  background-color: #000;
}
.bg-color-button-cablevision-hover {
  background-color: #b72025;
}
.bg-color-button-cablevision-hover.bg-color-important {
  background-color: #b72025 !important;
}
.bg-color-fibertel-blue-500 {
  background-color: #225380;
}
.bg-color-fibertel-blue-500.bg-color-important {
  background-color: #225380 !important;
}
.bg-color-personal-cyan-500 {
  background-color: #00b0ea;
}
.bg-color-personal-cyan-500.bg-color-important {
  background-color: #00b0ea !important;
}
.bg-color-contenido-pressed {
  background-color: #002b53;
}
.bg-color-contenido-pressed.bg-color-important {
  background-color: #002b53 !important;
}
.bg-color-lipstick {
  background-color: #dd2534;
}
.bg-color-lipstick.bg-color-important {
  background-color: #dd2534 !important;
}
.bg-color-black {
  background-color: #333;
}
.bg-color-black.bg-color-important {
  background-color: #333 !important;
}
.bg-color-flow {
  background-color: #5edeb7;
}
.bg-color-flow.bg-color-important {
  background-color: #5edeb7 !important;
}
.bg-color-denim {
  background-color: #38547e;
}
.bg-color-denim.bg-color-important {
  background-color: #38547e !important;
}
.bg-color-white {
  background-color: #fff;
}
.bg-color-white.bg-color-important {
  background-color: #fff !important;
}
.bg-color-ocean-blue {
  background-color: #0077ad;
}
.bg-color-ocean-blue.bg-color-important {
  background-color: #0077ad !important;
}
.bg-color-background-gray-100-interfaz {
  background-color: #f5f5f5;
}
.bg-color-background-gray-100-interfaz.bg-color-important {
  background-color: #f5f5f5 !important;
}
.bg-color-flow-primario {
  background-color: #57dfb7;
}
.bg-color-flow-primario.bg-color-important {
  background-color: #57dfb7 !important;
}
.bg-color-flow-complementario {
  background-color: #1b1b1d !important;
}
.bg-color-flow-complementario.bg-color-important {
  background-color: #1b1b1d !important;
}
.bg-color-teal-blue {
  background-color: #017da6;
}
.bg-color-teal-blue.bg-color-important {
  background-color: #017da6 !important;
}
.bg-color-dusk-blue {
  background-color: #225280;
}
.bg-color-dusk-blue.bg-color-important {
  background-color: #225280 !important;
}
.bg-color-dodger-blue {
  background-color: #0094ff;
}
.bg-color-dodger-blue.bg-color-important {
  background-color: #0094ff !important;
}
.bg-color-dark-grey {
  background-color: #282629;
}
.bg-color-dark-grey.bg-color-important {
  background-color: #282629 !important;
}
.bg-color-black-social-media {
  background-color: #212123;
}
.bg-color-black-social-media.bg-color-important {
  background-color: #212123 !important;
}
.bg-color-light-grey {
  background-color: #e0e0e0;
}
.bg-color-light-grey.bg-color-important {
  background-color: #e0e0e0 !important;
}
.full-height {
  height: 100%;
}
.full-height-important {
  height: 100% !important;
}
.margin-top-9 {
  margin-top: 9px;
}
.margin-top-9.margin-important {
  margin-top: 9px !important;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-10.margin-important {
  margin-top: 10px !important;
}
.margin-top-15 {
  margin-top: 15px;
}
.margin-top-15.margin-important {
  margin-top: 15px !important;
}
.margin-top-17 {
  margin-top: 17px;
}
.margin-top-17.margin-important {
  margin-top: 17px !important;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-20.margin-important {
  margin-top: 20px !important;
}
.margin-top-31 {
  margin-top: 31px;
}
.margin-top-31.margin-important {
  margin-top: 31px !important;
}
.margin-top-35 {
  margin-top: 35px;
}
.margin-top-35.margin-important {
  margin-top: 35px !important;
}
.margin-top-45 {
  margin-top: 45px;
}
.margin-top-45.margin-important {
  margin-top: 45px !important;
}
.margin-top-50 {
  margin-top: 50px;
}
.margin-top-50.margin-important {
  margin-top: 50px !important;
}
.margin-top-52 {
  margin-top: 52px;
}
.margin-top-52.margin-important {
  margin-top: 52px !important;
}
.margin-top-57 {
  margin-top: 57px;
}
.margin-top-57.margin-important {
  margin-top: 57px !important;
}
.margin-top-67 {
  margin-top: 67px;
}
.margin-top-67.margin-important {
  margin-top: 67px !important;
}
.margin-left-15 {
  margin-left: 15px;
}
.margin-left-15.margin-important {
  margin-left: 15px !important;
}
.margin-left-16 {
  margin-left: 16px;
}
.margin-left-16.margin-important {
  margin-left: 16px !important;
}
.margin-left-25 {
  margin-left: 25px;
}
.margin-left-25.margin-important {
  margin-left: 25px !important;
}
.margin-bottom-0 {
  margin-bottom: 0;
}
.margin-bottom-0.margin-important {
  margin-bottom: 0 !important;
}
.margin-bottom-8 {
  margin-bottom: 8px;
}
.margin-bottom-8.margin-important {
  margin-bottom: 8px !important;
}
.margin-bottom-28 {
  margin-bottom: 28px;
}
.margin-bottom-28.margin-important {
  margin-bottom: 28px !important;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}
.margin-bottom-30.margin-important {
  margin-bottom: 30px !important;
}
.margin-bottom-32 {
  margin-bottom: 32px;
}
.margin-bottom-32.margin-important {
  margin-bottom: 32px !important;
}
.margin-bottom-48 {
  margin-bottom: 48px;
}
.margin-bottom-48.margin-important {
  margin-bottom: 48px !important;
}
.margin-bottom-67 {
  margin-bottom: 67px;
}
.margin-bottom-67.margin-important {
  margin-bottom: 67px !important;
}
.margin-right-15 {
  margin-right: 15px;
}
.margin-right-15.margin-important {
  margin-right: 15px !important;
}
.margin-right-16 {
  margin-right: 16px;
}
.margin-right-16.margin-important {
  margin-right: 16px !important;
}
.padding-top-25 {
  padding-top: 25px;
}
.padding-top-25.padding-important {
  padding-top: 25px !important;
}
.padding-top-50 {
  padding-top: 50px;
}
.padding-top-50.padding-important {
  padding-top: 50px !important;
}
.padding-left-15 {
  padding-left: 15px;
}
@media (max-width: 575.98px) {
  .padding-left-15.padding-left-xs {
    padding-left: 15px !important;
  }
}
@media (max-width: 767.98px) {
  .padding-left-15.padding-left-sm {
    padding-left: 15px !important;
  }
}
@media (max-width: 991.98px) {
  .padding-left-15.padding-left-md {
    padding-left: 15px !important;
  }
}
@media (max-width: 1199.98px) {
  .padding-left-15.padding-left-lg {
    padding-left: 15px !important;
  }
}
.padding-left-15.padding-important {
  padding-left: 15px !important;
}
.padding-right-15 {
  padding-right: 15px;
}
@media (max-width: 575.98px) {
  .padding-right-15.padding-right-xs {
    padding-right: 15px !important;
  }
}
@media (max-width: 767.98px) {
  .padding-right-15.padding-right-sm {
    padding-right: 15px !important;
  }
}
@media (max-width: 991.98px) {
  .padding-right-15.padding-right-md {
    padding-right: 15px !important;
  }
}
@media (max-width: 1199.98px) {
  .padding-right-15.padding-right-lg {
    padding-right: 15px !important;
  }
}
.padding-right-15.padding-important {
  padding-right: 15px !important;
}
.padding-bottom-25 {
  padding-bottom: 25px;
}
.padding-bottom-25.padding-important {
  padding-bottom: 25px !important;
}
.padding-bottom-50 {
  padding-bottom: 50px;
}
.padding-bottom-50.padding-important {
  padding-bottom: 50px !important;
}
.top-0 {
  top: 0;
}
.float-left {
  float: left;
}
.font-size-32 {
  font-size: 32px;
}
.font-size-40 {
  font-size: 40px;
}
.font-bold {
  font-weight: bold !important;
}
.border-rounded-5 {
  border-radius: 5px;
}
.border-line {
  border: solid 1px #ccc;
}
.border-line.light-gray {
  border: solid 1px #ccc;
}
.v-line-left-8 {
  border-left: solid 8px #ccc !important;
}
.v-line-left-8.v-line-left-dusk-blue {
  border-left: solid 8px #225280 !important;
}
.fal {
  font-family: FontAwesomePro;
}
.fab {
  font-family: FontAwesomeProBrand;
}
.fas {
  font-family: FontAwesomeProSolid;
}
.fad {
  font-family: FontAwesomeProDuotone;
}
.fa-telecom-signal:before {
  /* content: url("/file/general/Group 223.svg"); */
}
.text-left-important {
  text-align: left !important;
}
.modal-backdrop.in {
  opacity: 0.95 !important;
}
.font-color-primary-personal {
  color: #00b0ea;
}
.font-color-secondary1-personal {
  color: #65e2ff;
}
.font-color-secondary2-personal {
  color: #33c0ee;
}
.font-color-secondary2-personal {
  color: #33c0ee;
}
.font-color-secondary3-personal {
  color: #0081b7;
}
.font-color-primary-cablevision {
  color: #dd2534;
}
.font-color-secondary1-cablevision {
  color: #b72025;
}
.font-color-secondary1-cablevision {
  color: #b72025;
}
.font-color-secondary2-cablevision {
  color: #8d1d1d;
}
.font-color-secondary3-cablevision {
  color: #f4731a;
}
.font-color-primary-fibertel {
  color: #225380;
}
.font-color-secondary1-fibertel {
  color: #567fb0;
}
.font-color-secondary2-fibertel {
  color: #415f84;
}
.font-color-secondary2-fibertel {
  color: #415f84;
}
.font-color-secondary3-fibertel {
  color: #002b53;
}
.font-color-555 {
  color: #555;
}
.font-color-body {
  color: #555;
}
.font-color-white {
  color: #fff;
}
.trilogo {
  border-top: 4px solid;
  border-color: #dd2534;
  padding-top: 10px;
  box-shadow: 0 8px 6px -6px #ccc;
  text-align: center;
}
.no-padding {
  padding: 0;
}
.no-lateral-padding {
  padding-left: 0;
  padding-right: 0;
}
.no-padding-left {
  padding-left: 0;
}
.no-padding-right {
  padding-right: 0;
}
.no-margin {
  margin: 0;
}
.no-lateral-margin {
  margin-left: 0;
  margin-right: 0;
}
.no-margin-left {
  margin-left: 0;
}
.no-margin-right {
  margin-right: 0;
}
.heading-xl {
  font-size: 48px;
  font-family: Lato-Bold;
}
.dsk-lato-regular-48 {
  font-family: Lato-Regular;
  font-size: 48px;
}
.dsk-title-lato-regular-18 {
  font-size: 28px;
  font-family: Lato-Regular;
}
.dsk-title-l {
  font-size: 40px;
  font-family: Lato-Bold;
}
.dsk-title-black-xl {
  font-size: 42px;
  font-family: Lato-Black;
  font-weight: normal;
}
.dsk-title-black-l {
  font-size: 40px;
  font-family: Lato-Black;
  font-weight: normal;
}
.dsk-title-l {
  font-size: 48px;
  font-family: Lato-Regular;
  font-weight: normal;
}
.dsk-title-black-m {
  font-size: 32px;
  font-family: Lato-Black;
  font-weight: normal;
}
.dsk-title-black-sm {
  font-size: 28px;
  font-family: Lato-Black;
  font-weight: normal;
}
.dsk-title-black-s {
  font-size: 24px;
  font-family: Lato-Black;
  font-weight: normal;
}
.dsk-title-bold-sub {
  font-size: 18px;
  font-family: Lato-Bold;
}
.dsk-title-black-sub {
  font-size: 18px;
  font-family: Lato-Black;
}
.dsk-title-m {
  font-size: 32px;
  font-family: Lato-Regular;
  font-weight: normal;
}
.dsk-subtitle-s-uy{
  font-family: Lato-Regular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
.dsk-title-s {
  font-size: 24px;
  font-family: Lato-Regular;
  font-weight: normal;
}
.dsk-lato-bold-18-333{
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.25);
  font-family: Lato-Bold;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333 !important;
}
.dsk-title-s-bold {
  font-size: 24px;
  font-family: Lato-Bold;
  font-weight: normal;
}
.dsk-title-sm {
  font-size: 20px;
  font-family: Lato-Regular;
  font-weight: normal;
}
.dsk-title-bold-sm {
  font-size: 20px;
  font-family: Lato-Bold;
}
.dsk-title-light {
  font-size: 24px;
  font-family: Lato-Light;
  font-weight: normal;
}
.dsk-title-bold-m {
  font-size: 32px;
  font-family: Lato-Bold;
}
.dsk-title {
  font-size: 24px;
  font-family: Lato-Light;
}
.dsk-tahoma-bold{
  font-size: 11px;
  font-family: Tahoma-Bold;
}
.dsk-tahoma-regular-12{
  font-size: 12px;
  font-family: Tahoma-Regular;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}
.dsk-tahoma-regular{
  font-size: 10px;
  font-family: Tahoma-Regular;
}
.dsk-title-footer {
  height: 20px;
  font-family: Tahoma;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.82;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}
.dsk-item-footer {
  height: 20px;
  margin: 0 17px 0 0;
  font-family: Tahoma;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #666666;
}
.dsk-title-bold {
  font-size: 24px;
  font-family: Lato-Bold;
}
.dsk-title-lato-regular {
  font-size: 24px;
  font-family: Lato-Regular;
  font-weight: normal;
}

.dsk-title-sub {
  font-size: 18px;
  font-family: Lato-Regular;
  font-weight: normal;
}
.dsk-body-s {
  font-size: 14px;
  font-family: Roboto-Regular;
  font-weight: normal;
}
.dsk-body-m {
  font-size: 16px;
  font-family: Roboto-Regular;
  font-weight: normal;
}
.dsk-body-l {
  font-size: 18px;
  font-family: Roboto-Regular;
  font-weight: normal;
}
.dsk-body-m-rbm {
  font-size: 16px;
  font-family: Roboto-Medium;
  font-weight: normal;
}
.dsk-body-rbm {
  font-size: 14px;
  font-family: Roboto-Medium;
  font-weight: normal;
}
.dsk-body-rbr {
  font-size: 14px;
  font-family: Roboto-Regular;
  font-weight: normal;
}
.dsk-body-rbb {
  font-size: 14px;
  font-family: Roboto-Bold;
  font-weight: normal;
}
.dsk-body {
  font-size: 14px;
  font-family: Roboto-Regular;
  font-weight: normal;
}
.dsk-body-lato-regular-14 {
  font-size: 14px;
  font-family: Lato-Regular;
  font-weight: normal;
}
.dsk-body-lato-bold-14 {
  font-size: 14px;
  font-family: Lato-Bold;
  font-weight: normal;
}
.dsk-body-medium-m {
  font-size: 16px;
  font-family: Roboto-Medium;
  font-weight: normal;
}
.dsk-body-medium {
  font-size: 14px;
  font-family: Roboto-Medium;
  font-weight: normal;
}
.dsk-label {
  font-size: 12px;
  font-family: Roboto-Regular;
  color: #888;
  font-weight: normal;
}
.mob-label {
  font-size: 12px;
  font-family: Roboto-Regular;
}
.dsk-label-10 {
  font-size: 10px;
  font-family: Roboto-Regular;
  color: #888;
  font-weight: normal;
}
.dsk-label-11 {
  font-size: 11px;
  font-family: Roboto-Regular;
  color: #888;
  font-weight: normal;
}
.dsk-label-13 {
  font-size: 13px;
  font-family: Roboto-Regular;
  color: #888;
  font-weight: normal;
}
.dsk-label-14 {
  font-size: 14px !important;
  font-family: Roboto-Regular;
  color: #555;
  font-weight: normal;
}
.mbl-title-xl {
  font-size: 32px;
}
.mbl-title-l {
  font-size: 28px;
  font-family: Lato-Regular;
  font-weight: normal;
}
.mbl-title-l-18 {
  font-size: 18px;
  font-family: Lato-Regular;
  font-weight: normal;
}
.mbl-title-l-22 {
  font-size: 22px;
  font-family: Lato-Regular;
  font-weight: normal;
}
.mbl-title-l-22-lato-bold {
  font-size: 22px;
  font-family: Lato-Bold;
  font-weight: normal;
}
.mbl-title-l-24 {
  font-size: 24px;
  font-family: Lato-Regular;
  font-weight: normal;
}
.mbl-title-24-lato-bold {
  font-size: 24px;
  font-family: Lato-Bold;
  font-weight: normal;
}
.mbl-title-bold-l {
  font-size: 28px;
  font-family: Lato-Bold;
}
.mbl-title-m {
  font-size: 24px;
  font-family: Lato-Regular;
  font-weight: normal;
}
.mbl-title-m-roboto {
  font-size: 24px;
  font-family: Roboto-Regular;
  font-weight: normal;
}
.mbl-title {
  font-size: 20px;
  font-family: Lato-Regular;
  font-weight: normal;
}
.mbl-title-lato-bold-20 {
  font-size: 20px;
  font-family: Lato-Bold;
}
.mbl-title-s {
  font-size: 18px;
}
.mbl-title-xs {
  font-size: 14px;
  font-family: Roboto-Regular;
}
.font-bold {
  font-weight: bold;
}
body {
  color: #555;
  font-size: 14px;
  font-family: Roboto-Regular;
}
h1,
h2,
h3,
h4 {
  color: #333;
}
h2 {
  font-size: 18px;
}
a {
  color: #0081b7;
  font-family: Roboto-Regular;
}
a:hover {
  color: #225380;
}
.hover-fb a,
.hover-cb a,
.hover-cb-flow a,
.hover-p a {
  color: #555 !important;
  font-weight: normal;
}
.hover-fb a:hover {
  text-decoration: none;
  color: #225380 !important;
  border-bottom: 1px solid #225380;
}
.hover-cb a:hover {
  text-decoration: none;
  color: #dd2534 !important;
  border-bottom: 1px solid #dd2534;
}
.hover-cb-flow a:hover {
  text-decoration: none;
  color: #57dfb7 !important;
  border-bottom: 1px solid #57dfb7;
}
.hover-p a:hover {
  text-decoration: none;
  color: #00b0ea !important;
  border-bottom: 1px solid #00b0ea;
}
.footer-link-text > a {
  color: #333;
  opacity: 0.6;
}
.btn.focus,
.btn:focus,
.btn:hover {
  color: #fff;
}
.background-interfaz {
  background-color: #f5f5f5;
}
.btn-global {
  padding: 8px 24px;
  border-radius: 25px;
  text-transform: uppercase;
  max-height: 40px;
}
.cv-btn-lg {
  padding: 10px 69px;
}
.cv-btn-xlg {
  padding: 10px 95px;
}
.pr-btn-xlg {
  padding: 10px 90px;
}
label {
  font-size: 12px;
  font-family: Roboto-Regular;
}
.has-error .form-control,
.form-control.has-error {
  border-color: #c33;
}
.has-error .input-group-addon {
  color: inherit;
  background-color: #eee;
  border-color: #c33;
}
.has-error .form-control {
  border-color: #c33;
  border-left: none;
}
p.has-error {
  padding: 0;
}
.text-error {
  width: 88%;
  float: left;
  padding-left: 5px;
}
.icon-error {
  float: left;
  color: #c33;
}
.icon-close {
  float: right;
  color: #888;
}
.form-control.has-error {
  border-color: #c33;
}
.font-xs-info.has-error {
  color: #c33;
}
.required-field {
  color: #c33;
}
.input-group-addon {
  border-radius: 2px 0 2px 0;
}
.font-xs-info {
  color: #888;
  padding: 8px 0 0 0;
}
.form-control {
  height: 40px;
  padding: 6px 7px;
  border-radius: 2px;
}
.form-error-container {
  width: 410px;
  height: 76px;
  background-color: #f9eaea;
  border-radius: 2px;
  border: 1px solid #c33;
  padding: 13px 16px;
  margin-bottom: 15px;
}
.selec-t {
  position: relative;
  border: 1px solid #ccc;
  height: 40px;
  clear: both;
  border-radius: 2px;
  box-sizing: border-box;
  padding-left: 1px;
  background-color: #fff;
}
select:focus {
  outline: none;
}
.selec-t:hover,
.selec-t:focus {
  border-color: #225380;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}
.selec-t select {
  cursor: pointer;
  padding: 11px 3px 0 6px;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  background-color: #fff;
}
.content-arrow {
  position: absolute;
  top: 9px;
  left: 70%;
}
.selec-t .content-arrow::after {
  content: "";
  border-right: 1px solid #ccc;
}
.selec-t select:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.select_arrow {
  position: absolute;
  top: 16px;
  right: 9px;
  pointer-events: none;
  border-radius: 2px;
  border-top: 6px solid #333;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
}
.selec-t select:hover ~ .select_arrow,
.selec-t select:focus ~ .select_arrow {
  border-top-color: #000;
}
.selec-t select:disabled ~ .select_arrow {
  border-top-color: #ccc;
}
.separator {
  margin: 20px 0;
  border-bottom: 1px solid #eee;
}
.btn-p {
  color: #fff;
  background-color: #00b0ea;
}
.btn-p-s01 {
  color: #fff;
  background-color: #65e2ff;
}
.btn-p-s02 {
  color: #fff;
  background-color: #33c0ee;
}
.btn-p-s03 {
  color: #fff;
  background-color: #0081b7;
}
.btn-pr {
  color: #fff;
  background-color: #0094ff;
  border-radius: 0;
}
.btn-flow {
  color: #1b1b1d;
  background-color: #57dfb7;
  border-radius: 0;
}
.btn-cv {
  color: #fff;
  background-color: #dd2534;
  font-family: Roboto-Regular;
}
.btn-cv-s01 {
  color: #fff;
  background-color: #b72025;
}
.btn-cv-s02 {
  color: #fff;
  background-color: #8d1d1d;
}
.btn-cv-s03 {
  color: #fff;
  background-color: #f4731a;
}
.btn-fb {
  color: #fff;
  background-color: #225380;
}
.btn-fb-s01 {
  color: #fff;
  background-color: #567fb0;
}
.btn-fb-s02 {
  color: #fff;
  background-color: #415f84;
}
.btn-fb-s03 {
  color: #fff;
  background-color: #002b53;
}
.btn-cv-inverted {
  background-color: transparent;
  border: 1px solid #dd2534;
  color: #dd2534;
}
.btn-cv:hover,
.btn-cv-inverted:hover {
  color: #fff;
  background: #b72025;
  border: 1px solid #b72025;
}
.btn-cv-inverted {
  box-shadow: none;
}
.btn-cv:active,
.btn-cv-inverted:active,
.btn-cv:active {
  background: #8d1d1d;
  border: 1px solid #8d1d1d;
  outline: none;
}
.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: none;
}
.btn-p-inverted {
  background-color: transparent;
  border: 1px solid #00b0ea;
  color: #00b0ea;
}
.btn-p:hover,
.btn-pr:hover,
.btn-p-inverted:hover {
  color: #fff;
  background: #65e2ff;
  border: 1px solid #65e2ff;
}
.btn-p-inverted {
  box-shadow: none;
}
.btn-p:active,
.btn-pr:active,
.btn-p-inverted:active {
  background: #33c0ee;
  border: 1px solid #33c0ee;
}
.label-p {
  color: #fff;
  background-color: #00b0ea;
}
.label-p-s01 {
  color: #fff;
  background-color: #65e2ff;
}
.label-p-s02 {
  color: #fff;
  background-color: #33c0ee;
}
.label-p-s03 {
  color: #fff;
  background-color: #0081b7;
}
.label-cv {
  color: #fff;
  background-color: #dd2534;
}
.label-cv-s01 {
  color: #fff;
  background-color: #b72025;
}
.label-cv-s02 {
  color: #fff;
  background-color: #8d1d1d;
}
.label-cv-s03 {
  color: #fff;
  background-color: #f4731a;
}
.label-fb {
  color: #fff;
  background-color: #225380;
}
.label-fb-s01 {
  color: #fff;
  background-color: #567fb0;
}
.label-fb-s02 {
  color: #fff;
  background-color: #415f84;
}
.label-fb-s03 {
  color: #fff;
  background-color: #002b53;
}
.label-fb-pr {
  color: #fff;
  background-color: #291e3e;
}
.box-content-form-v1 {
  width: 410px;
  height: auto;
  border-radius: 6px;
  border: solid 1px #ccc;
  padding-bottom: 42px;
  overflow: hidden;
  margin-bottom: 56px;
  background-color: #fff;
}
.back-container-formv2 {
  padding: 50px 0 0 46px;
}
.box-content-form-v1 .content-form {
  padding: 0 43px;
  font-family: Roboto-Regular;
}
.box-content-form-v1 label {
  font-size: 12px;
  font-weight: normal;
}
.box-content-form-v1 .content-form h2 {
  padding: 3px 0 17px 0;
  width: 300px;
}
.box-content-form-v1 .hd-box {
  float: left;
  font-weight: bold;
}
.box-content-form-v1 .form-control:focus {
  border-color: #225380;
}
.box-head .chat {
  color: #0081b7;
}
.box-head .phone a {
  color: #555;
}
.box-content-form-v1 .hd-box.chat {
  padding: 19px 0 12px 40px;
}
.box-content-form-v1 .hd-box.phone {
  padding: 19px 37.5px 12px 0;
  text-align: right;
}
.row.legales-row {
  background: #fff;
}
.row-legales hr {
  margin-bottom: 0;
}
i.user-circle {
  font-family: FontAwesomeProSolid;
  font-size: 48px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #e0e0e0;
}
i.user-circle.red {
  border-radius: 200px 200px 200px 200px;
  -moz-border-radius: 200px 200px 200px 200px;
  -webkit-border-radius: 200px 200px 200px 200px;
  border: 2px solid #c33;
  padding: 0;
}
.chat .fa-comment-lines:before {
  content: "\f4b0";
  color: #888;
  font-size: 18px;
  margin-right: 8px;
  font-weight: lighter;
  padding: 0;
}
.phone .fal.fa-phone.fa-rotate-90:before {
  content: "\f095";
  font-family: inherit;
  text-decoration: inherit;
  color: #888;
  font-size: 18px;
  margin: 0 8px 0 10px;
  font-weight: lighter;
  padding: 0;
}
.box-head {
  background-color: #f5f5f5;
}
.box-content-form-v1 .content-form {
  padding: 0 43px 0 43px;
}
.box-content-form-v1 .content-form button {
  position: relative;
  top: 14px;
}
.box-content-form-v1 label {
  font-size: 12px;
  font-weight: normal;
}
.box-head {
  background-color: #f5f5f5;
  height: 55px;
  line-height: 1.5;
}
.box-content-form-v1 .hd-box {
  float: left;
}
@media (min-width: 769px) {
  .box-content-form-v1 .hd-box {
    width: 50%;
  }
}
.box-content-form-v1 .form-control:focus {
  border-color: #225380;
}
.box-content-form-v1 .form-box-a {
  width: 30%;
  float: left;
}
.box-content-form-v1 .form-box-a label,
.box-content-form-v1 .form-box-b label {
  float: left;
  width: 100%;
}
.box-content-form-v1 .form-box-b {
  float: left;
  width: 70%;
  padding-left: 10px;
  margin: 0 0 15px 0;
}
.form-group-a .input-group-addon,
.form-group-b .input-group-addon {
  padding: 6px 8px;
}
.form-group-a,
.form-group-b {
  margin-bottom: 9px;
}
.form-group-a {
  width: 37%;
  float: left;
  padding: 0 7px 0 0;
}
.form-group-b {
  width: 63%;
  float: left;
}
.content-legales {
  padding: 20px 0 15px 0;
  clear: both;
}
.widget-separator {
  border-top: solid 1px #ccc;
  margin-top: 30px;
  margin-bottom: 30px;
}
.using-mouse {
  outline: none !important;
}
.full-width {
  width: 100%;
  padding: 0 !important;
}
.pointer {
  cursor: pointer;
}
#page {
  padding: 0;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
}
#page .row {
  margin-right: 0;
  margin-left: 0;
}
#page .image-container img {
  max-width: fit-content;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  width: 100%;
}
.background-form {
  background-color: #fff;
}
.selec-t .select {
  background: #fff;
}
.dsk-txt-sm {
  font-size: 12px;
}
.hr-top-0 {
  margin-top: 0;
}
.form-error-container {
  margin: auto;
}
.has-error .form-control {
  border-color: #c33;
  border-left: 1px solid #c33;
}
p.dsk-body.bold {
  font-weight: bold;
}
.left-line-wrapper-cv:before {
  content: "";
  width: 5px;
  height: 100%;
  position: absolute;
  left: -5px;
  top: 0;
  border-radius: 15px;
  background: #dd2534;
}
.left-line-wrapper-p:before {
  content: "";
  width: 5px;
  height: 100%;
  position: absolute;
  left: -5px;
  top: 0;
  border-radius: 15px;
  background: #00b0ea;
}
.left-line-wrapper-fb:before {
  content: "";
  width: 5px;
  height: 100%;
  position: absolute;
  left: -5px;
  top: 0;
  border-radius: 15px;
  background: #225380;
}
@media (max-width: 768px) {
  .form-bg {
    padding: 0;
  }
  .content-legales {
    padding: 10px 16px 10px 16px;
    text-align: left;
  }
  .content-legales p {
    margin-bottom: 4px;
  }
  .content-legales #legales {
    text-align: left !important;
  }
  .content-legales .content-box-legales {
    padding: 0 16px 0 0;
  }
  .back-container-formv2 {
    padding: 0 !important;
  }
  .back-container-formv1 {
    background-color: #f5f5f5;
    background-image: none;
  }
  .widget-margin-top {
    margin-top: 36px;
    margin-bottom: 211px;
  }
  .form-margin {
    margin-left: 0;
    padding: 0;
    margin-top: 38px;
    margin-bottom: 50px;
  }
  .box-content-form-v1 .content-form {
    padding: 0 16px 0 16px;
  }
  .box-content-form-v1 .hd-box.chat {
    padding: 17px 0 15px 15px;
    margin-right: 46px;
    width: 46%;
  }
  .box-content-form-v1 .hd-box.phone {
    padding: 17px 0 15px 0;
    text-align: right;
    display: inline-flex;
  }
  .text-error {
    width: 84%;
  }
  .form-mobile .text-error {
    margin-top: -8px;
  }
  .form-error-container {
    width: 100%;
  }
}
@media (max-width: 768px) and (max-width: 768px) {
  .box-content-form-v1 .hd-box.phone {
    padding: 17px 14.5px 15px 0;
    margin-left: -7px;
    display: block;
  }
}
.phone-chat-section {
  height: 42px;
  background-color: #282629;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 768px) {
  .phone-chat-section {
    height: 48px;
  }
}
.phone-chat-section .phone-icon-mobile {
  padding-top: 15px;
  font-size: 16px;
  color: #dd2534;
  -webkit-font-smoothing: antialiased;
}
.phone-chat-section .pipe {
  color: #ccc;
}
.phone-chat-section .phone-text {
  padding-right: 24px;
}
.phone-chat-section .phone-text .phone-number {
  color: #333;
}
.phone-chat-section .phone-text .phone-in-mobile {
  color: #dd2534;
  padding-top: 15px;
  font-size: 16px;
}
.phone-chat-section .phone-text a {
  color: #555;
}
@media (max-width: 768px) {
   a {
    font-weight: bold;
    color: #dd2534;
  }
}
.phone-chat-section .phone-text a:hover {
  text-decoration: none;
  cursor: auto;
}
.phone-chat-section .phone-text .phone-icon {
  color: #888;
}
.phone-chat-section .chat-text {
  padding-left: 24px;
}
.phone-chat-section .chat-text .chat-icon {
  font-size: 16px;
  padding-right: 7px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
}
.phone-chat-section .chat-text .chat-link,
.phone-chat-section .chat-text .chat-icon {
  color: #dd2534;
}
.modal {
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
}
.modal-mobile .modal-dialog {
  margin: 0;
  height: 100%;
}
.modal-mobile .modal-header {
  padding: 15px 34px 15px 34px;
  border-bottom: 0;
}
.modal-mobile .modal-body {
  padding: 15px 34px 15px 34px;
  text-align: justify;
}
.modal-mobile .modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}
.modal-video .desktop-video-container {
  width: 100%;
  margin-top: 0;
}
@media (max-width: 768px) {
  .modal-video .desktop-video-container {
    margin: 0 !important;
  }
  .modal-video .desktop-video-container .video-close {
    right: 0 !important;
    top: 0 !important;
    padding: 24px !important;
  }
  .modal-video .desktop-video-container .modal-body {
    padding: 0;
    margin-top: calc(30vh);
  }
  .modal-video .desktop-video-container .modal-body iframe,
  .modal-video .desktop-video-container .modal-body video {
    height: 190px !important;
  }
}
.modal-video .desktop-video-container .desktop-video-styles {
  background-color: transparent;
  background-clip: none;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.modal-video .desktop-video-container .desktop-video-styles .video-close {
  position: absolute;
  right: 340px;
  top: 38px;
  z-index: 999999;
  border: none;
  font-size: 41px;
  font-weight: normal;
}
.modal-video
  .desktop-video-container
  .desktop-video-styles
  .video-close
  button {
  color: #fff;
  text-shadow: none;
  opacity: 1;
  font-size: 41px;
  font-weight: normal;
}
.modal-video
  .desktop-video-container
  .desktop-video-styles
  .video-close
  button:active,
.modal-video
  .desktop-video-container
  .desktop-video-styles
  .video-close
  button:focus,
.modal-video
  .desktop-video-container
  .desktop-video-styles
  .video-close
  button:hover {
  outline: none;
}
@media (max-width: 768px) {
  .modal-video
    .desktop-video-container
    .desktop-video-styles
    .video-close
    button {
    font-size: 31px;
  }
}
.modal-video .desktop-video-container .desktop-video-styles .modal-body {
  padding: 0;
  max-height: 779px;
}
.modal-video .desktop-video-container .desktop-video-styles .modal-body iframe,
.modal-video .desktop-video-container .desktop-video-styles .modal-body video {
  width: 100%;
  height: 779px;
}
@media (max-width: 768px) {
  .modal-text .modal-dialog,
  .modal-text-cta .modal-dialog,
  .modal-image-text .modal-dialog,
  .modal-text-tierra-fuego .modal-dialog {
    margin: 0;
    height: 100%;
    width: auto !important;
  }
  .modal-text .modal-header,
  .modal-text-cta .modal-header,
  .modal-image-text .modal-header,
  .modal-text-tierra-fuego .modal-header {
    padding: 15px 34px 15px 27px;
    border-bottom: 0;
  }
  .modal-text .modal-header img,
  .modal-text-cta .modal-header img,
  .modal-image-text .modal-header img,
  .modal-text-tierra-fuego .modal-header img {
    width: 100% !important;
  }
  .modal-text .modal-body,
  .modal-text-cta .modal-body,
  .modal-image-text .modal-body,
  .modal-text-tierra-fuego .modal-body {
    padding: 15px 34px 15px 27px;
    text-align: justify;
  }
  .modal-text .modal-content,
  .modal-text-cta .modal-content,
  .modal-image-text .modal-content,
  .modal-text-tierra-fuego .modal-content {
    height: auto !important;
    border-radius: 0;
    padding: 0 16px !important;
  }
}
.modal-text .modal-dialog,
.modal-image-text .modal-dialog {
  width: 1000px;
  height: 405px;
}
.modal-text .modal-dialog .modal-content,
.modal-text-cta .modal-dialog .modal-content,
.modal-image-text .modal-dialog .modal-content,
.modal-text-tierra-fuego .modal-dialog .modal-content {
  background-color: #333;
  color: #ccc;
}
.modal-text .modal-dialog .modal-content .modal-header,
.modal-text-cta .modal-dialog .modal-content .modal-header,
.modal-image-text .modal-dialog .modal-content .modal-header,
.modal-text-tierra-fuego .modal-dialog .modal-content .modal-header {
  padding: 24px;
  padding-bottom: 0;
  border: none;
}
.modal-text .modal-dialog .modal-content .modal-header .modal-title,
.modal-text-cta .modal-dialog .modal-content .modal-header .modal-title,
.modal-image-text .modal-dialog .modal-content .modal-header .modal-title,
.modal-text-tierra-fuego
  .modal-dialog
  .modal-content
  .modal-header
  .modal-title {
  color: #ccc;
  font-family: Lato-Regular;
  font-size: 24px;
  margin-top: 53px;
  padding: 0 34px;
}
.modal-text .modal-dialog .modal-content .modal-header .close,
.modal-text-cta .modal-dialog .modal-content .modal-header .close,
.modal-image-text .modal-dialog .modal-content .modal-header .close,
.modal-text-tierra-fuego .modal-dialog .modal-content .modal-header .close {
  color: #ccc;
  opacity: 1;
  font-weight: normal;
  font-size: 30px;
  height: 30px !important;
  width: 30px !important;
}
.modal-text .modal-dialog .modal-content .modal-header .close:active,
.modal-text-cta .modal-dialog .modal-content .modal-header .close:active,
.modal-image-text .modal-dialog .modal-content .modal-header .close:active,
.modal-text-tierra-fuego
  .modal-dialog
  .modal-content
  .modal-header
  .close:active,
.modal-text .modal-dialog .modal-content .modal-header .close:focus,
.modal-text-cta .modal-dialog .modal-content .modal-header .close:focus,
.modal-image-text .modal-dialog .modal-content .modal-header .close:focus,
.modal-text-tierra-fuego
  .modal-dialog
  .modal-content
  .modal-header
  .close:focus,
.modal-text .modal-dialog .modal-content .modal-header .close:hover,
.modal-text-cta .modal-dialog .modal-content .modal-header .close:hover,
.modal-image-text .modal-dialog .modal-content .modal-header .close:hover,
.modal-text-tierra-fuego
  .modal-dialog
  .modal-content
  .modal-header
  .close:hover {
  outline: none;
}
@media (max-width: 768px) {
  .modal-text .modal-dialog .modal-content .modal-header,
  .modal-text-cta .modal-dialog .modal-content .modal-header,
  .modal-image-text .modal-dialog .modal-content .modal-header,
  .modal-text-tierra-fuego .modal-dialog .modal-content .modal-header {
    padding: 24px 8px 24px 11px;
  }
  .modal-text .modal-dialog .modal-content .modal-header .modal-title,
  .modal-text-cta .modal-dialog .modal-content .modal-header .modal-title,
  .modal-image-text .modal-dialog .modal-content .modal-header .modal-title,
  .modal-text-tierra-fuego
    .modal-dialog
    .modal-content
    .modal-header
    .modal-title {
    padding: 0;
  }
}
.modal-text .modal-dialog .modal-content .modal-body,
.modal-text-cta .modal-dialog .modal-content .modal-body,
.modal-image-text .modal-dialog .modal-content .modal-body,
.modal-text-tierra-fuego .modal-dialog .modal-content .modal-body {
  position: relative;
  padding: 26px 58px;
  padding-right: 19px;
  text-align: justify;
}
@media (max-width: 768px) {
  .modal-text .modal-dialog .modal-content .modal-body,
  .modal-text-cta .modal-dialog .modal-content .modal-body,
  .modal-image-text .modal-dialog .modal-content .modal-body,
  .modal-text-tierra-fuego .modal-dialog .modal-content .modal-body {
    padding: 0 11px;
  }
}
@media (max-width: 768px) {
  .modal-text .modal-dialog .modal-content .modal-body.custom-modal-scrollbar,
  .modal-text-cta
    .modal-dialog
    .modal-content
    .modal-body.custom-modal-scrollbar,
  .modal-image-text
    .modal-dialog
    .modal-content
    .modal-body.custom-modal-scrollbar,
  .modal-text-tierra-fuego
    .modal-dialog
    .modal-content
    .modal-body.custom-modal-scrollbar {
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }
}
.modal-text .modal-dialog .modal-footer,
.modal-text-cta .modal-dialog .modal-footer,
.modal-image-text .modal-dialog .modal-footer,
.modal-text-tierra-fuego .modal-dialog .modal-footer {
  border: none;
  margin: 25px 75px 59px 58px;
  padding: 0;
}
.modal-text .modal-dialog .modal-footer [data-dismiss],
.modal-text-cta .modal-dialog .modal-footer [data-dismiss],
.modal-image-text .modal-dialog .modal-footer [data-dismiss],
.modal-text-tierra-fuego .modal-dialog .modal-footer [data-dismiss] {
  padding: 10px;
  margin: 0;
  margin-right: 38px;
  height: auto !important;
  width: auto !important;
}
.modal-text .modal-dialog .modal-footer .btn,
.modal-text-cta .modal-dialog .modal-footer .btn,
.modal-image-text .modal-dialog .modal-footer .btn,
.modal-text-tierra-fuego .modal-dialog .modal-footer .btn {
  width: 173px;
  color: #fff;
  float: none;
  border-color: transparent;
}
.modal-text .modal-dialog .modal-footer .btn a,
.modal-text-cta .modal-dialog .modal-footer .btn a,
.modal-image-text .modal-dialog .modal-footer .btn a,
.modal-text-tierra-fuego .modal-dialog .modal-footer .btn a,
.modal-text .modal-dialog .modal-footer .btn a:link,
.modal-text-cta .modal-dialog .modal-footer .btn a:link,
.modal-image-text .modal-dialog .modal-footer .btn a:link,
.modal-text-tierra-fuego .modal-dialog .modal-footer .btn a:link,
.modal-text .modal-dialog .modal-footer .btn a:visited,
.modal-text-cta .modal-dialog .modal-footer .btn a:visited,
.modal-image-text .modal-dialog .modal-footer .btn a:visited,
.modal-text-tierra-fuego .modal-dialog .modal-footer .btn a:visited {
  color: #fff;
}
@media (max-width: 768px) {
  .modal-text .modal-dialog .modal-footer,
  .modal-text-cta .modal-dialog .modal-footer,
  .modal-image-text .modal-dialog .modal-footer,
  .modal-text-tierra-fuego .modal-dialog .modal-footer {
    margin: 56px 1px 60px 1px;
    text-align: center;
  }
  .modal-text .modal-dialog .modal-footer .btn,
  .modal-text-cta .modal-dialog .modal-footer .btn,
  .modal-image-text .modal-dialog .modal-footer .btn,
  .modal-text-tierra-fuego .modal-dialog .modal-footer .btn {
    width: 326px;
    margin: 0 auto;
  }
  .modal-text .modal-dialog .modal-footer [data-dismiss],
  .modal-text-cta .modal-dialog .modal-footer [data-dismiss],
  .modal-image-text .modal-dialog .modal-footer [data-dismiss],
  .modal-text-tierra-fuego .modal-dialog .modal-footer [data-dismiss] {
    margin: 0;
    margin-top: 20px;
    padding: 10px;
  }
}
.modal-text-tierra-fuego .modal-dialog {
  width: 802px;
  margin-top: 89px;
}
.modal-text-tierra-fuego .modal-dialog .modal-content {
  height: 290px;
}
@media (min-width: 768px) {
  .modal-text-tierra-fuego .modal-dialog .modal-content {
    padding: 0 34px 0 108px;
  }
}
@media (max-width: 768px) {
  .modal-text-tierra-fuego .modal-dialog .modal-content {
    height: 100vh !important;
    padding: 0 25px !important;
  }
}
.modal-text-tierra-fuego .modal-dialog .modal-content .modal-header {
  padding: 0;
}
.modal-text-tierra-fuego
  .modal-dialog
  .modal-content
  .modal-header
  .modal-title {
  font-size: 24px;
}
.modal-text-tierra-fuego .modal-dialog .modal-content .modal-header .close {
  text-shadow: none;
}
.modal-text-tierra-fuego .modal-dialog .modal-content .modal-body {
  padding: 0;
}
.modal-text-tierra-fuego
  .modal-dialog
  .modal-content
  .modal-body
  .modal-subtitle {
  margin: 10px 0 38px 0;
}
.modal-text-tierra-fuego
  .modal-dialog
  .modal-content
  .modal-body
  .modal-select-container {
  width: 100%;
}
.modal-text-tierra-fuego
  .modal-dialog
  .modal-content
  .modal-body
  .modal-select-container
  .input-container {
  height: 40px;
}
@media (max-width: 768px) {
  .modal-text-tierra-fuego
    .modal-dialog
    .modal-content
    .modal-body
    .modal-select-container
    .input-container {
    width: 100%;
  }
}
.modal-text-tierra-fuego
  .modal-dialog
  .modal-content
  .modal-body
  .modal-select-container
  .input-container
  input {
  width: 380px;
  height: 40px;
  border-radius: 2px;
  border: 1px solid #ccc;
  padding-left: 41px;
  color: #555;
  line-height: 1.43;
}
@media (max-width: 768px) {
  .modal-text-tierra-fuego
    .modal-dialog
    .modal-content
    .modal-body
    .modal-select-container
    .input-container
    input {
    width: 100%;
  }
}
.modal-text-tierra-fuego
  .modal-dialog
  .modal-content
  .modal-body
  .modal-select-container
  .input-container
  i {
  position: relative;
  right: 368px;
  top: 2px;
  color: #888;
  font-size: 18px;
}
@media (max-width: 768px) {
  .modal-text-tierra-fuego
    .modal-dialog
    .modal-content
    .modal-body
    .modal-select-container
    .input-container
    i {
    bottom: 27px;
    top: unset;
    left: 11px;
  }
}
.modal-text-tierra-fuego
  .modal-dialog
  .modal-content
  .modal-body
  .modal-select-container
  .modal-location-button,
.modal-text-tierra-fuego
  .modal-dialog
  .modal-content
  .modal-body
  .modal-select-container:link,
.modal-text-tierra-fuego
  .modal-dialog
  .modal-content
  .modal-body
  .modal-select-container:visited {
  width: 173px;
  height: 40px;
  margin-left: 20px;
  color: #fff;
  outline: none;
  border: none;
  padding-top: 10px;
}
@media (max-width: 768px) {
  .modal-text-tierra-fuego
    .modal-dialog
    .modal-content
    .modal-body
    .modal-select-container
    .modal-location-button,
  .modal-text-tierra-fuego
    .modal-dialog
    .modal-content
    .modal-body
    .modal-select-container:link,
  .modal-text-tierra-fuego
    .modal-dialog
    .modal-content
    .modal-body
    .modal-select-container:visited {
    width: 100%;
    margin-left: unset;
  }
}
.modal-text-tierra-fuego
  .modal-dialog
  .modal-content
  .modal-body
  .modal-select-container
  .input-results {
  display: none;
  width: 380px;
  background: #fff;
  margin-top: 4px;
}
@media (max-width: 768px) {
  .modal-text-tierra-fuego
    .modal-dialog
    .modal-content
    .modal-body
    .modal-select-container
    .input-results {
    width: 100%;
    overflow-y: scroll;
  }
}
.modal-text-tierra-fuego
  .modal-dialog
  .modal-content
  .modal-body
  .modal-select-container
  .input-results
  ul {
  list-style: none;
  padding: 30px 41px 0 41px;
  color: #555;
}
.modal-text-tierra-fuego
  .modal-dialog
  .modal-content
  .modal-body
  .modal-select-container
  .input-results
  ul
  li
  a,
.modal-text-tierra-fuego
  .modal-dialog
  .modal-content
  .modal-body
  .modal-select-container
  .input-results
  ul
  li
  a:link,
.modal-text-tierra-fuego
  .modal-dialog
  .modal-content
  .modal-body
  .modal-select-container
  .input-results
  ul
  li
  a:visited,
.modal-text-tierra-fuego
  .modal-dialog
  .modal-content
  .modal-body
  .modal-select-container
  .input-results
  ul
  li
  a:hover
  span {
  text-decoration: none;
  text-transform: uppercase;
  color: #555;
}
.modal-text-cta .modal-dialog,
.modal-image-text .modal-dialog {
  width: 1010px;
  height: 560px;
}
@media (max-width: 768px) {
  .modal-text-cta .modal-dialog .modal-content,
  .modal-image-text .modal-dialog .modal-content {
    height: auto !important;
    min-height: 100vh;
  }
}
.modal-image-text .close {
  color: #ccc;
  opacity: 1;
  font-weight: normal;
  font-size: 30px;
  width: 30px;
  position: relative;
  z-index: 99;
  right: 24px;
  top: 24px;
}
.modal-image-text .close:active,
.modal-image-text .close:focus,
.modal-image-text .close:hover {
  outline: none;
}
.modal-image-text .modal-content .modal-header {
  padding-left: 0;
  padding-right: 0;
  margin-top: 45px;
  margin-bottom: 26px;
}
.modal-image-text .modal-content img {
  width: 884px;
  height: 172px;
}
.modal-image-text .modal-content .modal-body {
  max-height: 185px;
  overflow-y: scroll;
  margin-right: 37px;
  padding: 0;
  padding-top: 0 !important;
}
.modal-image-text .modal-content .modal-body p {
  margin-bottom: 18px;
}
@media (max-width: 768px) {
  .modal-image-text .modal-content .modal-body {
    padding: 0;
    max-height: 227px;
  }
}
.modal-image-text .modal-content .custom-modal-scrollbar::-webkit-scrollbar {
  width: 13px;
}
.modal-image-text
  .modal-content
  .custom-modal-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
.modal-image-text
  .modal-content
  .custom-modal-scrollbar::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border: 5px solid #fff;
  border-radius: 7px;
}
.modal-image-text
  .modal-content
  .custom-modal-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
.modal-image-text
  .modal-content
  .custom-modal-scrollbar::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border: 5px solid #333;
}
.modal-image-text .modal-dialog .modal-content {
  padding-bottom: 16px;
}
.modal-image-text .modal-dialog .modal-content .modal-header {
  padding: 26px 58px;
  margin-right: 37px;
  padding-right: 19px;
  margin-bottom: 0;
}
.modal-image-text .modal-dialog .modal-content .modal-header .modal-title {
  margin-top: 38px;
  padding: 0;
}
@media (max-width: 768px) {
  .modal-image-text .modal-dialog .modal-content .modal-header {
    padding: 0;
    margin-top: 60px;
    margin-left: 0;
    margin-right: 0;
  }
  .modal-image-text .modal-dialog .modal-content .modal-header .modal-title {
    margin-top: 24px;
    margin-bottom: 24px;
    font-family: Lato-Bold;
    color: #fff;
  }
}
.modal-dialog-vodafonized {
  width: 100%;
  height: 100% !important;
  background: #333;
}
.modal-dialog-vodafonized .modal-container .button-close {
  padding-top: 35px;
}
.modal-dialog-vodafonized .modal-container .button-close button {
  opacity: 1;
}
.modal-dialog-vodafonized .modal-container .button-close button i {
  font-size: 40px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.8;
  letter-spacing: normal;
  color: #f5f5f5 !important;
}
.modal-dialog-vodafonized .modal-container .header-modal {
  padding-top: 55px;
}
.modal-dialog-vodafonized .modal-container .header-modal h3 {
  font-family: Lato-Light;
  font-size: 28px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #f5f5f5;
  margin: 0;
}
.modal-dialog-vodafonized .modal-container .content-modal {
  padding-top: 27px;
}
.modal-dialog-vodafonized .modal-container .content-modal p {
  font-family: Roboto-Regular;
  font-size: 28px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #ccc;
}
@media (max-width: 768px) {
  .modal-dialog-vodafonized {
    height: 100% !important;
  }
  .modal-dialog-vodafonized .modal-container .button-close {
    padding-top: 26px;
    padding-right: 10px;
  }
  .modal-dialog-vodafonized .modal-container .button-close button i {
    font-size: 24px;
  }
  .modal-dialog-vodafonized .modal-container .header-modal {
    padding-top: 48px;
  }
  .modal-dialog-vodafonized .modal-container .header-modal h3 {
    font-size: 24px;
  }
  .modal-dialog-vodafonized .modal-container .content-modal {
    padding-top: 16px;
  }
  .modal-dialog-vodafonized .modal-container .content-modal p {
    font-size: 16px;
  }
}
@media (min-width: 1250px) {
  .container {
    width: 1250px;
  }
}
.rectangles-container {
  margin-top: 35px;
  margin-bottom: 38px;
  display: flex;
  justify-content: center;
}
.rectangles-container .Rectangle {
  width: 184px;
  height: 93px;
  border-radius: 1.3px;
  border: solid 0.7px #ccc;
  background-color: #f5f5f5;
  position: relative;
  display: grid;
  margin: 15px;
}
.rectangles-container .Rectangle :first-of-type {
  margin-left: 0;
}
.rectangles-container .Rectangle :last-of-type {
  margin-right: 0;
}
.rectangles-container .Rectangle .rectangle-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 80%;
}
.rectangles-container .Rectangle .rectangle-content .icon-content {
  text-align: center;
}
.rectangles-container p {
  margin-bottom: 0;
}
.custom-scrollbar::-webkit-scrollbar {
  width: 13px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: #fff;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border: 4px solid #fff;
}
.col-centered {
  display: inline-block;
  float: none;
  text-align: left;
  vertical-align: top;
  margin-right: -4px;
  margin-bottom: 35px;
}
.iconTitle {
  float: left;
}
.fontSize24 {
  font-size: 24px;
}
.textSubtitle {
  margin-left: 40px;
}
@media (max-width: 768px) {
  .textSubtitle {
    margin-left: 59px;
  }
}
.offer-card {
  width: 281px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #fff;
  margin: 20px 14px 10px 14px;
  outline: none;
  position: relative;
}
.offer-card.card-expanded {
  margin-top: 10px;
  box-shadow: 0 0 2px #888;
}
.card-header,
.card-header-gradient,
.card-header-product-gradient,
.card-header-televisión,
.card-header-television,
.card-header-internet,
.card-header-planMovil {
  height: 10px;
  border-radius: 10px 10px 0 0;
}
.card-header-gradient {
  background-image: linear-gradient(to left, #dd2534, #225380 66%, #00b0ea);
}
.card-header-product-gradient {
  background-image: linear-gradient(to left, #57dfb7, #00c2ce);
}
.card-header-televisión,
.card-header-television {
  background-color: #dd2534;
}
.card-header-internet {
  background-color: #225380;
}
.card-header-planMovil {
  background-color: #00b0ea;
}
.card-header-title {
  background: #f5f5f5;
  color: #333;
  padding: 13px 20px;
  height: 67px;
}
.card-header-title h2 {
  box-sizing: border-box;
  color: #333;
  display: inline;
  font-family: Lato-Bold;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}
.card-body-row {
  padding: 0 28px;
}
.card-body-row-icon-div {
  text-align: center;
  margin-top: 15px;
}
.card-body-row-icon {
  display: inline-block;
}
.whatsapp-icon {
  font-size: 16px;
  color: #169f4e;
}
.cloud-icon {
  font-size: 16px;
  color: #00b0ea;
}
.card-body-row-text {
  padding: 15px 0;
  margin-left: 18px;
  color: #555;
  min-height: 50px;
}
.card-text-border {
  border-bottom: 0.5px solid #ccc;
}
.card-body-additional-row {
  margin-top: 10px;
  text-align: center;
  padding: 0 40px 0 40px;
}
.card-body-additional-row-icon {
  display: inline-block;
}
.card-body-additional-row-text {
  color: #225380;
  padding-left: 5px;
}
.card-body-additional-row-subtext {
  color: #555;
}
.card-body-details-row {
  background: #f5f5f5;
  padding: 27px;
  margin-bottom: 25px;
}
.card-details-products {
  color: #333;
  margin-bottom: 6px;
}
.card-details-prod-lbl {
  color: #333;
}
.card-details-prod-attr {
  color: #555;
  margin-top: 8px;
}
.card-details-prod-attr p {
  margin: 0;
}
.card-body-price-tag {
  border-radius: 13px 0 0 13px;
  padding: 6px 15px 6px 10px;
  float: right;
}
.card-body-price {
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 0 3px;
}
.card-body-price-number {
  color: #333;
  text-align: center;
  line-height: 21px;
}
.card-body-price-text {
  color: #333;
  line-height: 15px;
  padding: 0 0 0 10px;
}
.card-body-price-before {
  color: #888;
  text-align: center;
  margin-top: 5px;
}
.card-body-price-before-without-color {
  text-align: center;
  margin-top: 5px;
}
.card-body-price-before-number {
  text-decoration: line-through;
}
.card-body-price .card-body-price-before .card-body-price-before-number {
  font-size: 13px;
}
.card-buttons-row {
  text-align: center;
}
.card-buttons-more {
  color: #0081b7;
  margin: 20px 0;
}
.offer-cards-arrow {
  position: absolute;
  top: 35%;
  outline: none;
  background: #fff;
  color: #225380;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  border: 1px solid #eee;
  box-shadow: 1px 2px #eee;
}
.offer-cards-arrow.prevArrow {
  left: -1%;
  z-index: 1;
}
@media (max-width: 768px) {
  .offer-cards-arrow.prevArrow {
    display: none !important;
  }
}
.offer-cards-arrow.nextArrow {
  right: -1%;
}
@media (max-width: 768px) {
  .offer-cards-arrow.nextArrow {
    display: none !important;
  }
}
.offer-cards-arrow.prevArrow.slick-disabled,
.offer-cards-arrow.nextArrow.slick-disabled {
  display: none !important;
}
.offer-cards .slick-track {
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
}
.offer-cards .slick-dots {
  display: none !important;
}
@media (max-width: 768px) {
  .offer-cards .slick-dots {
    display: block !important;
    visibility: hidden;
  }
}
.offer-cards .slick-dots li {
  width: 7%;
  height: 5px;
  background: #ccc;
  margin: 0;
  visibility: visible;
}
.offer-cards .slick-dots li:first-child {
  border-radius: 10px 0 0 10px;
}
.offer-cards .slick-dots li:last-child {
  border-radius: 0 10px 10px 0;
}
.offer-cards .slick-dots li button {
  height: 5px;
  opacity: 0;
  color: transparent;
}
.offer-cards .slick-dots li.slick-active {
  background: inherit;
}
.offer-cards .slick-dots li button,
.offer-cards .slick-dots li button:before {
  color: transparent;
  opacity: 0;
}
.offer-filters {
  background: #eee;
}
.offer-filters .slick-track {
  height: 56px;
}
.offer-filters h4 {
  color: inherit;
  margin: 0;
}
.offer-details {
  background: #f5f5f5;
  padding: 25px 0 35px 0;
}
.offer-product-details {
  padding: 16px 0 35px 0;
}
.offer-details-container {
  padding-right: 0;
}
.draggable-menu {
  background: #eee;
  white-space: nowrap;
  text-align: center;
}
.draggable-menu-item {
  color: #888;
  padding: 21px 0 19px 0;
  width: 200px;
  outline: none;
}
.draggable-menu-item-active {
  color: #225380;
  border-bottom: 2px solid #225380;
}
.draggable-menu-arrow {
  height: 100%;
  width: 150px;
  position: absolute;
  top: 0;
  border: none;
  outline: none;
  color: transparent;
}
@media (max-width: 768px) {
  .draggable-menu-arrow {
    width: 75px;
  }
}
.draggable-menu-arrow:hover:not(.slick-disabled) {
  color: #000;
}
.draggable-menu-arrow:active:not(.slick-disabled) {
  color: #00f;
}
.draggable-menu-arrow-prev {
  display: flex;
}
@media (max-width: 768px) {
  .draggable-menu-arrow-prev {
    display: none;
  }
}
.draggable-menu-arrow-next {
  display: flex;
  flex-direction: row-reverse;
}
@media (max-width: 768px) {
  .draggable-menu-arrow-next {
    display: none;
  }
}
.draggable-menu-arrow.prevArrow {
  left: 0;
  z-index: 1;
  background: linear-gradient(to left, transparent, #eee 50%);
}
.draggable-menu-arrow.nextArrow {
  right: 0;
  background: linear-gradient(to right, transparent, #eee 50%);
}
.draggable-menu-arrow.prevArrow.slick-disabled,
.draggable-menu-arrow.nextArrow.slick-disabled {
  display: none !important;
}
@keyframes strokeanim {
  0% {
    stroke-dasharray: 1, 300;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -58.54832458;
  }
  100% {
    stroke-dasharray: 120, 300;
    stroke-dashoffset: -175.64497375;
  }
}
@keyframes contanim {
  100% {
    transform: rotate(360deg);
  }
}
.cc-spinner {
  position: fixed !important;
  z-index: 99;
}
.custom-cc-spinner-css {
  position: relative;
  height: 66px;
  width: 66px;
  margin-left: -33px;
  margin-top: -33px;
  animation: contanim 2s linear infinite;
}
.custom-cc-spinner-css svg {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  transform: rotate(-90deg);
}
.custom-cc-spinner-css svg:nth-child(4) circle {
  stroke: #225380;
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 3s calc(0.8s) ease infinite;
  transform-origin: center center;
}
.custom-cc-spinner-css svg:nth-child(3) circle {
  stroke: #dd2534;
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 3s calc(0.6s) ease infinite;
  transform-origin: center center;
}
.custom-cc-spinner-css svg:nth-child(2) circle {
  stroke: #00b0ea;
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 3s calc(0.4s) ease infinite;
  transform-origin: center center;
}
.custom-cc-spinner-css svg:nth-child(1) circle {
  stroke: #57dfb7;
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: strokeanim 3s calc(0.2s) ease infinite;
  transform-origin: center center;
}
.style-guide-html-content {
  margin-top: 50px;
}
.card-cucarda-center {
  font-family: Roboto-Bold;
  position: inherit;
  top: -12px;
  height: 0;
}
.card-cucarda-center .badge {
  width: 140px;
  height: 29px;
  background-color: #ff7a00;
  border-radius: 2px;
}
.card-cucarda-left {
  position: inherit;
  top: -12px;
  height: 0;
  float: left;
}
.card-cucarda-left .badge {
  width: 140px;
  height: 29px;
  background-color: #ff7a00;
  border-radius: 2px;
}
.card-cucarda-right {
  position: inherit;
  top: -12px;
  height: 0;
  float: right;
}
.card-cucarda-right .badge {
  width: 140px;
  height: 29px;
  background-color: #ff7a00;
  border-radius: 2px;
}
.feature-offer-container {
  background-color: #f5f5f5;
  padding: 0;
}
@media (max-width: 768px) {
  .feature-offer-container {
    height: auto;
  }
}
.feature-offer-container .feature-offer-title-container {
  padding-top: 46px;
  margin-bottom: 42px;
}
.feature-offer-container .feature-offer-boxes {
  display: flex;
  justify-content: center;
}
@media (min-width: 769px) {
  .feature-offer-container .feature-offer-boxes {
    padding: 0;
  }
}
.feature-offer-container .feature-offer-boxes .feature-offer-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (max-width: 768px) {
  .feature-offer-container .feature-offer-boxes .feature-offer-row {
    display: block;
    margin-bottom: 16px;
  }
}
.feature-offer-container .feature-offer-boxes .feature-offer-row a {
  text-decoration: none;
  color: unset;
}
.feature-offer-container .feature-offer-boxes .feature-offer-row a:active,
.feature-offer-container .feature-offer-boxes .feature-offer-row a:focus,
.feature-offer-container .feature-offer-boxes .feature-offer-row a:hover {
  color: unset;
}
.feature-offer-container
  .feature-offer-boxes
  .feature-offer-row
  a
  .feature-offer-box {
  height: 449px;
  width: 595px;
  margin-bottom: 30px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  border-top: none;
  margin-left: 15px;
  margin-right: 15px;
}
@media (max-width: 768px) {
  .feature-offer-container
    .feature-offer-boxes
    .feature-offer-row
    a
    .feature-offer-box {
    height: auto;
    width: 328px;
    margin-bottom: 16px;
  }
}
@media (max-width: 768px) {
  .feature-offer-container
    .feature-offer-boxes
    .feature-offer-row
    a
    .feature-offer-box:nth-of-type(1) {
    margin: 0 auto 16px auto;
  }
}
@media (max-width: 768px) {
  .feature-offer-container
    .feature-offer-boxes
    .feature-offer-row
    a
    .feature-offer-box:nth-of-type(3) {
    margin: 0 auto 16px auto;
  }
}
.feature-offer-container
  .feature-offer-boxes
  .feature-offer-row
  a
  .feature-offer-box
  .feature-offer-label-container {
  position: absolute;
  width: 128px;
  height: 26px;
  background-color: #291e3e;
  text-align: center;
  border-top-left-radius: 10px;
  color: #fff;
}
.feature-offer-container
  .feature-offer-boxes
  .feature-offer-row
  a
  .feature-offer-box
  .feature-offer-label-container
  span {
  line-height: 1.57;
  line-height: 2;
}
.feature-offer-container
  .feature-offer-boxes
  .feature-offer-row
  a
  .feature-offer-box
  .feature-offer-box-img-container {
  object-fit: contain;
}
.feature-offer-container
  .feature-offer-boxes
  .feature-offer-row
  a
  .feature-offer-box
  .feature-offer-box-img-container
  img {
  height: 294px;
}
@media (max-width: 768px) {
  .feature-offer-container
    .feature-offer-boxes
    .feature-offer-row
    a
    .feature-offer-box
    .feature-offer-box-img-container
    img {
    height: 162px;
  }
}
.feature-offer-container
  .feature-offer-boxes
  .feature-offer-row
  a
  .feature-offer-box
  .feature-offer-box-description {
  padding: 21px 16px 10px 23.8px;
}
@media (max-width: 768px) {
  .feature-offer-container
    .feature-offer-boxes
    .feature-offer-row
    a
    .feature-offer-box
    .feature-offer-box-description {
    padding: 13px 16px 10px 16px;
  }
}
.feature-offer-container
  .feature-offer-boxes
  .feature-offer-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-left-content {
  width: 367px;
  display: inline-block;
}
@media (max-width: 768px) {
  .feature-offer-container
    .feature-offer-boxes
    .feature-offer-row
    a
    .feature-offer-box
    .feature-offer-box-description
    .feature-offer-box-description-left-content {
    width: 296px;
  }
}
.feature-offer-container
  .feature-offer-boxes
  .feature-offer-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content {
  width: 165px;
  float: right;
}
@media (max-width: 768px) {
  .feature-offer-container
    .feature-offer-boxes
    .feature-offer-row
    a
    .feature-offer-box
    .feature-offer-box-description
    .feature-offer-box-description-right-content {
    width: 100%;
    float: unset;
  }
}
.feature-offer-container
  .feature-offer-boxes
  .feature-offer-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content
  .discount-label {
  display: block;
  height: 25px;
}
@media (max-width: 768px) {
  .feature-offer-container
    .feature-offer-boxes
    .feature-offer-row
    a
    .feature-offer-box
    .feature-offer-box-description
    .feature-offer-box-description-right-content
    .discount-label {
    height: unset;
    position: relative;
    top: 30px;
  }
}
.feature-offer-container
  .feature-offer-boxes
  .feature-offer-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content
  .discount-label
  .card-body-price-tag {
  display: block;
  padding: 6px 15px 6px 10px;
  margin-right: -1px;
}
.feature-offer-container
  .feature-offer-boxes
  .feature-offer-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content
  span {
  display: flex;
  padding-left: 0;
}
.feature-offer-container
  .feature-offer-boxes
  .feature-offer-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content
  .card-body-price-number {
  font-size: 36px;
  font-weight: 900;
  line-height: 1.33;
}
.feature-offer-container
  .feature-offer-boxes
  .feature-offer-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content-percentage {
  width: 165px;
  float: right;
  width: 186px;
}
@media (max-width: 768px) {
  .feature-offer-container
    .feature-offer-boxes
    .feature-offer-row
    a
    .feature-offer-box
    .feature-offer-box-description
    .feature-offer-box-description-right-content-percentage {
    width: 100%;
    float: unset;
  }
}
.feature-offer-container
  .feature-offer-boxes
  .feature-offer-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content-percentage
  .discount-label {
  display: block;
  height: 25px;
}
@media (max-width: 768px) {
  .feature-offer-container
    .feature-offer-boxes
    .feature-offer-row
    a
    .feature-offer-box
    .feature-offer-box-description
    .feature-offer-box-description-right-content-percentage
    .discount-label {
    height: unset;
    position: relative;
    top: 30px;
  }
}
.feature-offer-container
  .feature-offer-boxes
  .feature-offer-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content-percentage
  .discount-label
  .card-body-price-tag {
  display: block;
  padding: 6px 15px 6px 10px;
  margin-right: -1px;
}
.feature-offer-container
  .feature-offer-boxes
  .feature-offer-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content-percentage
  span {
  display: flex;
  padding-left: 0;
}
.feature-offer-container
  .feature-offer-boxes
  .feature-offer-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content-percentage
  .card-body-price-number {
  font-size: 36px;
  font-weight: 900;
  line-height: 1.33;
}
@media (max-width: 768px) {
  .feature-offer-container
    .feature-offer-boxes
    .feature-offer-row
    a
    .feature-offer-box
    .feature-offer-box-description
    .feature-offer-box-description-right-content-percentage {
    float: unset;
  }
}
@media (max-width: 768px) {
  .feature-offer-container
    .feature-offer-boxes
    .feature-offer-row
    a
    .feature-offer-box
    .feature-offer-box-description
    .feature-offer-box-description-right-content-percentage
    .discount-label {
    height: unset;
  }
}
.feature-offer-container
  .feature-offer-boxes
  .feature-offer-row
  a
  .feature-offer-box
  .feature-offer-box-description
  p {
  margin: 5px 0 5px 0;
  line-height: 1.11;
}
.special-benefits-container {
  height: auto;
  background-color: #fff;
  padding: 0;
}
.special-benefits-container .special-benefits-title-container {
  padding-top: 46px;
  margin-bottom: 42px;
}
.special-benefits-container .special-benefits-title-container h2 {
  max-width: 437px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .special-benefits-container .special-benefits-title-container h2 {
    padding: 0 31px;
    font-size: 24px;
    line-height: 28px;
  }
}
@media (max-width: 768px) {
  .special-benefits-container .special-benefits-title-container h4 {
    padding: 0 31px;
    font-size: 24px;
  }
}
@media (min-width: 769px) {
  .special-benefits-container .special-benefit-boxes {
    padding: 0;
  }
}
.special-benefits-container .special-benefit-boxes .special-benefits-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (max-width: 768px) {
  .special-benefits-container .special-benefit-boxes .special-benefits-row {
    display: block;
    margin-bottom: 16px;
  }
}
.special-benefits-container .special-benefit-boxes .special-benefits-row a {
  text-decoration: none;
  color: unset;
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a:active,
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a:focus,
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a:hover {
  color: unset;
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a
  .feature-offer-box {
  height: 449px;
  width: 595px;
  margin-bottom: 30px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  border-top: none;
  margin-left: 15px;
  margin-right: 15px;
}
@media (max-width: 768px) {
  .special-benefits-container
    .special-benefit-boxes
    .special-benefits-row
    a
    .feature-offer-box {
    height: auto;
    width: 328px;
    margin-bottom: 16px;
  }
}
@media (max-width: 768px) {
  .special-benefits-container
    .special-benefit-boxes
    .special-benefits-row
    a
    .feature-offer-box:nth-of-type(1) {
    margin: 0 auto 16px auto;
  }
}
@media (max-width: 768px) {
  .special-benefits-container
    .special-benefit-boxes
    .special-benefits-row
    a
    .feature-offer-box:nth-of-type(3) {
    margin: 0 auto 16px auto;
  }
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a
  .feature-offer-box
  .feature-offer-label-container {
  position: absolute;
  width: 128px;
  height: 26px;
  background-color: #291e3e;
  text-align: center;
  border-top-left-radius: 10px;
  color: #fff;
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a
  .feature-offer-box
  .feature-offer-label-container
  span {
  line-height: 1.57;
  line-height: 2;
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a
  .feature-offer-box
  .feature-offer-box-img-container {
  object-fit: contain;
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a
  .feature-offer-box
  .feature-offer-box-img-container
  img {
  height: 294px;
}
@media (max-width: 768px) {
  .special-benefits-container
    .special-benefit-boxes
    .special-benefits-row
    a
    .feature-offer-box
    .feature-offer-box-img-container
    img {
    height: 162px;
  }
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a
  .feature-offer-box
  .feature-offer-box-description {
  padding: 21px 16px 10px 23.8px;
}
@media (max-width: 768px) {
  .special-benefits-container
    .special-benefit-boxes
    .special-benefits-row
    a
    .feature-offer-box
    .feature-offer-box-description {
    padding: 13px 16px 10px 16px;
  }
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-left-content {
  width: 367px;
  display: inline-block;
}
@media (max-width: 768px) {
  .special-benefits-container
    .special-benefit-boxes
    .special-benefits-row
    a
    .feature-offer-box
    .feature-offer-box-description
    .feature-offer-box-description-left-content {
    width: 296px;
  }
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content {
  width: 165px;
  float: right;
}
@media (max-width: 768px) {
  .special-benefits-container
    .special-benefit-boxes
    .special-benefits-row
    a
    .feature-offer-box
    .feature-offer-box-description
    .feature-offer-box-description-right-content {
    width: 100%;
    float: unset;
  }
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content
  .discount-label {
  display: block;
  height: 25px;
}
@media (max-width: 768px) {
  .special-benefits-container
    .special-benefit-boxes
    .special-benefits-row
    a
    .feature-offer-box
    .feature-offer-box-description
    .feature-offer-box-description-right-content
    .discount-label {
    height: unset;
    position: relative;
    top: 30px;
  }
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content
  .discount-label
  .card-body-price-tag {
  display: block;
  padding: 6px 15px 6px 10px;
  margin-right: -1px;
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content
  span {
  display: flex;
  padding-left: 0;
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content
  .card-body-price-number {
  font-size: 36px;
  font-weight: 900;
  line-height: 1.33;
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content-percentage {
  width: 165px;
  float: right;
  width: 186px;
}
@media (max-width: 768px) {
  .special-benefits-container
    .special-benefit-boxes
    .special-benefits-row
    a
    .feature-offer-box
    .feature-offer-box-description
    .feature-offer-box-description-right-content-percentage {
    width: 100%;
    float: unset;
  }
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content-percentage
  .discount-label {
  display: block;
  height: 25px;
}
@media (max-width: 768px) {
  .special-benefits-container
    .special-benefit-boxes
    .special-benefits-row
    a
    .feature-offer-box
    .feature-offer-box-description
    .feature-offer-box-description-right-content-percentage
    .discount-label {
    height: unset;
    position: relative;
    top: 30px;
  }
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content-percentage
  .discount-label
  .card-body-price-tag {
  display: block;
  padding: 6px 15px 6px 10px;
  margin-right: -1px;
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content-percentage
  span {
  display: flex;
  padding-left: 0;
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a
  .feature-offer-box
  .feature-offer-box-description
  .feature-offer-box-description-right-content-percentage
  .card-body-price-number {
  font-size: 36px;
  font-weight: 900;
  line-height: 1.33;
}
@media (max-width: 768px) {
  .special-benefits-container
    .special-benefit-boxes
    .special-benefits-row
    a
    .feature-offer-box
    .feature-offer-box-description
    .feature-offer-box-description-right-content-percentage {
    float: unset;
  }
}
@media (max-width: 768px) {
  .special-benefits-container
    .special-benefit-boxes
    .special-benefits-row
    a
    .feature-offer-box
    .feature-offer-box-description
    .feature-offer-box-description-right-content-percentage
    .discount-label {
    height: unset;
  }
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  a
  .feature-offer-box
  .feature-offer-box-description
  p {
  margin: 5px 0 5px 0;
  line-height: 1.11;
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  .special-benefits-box {
  height: auto;
  object-fit: contain;
  margin-bottom: 30px;
  padding: 0;
}
@media (min-width: 769px) {
  .special-benefits-container
    .special-benefit-boxes
    .special-benefits-row
    .special-benefits-box {
    width: 600px;
  }
  .special-benefits-container
    .special-benefit-boxes
    .special-benefits-row
    .special-benefits-box:nth-of-type(1) {
    margin-right: 30px;
  }
  .special-benefits-container
    .special-benefit-boxes
    .special-benefits-row
    .special-benefits-box:nth-of-type(3) {
    margin-right: 30px;
  }
}
@media (max-width: 768px) {
  .special-benefits-container
    .special-benefit-boxes
    .special-benefits-row
    .special-benefits-box {
    height: auto;
    margin-bottom: 16px;
    margin-right: 0;
  }
}
@media (max-width: 768px) {
  .special-benefits-container
    .special-benefit-boxes
    .special-benefits-row
    .special-benefits-box
    img {
    width: 100%;
  }
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  .special-benefits-box
  .special-benefits-box-img-container {
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 0;
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  .special-benefits-box
  .special-benefits-box-img-container
  img {
  mix-blend-mode: multiply;
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  .special-benefits-box
  .special-benefits-box-img-container
  .shadow-box-content-description {
  position: absolute;
  color: #fff !important;
  text-align: center;
  margin-top: 30px;
  z-index: 1;
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  .special-benefits-box
  .special-benefits-box-img-container
  .shadow-box-content-description
  h1,
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  .special-benefits-box
  .special-benefits-box-img-container
  .shadow-box-content-description
  h2,
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  .special-benefits-box
  .special-benefits-box-img-container
  .shadow-box-content-description
  h3,
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  .special-benefits-box
  .special-benefits-box-img-container
  .shadow-box-content-description
  h4,
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  .special-benefits-box
  .special-benefits-box-img-container
  .shadow-box-content-description
  h5,
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  .special-benefits-box
  .special-benefits-box-img-container
  .shadow-box-content-description
  h6 {
  color: #fff;
}
.special-benefits-container
  .special-benefit-boxes
  .special-benefits-row
  .special-benefits-box
  .special-benefits-box-img-container
  .shadow-box-content-description
  h3 {
  font-size: 17.5px;
  margin-bottom: 10px;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .special-benefits-container
    .special-benefit-boxes
    .special-benefits-row
    .special-benefits-box
    .special-benefits-box-img-container
    .shadow-box-content-description {
    width: 65%;
  }
}
.special-benefits-container .special-benefits-button-container {
  margin-top: 40px;
  margin-bottom: 56px;
  display: flex;
  justify-content: center;
}
.special-benefits-container .special-benefits-button-container a {
  width: 386px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.special-benefits-container .special-benefits-button-container a span {
  line-height: 1.43;
}
@media (max-width: 768px) {
  .special-benefits-container .special-benefits-button-container a {
    width: 328px;
  }
}
.scroll {
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.scroll::-webkit-scrollbar {
  display: none;
}
.direct-access {
  box-sizing: border-box;
  background: #1b1b1d;
  display: flex;
}
@media (min-width: 769px) {
  .direct-access {
    justify-content: center;
  }
}
.direct-access .shortCutList {
  font-size: 0;
  background: #1b1b1d;
}
.direct-access .shortCutList .vertical-align-middle {
  padding-left: 0;
  margin: 0 auto;
  display: flex;
}
.direct-access .shortCutList .vertical-align-middle .nav-item {
  width: 208px;
  height: 120px;
  display: flex;
}
@media (min-width: 769px) {
  .direct-access .shortCutList .vertical-align-middle .nav-item {
    justify-content: center;
  }
}
.direct-access .shortCutList .vertical-align-middle .nav-item a {
  color: #fff;
  text-align: center;
}
.direct-access .shortCutList .vertical-align-middle .nav-item .icon {
  font-size: 32px;
  margin-top: 33px;
}
.direct-access .shortCutList .vertical-align-middle .nav-item .text {
  margin-top: 16px;
}
@media (max-width: 768px) {
  .direct-access .shortCutList .vertical-align-middle {
    margin-left: 0;
  }
  .direct-access .shortCutList .vertical-align-middle .nav-item {
    width: 110px;
    height: 116px;
    margin: 0;
    padding: 0;
    justify-content: center;
  }
  .direct-access .shortCutList .vertical-align-middle .nav-item .icon {
    font-size: 32px;
    margin-top: 18px;
  }
  .direct-access .shortCutList .vertical-align-middle .nav-item .text {
    font-size: 14px;
    margin-top: 16px;
    width: 80px;
    white-space: pre-line;
  }
}
.functionality-flow-row {
  padding: 40px 10px 0 10px;
  background-color: #282629;
}
.functionality-flow-title h2 {
  color: #57dfb7;
  margin-bottom: 20px;
  font-family: Lato-Bold;
  font-size: 32px;
  line-height: 40px;
}
.functionality-container {
  box-sizing: border-box;
  background-color: #282629;
  display: flex;
  justify-content: center;
  height: 175px;
}
.functionality-container .functionality-div {
  font-size: 0;
  white-space: normal;
}
.functionality-container .functionality-div .vertical-align-middle {
  padding-left: 0;
  margin: 0 auto;
  display: flex;
}
.functionality-container
  .functionality-div
  .vertical-align-middle
  .functionality-item {
  text-align: center;
}
.functionality-container
  .functionality-div
  .vertical-align-middle
  .functionality-item
  button {
  color: #767676;
  background-color: #282629;
  margin: 0;
  border: none;
  outline: none;
  width: 208px;
}
.functionality-container
  .functionality-div
  .vertical-align-middle
  .functionality-item
  button
  .icon {
  font-size: 32px;
  margin-top: 33px;
}
.functionality-container
  .functionality-div
  .vertical-align-middle
  .functionality-item
  button
  .text {
  margin: 16px auto 0 auto;
  width: 80%;
}
.functionality-container
  .functionality-div
  .vertical-align-middle
  .functionality-item-active
  .icon {
  color: #57dfb7;
}
.functionality-container
  .functionality-div
  .vertical-align-middle
  .functionality-item-active
  .text {
  color: #fff;
}
.functionality-container
  .functionality-div
  .vertical-align-middle
  .functionality-item-active
  .functionality-dot {
  height: 10px;
  width: 10px;
  background-color: #57dfb7;
  border-radius: 50%;
  display: inline-block;
}
.functionality-container
  .functionality-div
  .vertical-align-middle
  .functionality-item-plus {
  width: 208px;
  text-align: center;
}
.functionality-container
  .functionality-div
  .vertical-align-middle
  .functionality-item-plus
  a:hover,
.functionality-container
  .functionality-div
  .vertical-align-middle
  .functionality-item-plus
  a:active,
.functionality-container
  .functionality-div
  .vertical-align-middle
  .functionality-item-plus
  a:visited {
  text-decoration: none;
}
.functionality-container
  .functionality-div
  .vertical-align-middle
  .functionality-item-plus
  .icon {
  font-size: 32px;
  margin-top: 33px;
  color: #57dfb7;
  border-left: 1px solid #fff;
}
.functionality-container
  .functionality-div
  .vertical-align-middle
  .functionality-item-plus
  .text {
  padding: 16px 0;
  color: #fff;
  border-left: 1px solid #fff;
}
.functionality-detail-container .functionality-detail {
  display: flex;
  align-items: center;
  left: 100%;
  margin-bottom: 25px;
  position: absolute;
  opacity: 0.1;
}
.functionality-detail-container .functionality-detail .functionality-title {
  color: #fff;
  line-height: 28px;
}
.functionality-detail-container .functionality-detail .functionality-subtitle {
  color: #ccc;
  margin-top: 8px;
  line-height: 20px;
}
.functionality-detail-container .functionality-detail-active {
  transition: 1s;
  left: 0;
  position: relative;
  opacity: 1;
}
.functionality-detail-container .functionality-detail-active img {
  width: 386px;
}
@media (max-width: 768px) {
  .functionality-flow-title {
    font-size: 24px;
    line-height: 28px;
  }
  .functionality-container
    .functionality-div
    .vertical-align-middle
    .functionality-item
    button {
    color: #767676;
    background: #282629;
    margin: 0;
    border: none;
    outline: none;
    width: 115px;
  }
  .functionality-container
    .functionality-div
    .vertical-align-middle
    .functionality-item
    button
    .icon {
    font-size: 28px;
    margin-top: 29px;
  }
  .functionality-container
    .functionality-div
    .vertical-align-middle
    .functionality-item
    button
    .text {
    font-size: 12px;
    padding-top: 12px;
  }
  .functionality-container
    .functionality-div
    .vertical-align-middle
    .functionality-item-active
    .functionality-dot {
    height: 8px;
    width: 8px;
  }
  .functionality-container
    .functionality-div
    .vertical-align-middle
    .functionality-item-plus {
    width: 115px;
  }
  .functionality-container
    .functionality-div
    .vertical-align-middle
    .functionality-item-plus
    .icon {
    font-size: 28px;
    margin-top: 29px;
  }
  .functionality-container
    .functionality-div
    .vertical-align-middle
    .functionality-item-plus
    .text {
    font-size: 12px;
    padding-top: 12px;
  }
  .functionality-detail-container .functionality-detail {
    margin-bottom: 56px;
  }
  .functionality-detail-container .functionality-detail-active {
    display: block;
    text-align: center;
  }
  .functionality-detail-container .functionality-detail-active img {
    width: 100%;
  }
  .functionality-detail-container
    .functionality-detail-active
    .functionality-title {
    font-size: 18px;
    line-height: 24px;
  }
  .functionality-detail-container
    .functionality-detail-active
    .functionality-subtitle {
    margin-top: 6px;
  }
}
.my-account-app-container {
  height: 569px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  background-color: #f5f5f5;
}
@media (max-width: 768px) {
  .my-account-app-container {
    height: 430px;
    padding-top: 30px;
  }
}
@media (min-width: 769px) {
  .my-account-app-container {
    display: flex;
    justify-content: center;
  }
}
.my-account-app-container .featured-title-container .featured-title {
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}
@media (max-width: 768px) {
  .my-account-app-container .featured-title-container .featured-title {
    font-size: 18px;
  }
}
.my-account-app-container
  .featured-title-container
  .possibilities-list-container {
  margin-top: 41px;
}
.my-account-app-container
  .featured-title-container
  .possibilities-list-container
  .possibilities-list {
  list-style: none;
  margin-bottom: 0;
}
.my-account-app-container
  .featured-title-container
  .possibilities-list-container
  .possibilities-list
  .list-item {
  margin-bottom: 24px;
}
.my-account-app-container
  .featured-title-container
  .possibilities-list-container
  .possibilities-list
  .list-item:before {
  content: "\f00c";
  font-family: FontAwesomePro;
  display: inline-block;
  margin-left: -2.3em;
  padding-right: 16px;
}
.my-account-app-container
  .featured-title-container
  .possibilities-list-container
  .possibilities-list
  .list-item:nth-of-type(4) {
  margin-bottom: 0;
}
.my-account-app-container
  .featured-title-container
  .possibilities-list-container
  .logos-container {
  margin-top: 34px;
  display: block;
}
.my-account-app-container .middle-content {
  margin-bottom: 30px;
}
.my-account-app-container .middle-content .possibilities-list-container {
  padding-right: 0;
  margin-top: 41px;
}
.my-account-app-container
  .middle-content
  .possibilities-list-container
  .possibilities-list {
  list-style: none;
  padding-left: 29px;
  margin-bottom: 0;
  padding-right: 0;
}
.my-account-app-container
  .middle-content
  .possibilities-list-container
  .possibilities-list
  .list-item {
  margin-bottom: 24px;
}
.my-account-app-container
  .middle-content
  .possibilities-list-container
  .possibilities-list
  .list-item:before {
  content: "\f00c";
  font-family: FontAwesomePro;
  display: inline-block;
  margin-left: -2.3em;
  padding-right: 16px;
}
.my-account-app-container
  .middle-content
  .possibilities-list-container
  .possibilities-list
  .list-item:nth-of-type(4) {
  margin-bottom: 0;
}
.my-account-app-container .middle-content .img-container {
  padding: 0;
}
@media (max-width: 768px) {
  .my-account-app-container .middle-content .img-container img {
    padding-left: 10px;
    margin-top: 19px;
    height: 229px;
  }
}
.my-account-app-container .logos-container {
  margin-top: 34px;
}
@media (max-width: 768px) {
  .my-account-app-container .logos-container {
    display: inline;
    text-align: center;
  }
}
.my-account-section,
.benefits-included-section,
.address-section {
  background-color: #f5f5f5;
  height: 500px;
  padding-top: 46px;
}
.my-account-section h1,
.my-account-section h2,
.my-account-section h3,
.my-account-section h4,
.my-account-section h5,
.my-account-section h6 {
  margin: 0;
}
.my-account-section .my-account-section-title,
.benefits-included-section .benefits-included-section-title,
.address-section .address-section-title {
  margin-bottom: 42px;
}
@media (max-width: 768px) {
  .my-account-section .my-account-section-title,
  .benefits-included-section .benefits-included-section-title,
  .address-section .address-section-title {
    margin-bottom: 36px;
  }
}
@media (min-width: 769px) {
  .my-account-section .container {
    padding: 0;
  }
}
.my-account-section .container .tiles-container,
.my-account-section .container .benefits-included-tiles-container,
.benefits-included-section .container .benefits-included-tiles-container,
.address-section .container .address-section-tiles-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
@media (max-width: 768px) {
  .my-account-section .container .tiles-container,
  .my-account-section .container .benefits-included-tiles-container,
  .benefits-included-section .container .benefits-included-tiles-container,
  .address-section .container .address-section-tiles-container {
    justify-content: center;
  }
}
.my-account-section .container .tiles-container .tile,
.my-account-section .container .benefits-included-tiles-container .tile,
.my-account-section .container .tiles-container .benefits-tile,
.my-account-section
  .container
  .benefits-included-tiles-container
  .benefits-tile,
.benefits-included-section
  .container
  .benefits-included-tiles-container
  .benefits-tile,
.address-section
  .container
  .address-section-tiles-container
  .address-section-tile {
  height: 128px;
  border-radius: 1.3px;
  border: solid 0.7px #ccc;
  background-color: #fff;
  position: relative;
  display: grid;
  grid-template-rows: max-content;
  align-content: center;
  margin-bottom: 30px;
}
@media (min-width: 769px) {
  .my-account-section .container .tiles-container .tile,
  .my-account-section .container .benefits-included-tiles-container .tile,
  .my-account-section .container .tiles-container .benefits-tile,
  .my-account-section
    .container
    .benefits-included-tiles-container
    .benefits-tile,
  .benefits-included-section
    .container
    .benefits-included-tiles-container
    .benefits-tile,
  .address-section
    .container
    .address-section-tiles-container
    .address-section-tile {
    width: 594px;
  }
}
@media (max-width: 768px) {
  .my-account-section .container .tiles-container .tile,
  .my-account-section .container .benefits-included-tiles-container .tile,
  .my-account-section .container .tiles-container .benefits-tile,
  .my-account-section
    .container
    .benefits-included-tiles-container
    .benefits-tile,
  .benefits-included-section
    .container
    .benefits-included-tiles-container
    .benefits-tile,
  .address-section
    .container
    .address-section-tiles-container
    .address-section-tile {
    height: 110px;
    border-radius: 4px;
    margin: 8px;
    color: #000;
  }
}
.my-account-section .container .tiles-container .tile .tile-content,
.my-account-section
  .container
  .benefits-included-tiles-container
  .tile
  .tile-content,
.my-account-section .container .tiles-container .benefits-tile .tile-content,
.my-account-section
  .container
  .benefits-included-tiles-container
  .benefits-tile
  .tile-content,
.benefits-included-section
  .container
  .benefits-included-tiles-container
  .benefits-tile
  .tile-content,
.address-section
  .container
  .address-section-tiles-container
  .address-section-tile
  .tile-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 769px) {
  .my-account-section .container .tiles-container .tile .tile-content,
  .my-account-section
    .container
    .benefits-included-tiles-container
    .tile
    .tile-content,
  .my-account-section .container .tiles-container .benefits-tile .tile-content,
  .my-account-section
    .container
    .benefits-included-tiles-container
    .benefits-tile
    .tile-content,
  .benefits-included-section
    .container
    .benefits-included-tiles-container
    .benefits-tile
    .tile-content,
  .address-section
    .container
    .address-section-tiles-container
    .address-section-tile
    .tile-content {
    justify-content: unset;
    margin-left: 57.3px;
  }
}
.my-account-section
  .container
  .tiles-container
  .tile
  .tile-content
  .icon-container,
.my-account-section
  .container
  .benefits-included-tiles-container
  .tile
  .tile-content
  .icon-container,
.my-account-section
  .container
  .tiles-container
  .benefits-tile
  .tile-content
  .icon-container,
.my-account-section
  .container
  .benefits-included-tiles-container
  .benefits-tile
  .tile-content
  .icon-container,
.benefits-included-section
  .container
  .benefits-included-tiles-container
  .benefits-tile
  .tile-content
  .icon-container {
  text-align: center;
}
.my-account-section
  .container
  .tiles-container
  .tile
  .tile-content
  .icon-container
  i,
.my-account-section
  .container
  .benefits-included-tiles-container
  .tile
  .tile-content
  .icon-container
  i,
.my-account-section
  .container
  .tiles-container
  .benefits-tile
  .tile-content
  .icon-container
  i,
.my-account-section
  .container
  .benefits-included-tiles-container
  .benefits-tile
  .tile-content
  .icon-container
  i {
  font-size: 40px;
  color: #225380;
}
@media (max-width: 768px) {
  .my-account-section
    .container
    .tiles-container
    .tile
    .tile-content
    .icon-container
    i,
  .my-account-section
    .container
    .benefits-included-tiles-container
    .tile
    .tile-content
    .icon-container
    i,
  .my-account-section
    .container
    .tiles-container
    .benefits-tile
    .tile-content
    .icon-container
    i,
  .my-account-section
    .container
    .benefits-included-tiles-container
    .benefits-tile
    .tile-content
    .icon-container
    i {
    font-size: 24px;
    margin-bottom: 8px;
  }
}
@media (min-width: 769px) {
  .my-account-section
    .container
    .tiles-container
    .tile
    .tile-content
    .icon-container
    i,
  .my-account-section
    .container
    .benefits-included-tiles-container
    .tile
    .tile-content
    .icon-container
    i,
  .my-account-section
    .container
    .tiles-container
    .benefits-tile
    .tile-content
    .icon-container
    i,
  .my-account-section
    .container
    .benefits-included-tiles-container
    .benefits-tile
    .tile-content
    .icon-container
    i {
    margin-right: 35px;
  }
}
.my-account-section
  .container
  .tiles-container
  .tile
  .tile-description:first-child,
.my-account-section
  .container
  .benefits-included-tiles-container
  .tile
  .tile-description:first-child,
.my-account-section
  .container
  .tiles-container
  .benefits-tile
  .tile-description:first-child,
.my-account-section
  .container
  .benefits-included-tiles-container
  .benefits-tile
  .tile-description:first-child {
  margin: 0;
}
@media (min-width: 769px) {
  .my-account-section .container .tiles-container .tile .tile-description,
  .my-account-section
    .container
    .benefits-included-tiles-container
    .tile
    .tile-description,
  .my-account-section
    .container
    .tiles-container
    .benefits-tile
    .tile-description,
  .my-account-section
    .container
    .benefits-included-tiles-container
    .benefits-tile
    .tile-description {
    text-align: left;
  }
}
.my-account-section .container .tiles-container .tile .tile-description span,
.my-account-section
  .container
  .benefits-included-tiles-container
  .tile
  .tile-description
  span,
.my-account-section
  .container
  .tiles-container
  .benefits-tile
  .tile-description
  span,
.my-account-section
  .container
  .benefits-included-tiles-container
  .benefits-tile
  .tile-description
  span {
  color: #555;
}
.benefits-included-section {
  min-height: 312px;
  height: auto;
  padding-top: 66px;
}
@media (min-width: 768px) {
  .benefits-included-section {
    max-height: 500px;
  }
}
.benefits-included-section .benefits-included-section-title {
  margin-bottom: 32px;
}
.benefits-included-section .benefits-included-section-title h1,
.benefits-included-section .benefits-included-section-title h2,
.benefits-included-section .benefits-included-section-title h3,
.benefits-included-section .benefits-included-section-title h4,
.benefits-included-section .benefits-included-section-title h5,
.benefits-included-section .benefits-included-section-title h6 {
  margin: 0;
}
.benefits-included-section .container {
  padding: 0;
}
.benefits-included-section .container .benefits-included-tiles-container {
  justify-content: center;
  margin-bottom: 49px;
}
.benefits-included-section
  .container
  .benefits-included-tiles-container
  .benefits-tile {
  width: 386px;
  margin: 0 15px;
  border-left: 8px solid #225380;
  border-radius: 4px;
}
@media (max-width: 768px) {
  .benefits-included-section
    .container
    .benefits-included-tiles-container
    .benefits-tile {
    height: 131px;
    border-radius: 4px;
    margin: 8px;
  }
}
@media (min-width: 769px) {
  .benefits-included-section
    .container
    .benefits-included-tiles-container
    .benefits-tile
    .tile-content {
    margin-left: 25px;
  }
}
.benefits-included-section
  .container
  .benefits-included-tiles-container
  .benefits-tile
  .tile-content
  .tile-description {
  margin-left: 35px;
}
.benefits-included-section
  .container
  .benefits-included-tiles-container
  .benefits-tile
  .tile-content
  .tile-description
  h1,
.benefits-included-section
  .container
  .benefits-included-tiles-container
  .benefits-tile
  .tile-content
  .tile-description
  h2,
.benefits-included-section
  .container
  .benefits-included-tiles-container
  .benefits-tile
  .tile-content
  .tile-description
  h3,
.benefits-included-section
  .container
  .benefits-included-tiles-container
  .benefits-tile
  .tile-content
  .tile-description
  h4,
.benefits-included-section
  .container
  .benefits-included-tiles-container
  .benefits-tile
  .tile-content
  .tile-description
  h5,
.benefits-included-section
  .container
  .benefits-included-tiles-container
  .benefits-tile
  .tile-content
  .tile-description
  h6 {
  margin: 4px 0 4px 0;
}
.benefits-included-section
  .container
  .benefits-included-tiles-container
  .benefits-tile
  .tile-content
  .tile-description
  h6 {
  margin-top: 20px;
}
@media (min-width: 769px) {
  .benefits-included-section
    .container
    .benefits-included-tiles-container
    .benefits-tile
    .tile-content
    .tile-description {
    text-align: left;
  }
}
.benefits-included-section
  .container
  .benefits-included-tiles-container
  .benefits-tile
  .tile-content
  .tile-description
  span {
  color: #555;
  margin-bottom: 17px;
}
.benefits-included-section
  .container
  .benefits-included-tiles-container
  .benefits-tile
  .tile-content
  .icon-container
  i {
  font-size: 40px;
  color: #333;
}
@media (max-width: 768px) {
  .benefits-included-section
    .container
    .benefits-included-tiles-container
    .benefits-tile
    .tile-content
    .icon-container
    i {
    font-size: 32px;
    margin-bottom: 8px;
  }
}
.benefits-included-section .firts-row .benefits-included-tiles-container {
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .benefits-included-section .firts-row .benefits-included-tiles-container {
    margin-bottom: 0;
  }
}
.benefits-included-section .second-row .benefits-included-tiles-container {
  margin-bottom: 51px;
}
.available-offers-productosyservicios-container .offer-title {
  margin: 0;
  padding-top: 42px;
  padding-bottom: 42px;
}
.available-offers-productosyservicios-container .offer-title-mbl {
  margin: 0;
  padding-top: 32px;
  padding-bottom: 16px;
  width: 75%;
  margin: auto;
  line-height: 32px;
}
.available-offers-productosyservicios-container .sin-ofertas {
  text-align: center;
  padding: 78px 0 89px 0;
}
.available-offers-productosyservicios-container .sin-ofertas p {
  margin-top: 44px;
}
.available-offers-productosyservicios-container .sin-ofertas-flow {
  text-align: center;
  padding: 78px 0 89px 0;
  background-color: #1e1e20;
}
.available-offers-productosyservicios-container .sin-ofertas-flow p {
  margin-top: 44px;
  color: #f5f5f5;
}
.header-unsubscribe {
  /* background-image: url("/file/general/hero-unsubscribe-request.png"); */
  background-repeat: no-repeat;
  background-size: 100% 405px;
  display: flex;
  height: 200px;
  align-items: center;
}
.header-unsubscribe .header-title h1 {
  color: #fff;
  font-family: Lato-Regular;
  font-size: 64px;
  line-height: 72px;
  margin: 0;
  text-align: center;
}
.body-unsubscribe {
  padding: 42px 0 80px 0;
}
.body-unsubscribe .body-title p {
  margin: 0 auto;
  text-align: center;
  font-family: Lato-Light;
  font-size: 28px;
  color: #333;
  max-width: 607px;
  line-height: 1.25;
}
.body-unsubscribe .body-elements {
  padding: 85px 0 56px 0;
}
.body-unsubscribe .body-elements .body-element i {
  font-size: 42px;
  color: #333;
}
.body-unsubscribe .body-elements .body-element h2 {
  font-family: Lato-Regular;
  font-size: 24px;
  line-height: 30px;
  color: #333;
  margin: 0;
  padding: 8px 0;
}
.body-unsubscribe .body-elements .body-element p {
  font-family: Roboto-Regular;
  font-size: 16px;
  color: #555;
  margin: 0;
}
.body-unsubscribe .body-elements .body-element a {
  text-decoration: none;
  color: #0081b7;
  font-family: Roboto-Regular;
  text-decoration: underline #0081b7;
  padding-top: 13px;
  display: block;
}
.body-unsubscribe .element-btn-1,
.body-unsubscribe .element-btn-2 {
  font-size: 18px;
}
.body-unsubscribe .element-btn-1 {
  text-align: right;
}
.body-unsubscribe .element-btn-2 {
  text-align: left;
}
.body-unsubscribe .btn-global,
.body-unsubscribe .btn {
  height: 50px !important;
  width: 205px !important;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: unset !important;
}
.body-unsubscribe button.btn-left.btn.btn-primary.dsk-body-l {
  border: 1px solid #0081b7 !important;
  background-color: #0081b7 !important;
  color: #fff;
}
.body-unsubscribe
  a.btn-right.btn.btn-primary.dsk-body.telecom__button--dodger-blue__outlined.h-40.button--width-230 {
  background: transparent;
  color: #0081b7 !important;
  border: 1px solid #0081b7 !important;
}
@media (max-width: 991px) {
  .header-unsubscribe .header-title h1 {
    font-size: 32px;
    line-height: 72px;
  }
  .body-unsubscribe {
    padding: 42px 0 88px 0;
  }
  .body-unsubscribe .body-title p {
    font-size: 24px !important;
  }
  .body-unsubscribe .body-elements {
    padding: 0 0 54px 0;
  }
  .body-unsubscribe .body-elements .body-element {
    padding-top: 42px;
  }
  .body-unsubscribe .body-elements .body-element i {
    font-size: 32px;
  }
  .body-unsubscribe .body-elements .body-element h5 {
    font-size: 20px;
  }
  .body-unsubscribe .body-elements .body-element p {
    max-width: 326px;
    margin: 0 auto;
  }
  .body-unsubscribe .body-elements .body-element a {
    padding-top: 16px;
  }
  .body-unsubscribe .element-btn-1,
  .body-unsubscribe .element-btn-2 {
    font-size: 16px;
    text-align: center !important;
  }
  .body-unsubscribe .element-btn-1 .btn-global,
  .body-unsubscribe .element-btn-2 .btn-global,
  .body-unsubscribe .element-btn-1 .btn,
  .body-unsubscribe .element-btn-2 .btn {
    width: 100% !important;
    max-width: 328px !important;
    height: 40px !important;
    margin: 0 auto !important;
  }
  .body-unsubscribe .element-btn-2 {
    padding-top: 35px;
  }
}
.header-move {
  /* background-image: url("/file/general/hero-move_Requet.png"); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 89px 0;
  height: 350px;
  align-items: center;
}
.header-move .header-title {
  text-align: center;
}
.header-move .header-title span {
  height: 20px;
  font-family: Roboto-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #fff;
  max-width: 600px;
  margin: auto;
}
.header-move .header-title h1 {
  margin: 8px 0;
  height: 144px;
  font-family: Lato-Regular;
  font-size: 64px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: #fff;
  max-width: 600px;
  margin: auto;
}
.body-move {
  padding: 18px 0 64px 0;
}
.body-move .body-title h2 {
  margin: 0 auto;
  text-align: center;
  font-family: Lato-Light;
  font-weight: 300;
  font-size: 40px;
  color: #333;
  max-width: 814px;
  line-height: 1.25;
}
.body-move .body-elements {
  padding: 33px 0 0 0;
}
.body-move .body-elements .body-element i {
  font-size: 42px;
  color: #555;
  font-weight: 300;
}
.body-move .body-elements .body-element h3 {
  font-family: Roboto-Medium;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #333;
  margin: 0;
  padding: 24px 0 0 0;
}
.body-move .body-elements .body-element p {
  font-family: Roboto-Regular;
  font-size: 16px;
  color: #555;
  margin: 0;
  padding-top: 5px;
}
.manage {
  background-color: #f5f5f5;
}
.manage .manage-move {
  padding: 56px 0 36px 0;
}
.manage .manage-move .manage-title h2 {
  margin: 0;
  font-family: Lato-Light;
  font-size: 40px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #333;
}
.manage .manage-move .left-element {
  padding: 86px 0 0 0;
}
.manage .manage-move .left-element .manage-steps .step-info span {
  font-family: Roboto-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #555;
}
.manage .manage-move .left-element .manage-phone {
  padding: 35px 0 0 0;
}
.manage .manage-move .left-element .manage-phone i {
  font-size: 33px;
  color: #555;
}
.manage .manage-move .left-element .manage-phone span {
  padding-left: 18px;
  font-family: Roboto-Regular;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}
.manage .manage-move .right-element .manage-form {
  padding-top: 56px;
}
.manage .manage-move .right-element .manage-form .error-send {
  border-radius: 5px 5px 5px 5px;
  border: 1px solid #c33;
  background: #c33;
  width: 446px;
  height: 96px;
  margin-bottom: 24px;
}
.manage .manage-move .right-element .manage-form .error-send .error-button {
  text-align: center;
  padding-top: 20px;
  width: 51px;
}
.manage
  .manage-move
  .right-element
  .manage-form
  .error-send
  .error-button
  .button-error {
  border: none;
  background: none;
}
.manage
  .manage-move
  .right-element
  .manage-form
  .error-send
  .error-button
  .button-error
  i {
  color: #fff;
  font-size: 24px;
}
.manage .manage-move .right-element .manage-form .error-send .error-message {
  background: #fff;
  width: 393px;
  height: -webkit-fill-available;
  border-radius: 0 5px 5px 0;
  padding: 16px 31px 16px 16px;
}
.manage .manage-move .right-element .manage-form .error-send .error-message h5 {
  font-family: Roboto-Bold;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  margin: 0;
}
.manage .manage-move .right-element .manage-form .error-send .error-message p {
  font-family: Roboto-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  margin: 0;
  padding-top: 4px;
}
.manage .manage-move .right-element .manage-form .form-element {
  padding: 42px 34px;
  width: 446px;
  min-height: 556px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35);
  background: #fff;
}
.manage .manage-move .right-element .manage-form .form-element .form-tittle {
  padding-bottom: 16px;
}
.manage .manage-move .right-element .manage-form .form-element .form-tittle h2 {
  margin: 0;
  width: 269px;
  height: 64px;
  font-family: Lato-Light;
  font-size: 28px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #555;
}
.manage .manage-move .right-element .manage-form .form-element .line-input {
  padding: 16px 0 0 0;
}
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  .input-element
  .element-form-label {
  height: 20px;
}
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  .input-element
  .element-form-label
  label {
  font-family: Roboto-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  .input-element
  .element-form-input {
  display: table;
}
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  .input-element
  .element-form-input
  input,
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  .input-element
  .element-form-input
  select,
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  .input-element
  .element-form-input
  .input-group-addon,
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  .input-element
  .element-form-input
  .angle-down {
  height: 50px;
  font-family: Roboto-Regular;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
}
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  .input-element
  .element-form-input
  input,
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  .input-element
  .element-form-input
  select,
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  .input-element
  .element-form-input
  .input-group-addon {
  border: solid 1px #ccc;
  border-radius: 0;
}
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  .input-element
  .element-form-input
  .input-group-addon {
  border-right: none;
}
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  .input-element
  .element-form-input
  input#txt_codArea,
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  .input-element
  .element-form-input
  input#txt_number {
  border-left: none;
  border-radius: 0;
  text-align: center;
}
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  .input-element
  .element-form-input
  .angle-down:after {
  content: "\f107";
  font-family: "FontAwesome";
  color: #0081b7;
  position: absolute;
  font-size: 24px;
  pointer-events: none;
  left: 0;
  right: 0;
  text-align: right;
  padding-right: 14px;
  padding-top: 10px;
}
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  .input-element
  .element-form-input
  select {
  -webkit-appearance: none;
  width: 108px;
  padding: 0 0 0 16px;
}
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  .input-element
  .element-form-input
  .full-border {
  border: 1px solid #ccc !important;
}
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  .input-element
  .element-form-input
  p {
  color: #767676;
}
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  #input_lineTipe {
  padding-left: 0;
}
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  #input_codeArea {
  padding-left: 0;
  padding-right: 0;
}
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  #input_phoneNum {
  padding-right: 0;
}
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  #input_firstName {
  margin-right: 15px;
}
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  #input_firstName,
.manage
  .manage-move
  .right-element
  .manage-form
  .form-element
  .line-input
  #input_lastName {
  width: 181px;
}
.manage .manage-move .right-element .manage-form .form-element .btn-global {
  margin-top: 32px;
  width: 100%;
  max-height: none;
  height: 50px;
  background: #0081b7;
  border-radius: unset;
  border: none;
  text-transform: none;
  padding: 0;
}
.legales {
  background-color: #f5f5f5;
}
@media (max-width: 768px) {
  .header-move {
    height: 350px;
    padding: 109px 0 119px;
  }
  .header-move .header-title span {
    font-size: 16px;
  }
  .header-move .header-title h1 {
    font-size: 32px;
    padding-top: 16px;
    height: auto;
  }
  .body-move {
    padding: 42px 16px 38px 16px;
  }
  .body-move .body-title p {
    font-size: 24px;
  }
  .body-move .body-elements {
    padding: 0 0 0 0;
  }
  .body-move .body-elements .body-element {
    padding: 53px 0 0 0;
  }
  .body-move .body-elements .body-element i {
    font-size: 34px;
  }
  .body-move .body-elements .body-element h5 {
    padding: 10px 0 0 0;
  }
  .manage .manage-move {
    background-color: #f5f5f5;
    padding: 55px 0 42px 0;
  }
  .manage .manage-move .manage-title p {
    font-size: 24px;
  }
  .manage .manage-move .left-element {
    padding: 24px 24px 24px 24px;
  }
  .manage .manage-move .left-element .manage-steps .step-info {
    padding: 0;
  }
  .manage .manage-move .left-element .manage-phone {
    padding-top: 28px;
  }
  .manage .manage-move .left-element .manage-phone i {
    font-size: 24px;
  }
  .manage .manage-move .left-element .manage-phone span {
    font-size: 16px;
  }
  .manage .manage-move .right-element {
    padding-top: 19px;
  }
  .manage .manage-move .right-element .manage-form {
    padding: 0;
  }
  .manage .manage-move .right-element .manage-form .error-send {
    width: 100%;
    height: 87px;
    margin-bottom: 26px;
    display: inline-flex;
  }
  .manage .manage-move .right-element .manage-form .error-send .error-button {
    width: 44px;
    padding-top: 16px;
  }
  .manage
    .manage-move
    .right-element
    .manage-form
    .error-send
    .error-button
    .button-error
    i {
    font-size: 18px;
  }
  .manage .manage-move .right-element .manage-form .error-send .error-message {
    width: -webkit-fill-available;
    padding: 16px;
  }
  .manage
    .manage-move
    .right-element
    .manage-form
    .error-send
    .error-message
    h5 {
    font-size: 14px;
  }
  .manage
    .manage-move
    .right-element
    .manage-form
    .error-send
    .error-message
    p {
    font-size: 12px;
  }
  .manage .manage-move .right-element .manage-form .form-element {
    padding: 24px 16px;
    width: 100%;
    min-height: 538px;
  }
  .manage .manage-move .right-element .manage-form .form-element .form-tittle {
    padding: 0;
  }
  .manage
    .manage-move
    .right-element
    .manage-form
    .form-element
    .form-tittle
    h3 {
    font-size: 24px;
  }
  .manage .manage-move .right-element .manage-form .form-element .line-input {
    padding: 0;
  }
  .manage
    .manage-move
    .right-element
    .manage-form
    .form-element
    .line-input
    .input-element {
    padding: 24px 0 0 0;
  }
  .manage
    .manage-move
    .right-element
    .manage-form
    .form-element
    .line-input
    .input-element
    .element-form-label
    label {
    font-size: 14px;
  }
  .manage
    .manage-move
    .right-element
    .manage-form
    .form-element
    .line-input
    .input-element
    .element-form-input
    input,
  .manage
    .manage-move
    .right-element
    .manage-form
    .form-element
    .line-input
    .input-element
    .element-form-input
    select,
  .manage
    .manage-move
    .right-element
    .manage-form
    .form-element
    .line-input
    .input-element
    .element-form-input
    .input-group-addon {
    font-size: 14px;
  }
  .manage
    .manage-move
    .right-element
    .manage-form
    .form-element
    .line-input
    .input-element
    .element-form-input
    .angle-down {
    width: -webkit-fill-available;
  }
  .manage
    .manage-move
    .right-element
    .manage-form
    .form-element
    .line-input
    .input-element
    .element-form-input
    select {
    width: -webkit-fill-available;
    padding-left: 6px;
  }
  .manage
    .manage-move
    .right-element
    .manage-form
    .form-element
    .line-input
    .input-element
    .element-form-input
    .input-group-addon {
    padding: 0;
    width: 30px;
  }
  .manage
    .manage-move
    .right-element
    .manage-form
    .form-element
    .line-input
    .input-element
    p {
    color: #767676;
  }
  .manage
    .manage-move
    .right-element
    .manage-form
    .form-element
    .line-input
    #input_firstName,
  .manage
    .manage-move
    .right-element
    .manage-form
    .form-element
    .line-input
    #input_lastName {
    width: 100%;
    margin: 0;
  }
  .manage
    .manage-move
    .right-element
    .manage-form
    .form-element
    .line-input
    #input_lineTipe {
    padding-right: 10px;
  }
  .manage
    .manage-move
    .right-element
    .manage-form
    .form-element
    .line-input
    #input_phoneNum {
    padding-left: 8px;
  }
  .manage .manage-move .right-element .manage-form .form-element .btn-global {
    font-size: 16px;
    white-space: nowrap;
    height: 40px;
  }
}
@media (max-width: 768px) {
  .address-section.modal-content {
    height: 100% !important;
  }
}
.address-section {
  min-height: 312px;
  height: auto;
  padding-top: 66px;
  padding: 0;
  background: #333;
}
@media (max-width: 768px) {
  .address-section .modal-header {
    padding: 28px 15px 0 15px !important;
  }
  .address-section .modal-header .arrow-icon {
    font-size: 24px;
    color: #99e5ff;
  }
}
@media (max-width: 768px) {
  .address-section .modal-body {
    padding: 0;
  }
}
.address-section .address-section-title {
  margin-bottom: 10px;
  color: #ccc;
}
@media (max-width: 768px) {
  .address-section .address-section-title {
    width: 300px;
    margin-bottom: 42px;
  }
}
.address-section .address-section-title h1,
.address-section .address-section-title h2,
.address-section .address-section-title h3,
.address-section .address-section-title h4,
.address-section .address-section-title h5,
.address-section .address-section-title h6 {
  margin-top: 49px;
  margin-bottom: 10px;
  color: #ccc;
}
@media (max-width: 768px) {
  .address-section .address-section-title h1,
  .address-section .address-section-title h2,
  .address-section .address-section-title h3,
  .address-section .address-section-title h4,
  .address-section .address-section-title h5,
  .address-section .address-section-title h6 {
    margin-top: 36px;
  }
}
.address-section .container {
  padding: 0;
  width: 700px;
}
@media (max-width: 768px) {
  .address-section .container {
    width: auto;
  }
}
.address-section .container .address-section-tiles-container {
  margin-bottom: 58px;
}
.address-section
  .container
  .address-section-tiles-container
  .address-section-tile-active {
  border-left: 6px solid #99e5ff !important;
  padding-left: 10px;
  background: rgba(153, 229, 255, 0.08) !important;
  text-decoration: none;
}
.address-section
  .container
  .address-section-tiles-container
  .address-section-tile:active {
  text-decoration: none;
}
.address-section
  .container
  .address-section-tiles-container
  .address-section-tile:hover {
  background: rgba(153, 229, 255, 0.04);
  text-decoration: none;
}
.address-section
  .container
  .address-section-tiles-container
  .address-section-tile:first-child {
  border-radius: 4px 4px 0 0;
}
.address-section
  .container
  .address-section-tiles-container
  .address-section-tile:last-child {
  border-radius: 0 0 4px 4px;
}
.address-section
  .container
  .address-section-tiles-container
  .address-section-tile {
  width: 701px;
  height: 78px;
  background: #333;
  color: #fff;
  margin: 0;
  border: 1px solid #ccc;
  outline: none;
}
@media (max-width: 768px) {
  .address-section
    .container
    .address-section-tiles-container
    .address-section-tile {
    width: 300px;
    height: 89px;
  }
}
.address-section
  .container
  .address-section-tiles-container
  .address-section-tile
  .tile-content {
  margin: 0;
}
.address-section
  .container
  .address-section-tiles-container
  .address-section-tile
  .tile-content
  .tile-description {
  text-align: left;
  margin-left: 16px;
}
@media (max-width: 768px) {
  .address-section
    .container
    .address-section-tiles-container
    .address-section-tile
    .tile-content
    .tile-description {
    margin-left: 7px;
  }
}
.address-section
  .container
  .address-section-tiles-container
  .address-section-tile
  .tile-content
  .tile-description
  h1,
.address-section
  .container
  .address-section-tiles-container
  .address-section-tile
  .tile-content
  .tile-description
  h2,
.address-section
  .container
  .address-section-tiles-container
  .address-section-tile
  .tile-content
  .tile-description
  h3,
.address-section
  .container
  .address-section-tiles-container
  .address-section-tile
  .tile-content
  .tile-description
  h4,
.address-section
  .container
  .address-section-tiles-container
  .address-section-tile
  .tile-content
  .tile-description
  h5,
.address-section
  .container
  .address-section-tiles-container
  .address-section-tile
  .tile-content
  .tile-description
  h6 {
  margin: 4px 0 4px 0;
  color: #fff;
}
@media (min-width: 768px) {
  .address-section
    .container
    .address-section-tiles-container
    .address-section-tile
    .tile-content
    .tile-description
    h6 {
    margin-top: 20px;
  }
}
.address-section
  .container
  .address-section-tiles-container
  .address-section-tile
  .tile-content
  .tile-description
  span {
  color: #ccc;
  margin-bottom: 17px;
}
.channel-guide-container {
  background: #282629;
}
.channel-guide-container .container {
  padding: 0;
}
@media (max-width: 768px) {
  .channel-guide-container .container {
    display: flex;
    justify-content: flex-end;
    padding: 0;
  }
}
.channel-guide-title {
  margin-top: 15px;
}
.channel-guide-boxes {
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  margin-bottom: 35px;
  margin-top: 25px;
}
@media (max-width: 768px) {
  .channel-guide-boxes {
    justify-content: flex-start;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
.channel-guide-box {
  background-color: #1b1b1d;
  border-radius: 3px;
  height: 184px;
  margin: 15px;
}
@media (min-width: 769px) {
  .channel-guide-box {
    width: 178px;
  }
}
@media (max-width: 768px) {
  .channel-guide-box {
    height: 90px;
    margin: 8px;
  }
}
.channel-guide-box-white {
  color: #fff;
}
.channel-guide-box-number {
  display: block;
  padding: 8px 8px 32px 8px;
  text-align: right;
  color: #fff;
}
@media (max-width: 768px) {
  .channel-guide-box-number {
    padding: 8px 8px 0 0;
    text-align: right;
    float: right;
    width: 100%;
  }
}
.channel-guide-box-name {
  padding-top: 19px;
  padding-bottom: 16px;
}
.channel-guide-info {
  color: #ccc;
  margin-bottom: 50px;
}
.channel-guide-info span i {
  padding-right: 20px;
}
@media (max-width: 768px) {
  .channel-guide-info span i {
    padding-right: 8px;
  }
}
.channel-guide-image {
  height: 68px;
  max-height: 68px;
}
.channel-guide-image .no-filter {
  filter: none;
}
.channel-guide-image .filter-2 {
  filter: brightness(2);
}
.channel-guide-image .filter-4 {
  filter: brightness(4);
}
.channel-guide-image img {
  height: 68px;
  max-height: 68px;
}
@media (max-width: 768px) {
  .channel-guide-image img {
    top: -20px;
    position: relative;
  }
}
.internet-addon-container {
  /* background: url(/file/general/banner-fibertel-security.png); */
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
}
@media (max-width: 768px) {
  .internet-addon-container {
    /* background: url(/file/general/banner-fibertel-security-mob.png); */
    background-repeat: no-repeat;
    background-size: cover;
    height: 370px;
  }
}
.internet-addon-content {
  text-align: left;
}
@media (max-width: 768px) {
  .internet-addon-content {
    text-align: center;
  }
}
.internet-addon-content a,
.internet-addon-content a:visited,
.internet-addon-content a:hover,
.internet-addon-content a:focus {
  color: #fff;
}
.internet-addon-title {
  color: #fff;
  margin-top: 37px;
}
@media (max-width: 768px) {
  .internet-addon-title {
    margin-top: 0;
    padding-top: 51px;
    padding-bottom: 8px;
  }
}
.internet-addon-description {
  margin-bottom: 20px;
}
.internet-addon-description span {
  color: #fff;
}
@media (max-width: 768px) {
  .internet-addon-description {
    margin-bottom: 16px;
    padding: 0 45px 0 45px;
  }
}
.dsk-title-black-xxl {
  font-size: 48px;
  font-family: Lato-Black;
  font-weight: normal;
}
.internet-addon-details i {
  font-size: 6px;
  vertical-align: middle;
  margin-right: 7px;
}
.internet-addon-details li {
  margin-right: 16px;
  display: inline;
  color: #fff;
}
@media (max-width: 768px) {
  .internet-addon-details li {
    display: block;
    margin-bottom: 8px;
  }
}
.internet-addon-button {
  width: 230px;
  height: 40px;
  border-radius: 5px;
  border: solid 1px #fff;
  color: #fff;
  line-height: 2;
  margin-top: 30px;
}
.internet-addon-button:visited {
  color: #fff;
}
@media (max-width: 768px) {
  .internet-addon-button {
    width: 250px;
    margin-top: 18px;
  }
}
.internet-speed-container {
  height: auto;
  padding: 0;
  background-color: #fff;
}
@media (min-width: 769px) {
  .internet-speed-container {
    max-height: 500px;
  }
}
@media (max-width: 768px) {
  .internet-speed-container {
    max-height: 803px;
  }
}
@media (min-width: 769px) {
  .internet-speed-container .internet-speed-content {
    padding: 0;
  }
}
.internet-speed-container .internet-speed-content .flex-boxed {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-flow: row wrap;
}
.internet-speed-container .internet-speed-content .flex-boxed .bar-speed {
  padding: 0;
}
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .internet-speed-title {
  margin-bottom: 58px;
}
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .internet-speed-title
  h1,
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .internet-speed-title
  h2,
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .internet-speed-title
  h3,
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .internet-speed-title
  h4,
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .internet-speed-title
  h5,
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .internet-speed-title
  h6 {
  margin: 0;
}
@media (max-width: 768px) {
  .internet-speed-container
    .internet-speed-content
    .flex-boxed
    .bar-speed
    .internet-speed-title {
    margin-top: 50px;
  }
}
@media (max-width: 768px) {
  .internet-speed-container
    .internet-speed-content
    .flex-boxed
    .bar-speed
    .progress-container {
    margin-bottom: 48px;
  }
}
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .progress-container
  progress[value] {
  height: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  appearance: none;
  position: relative;
}
@media (min-width: 769px) {
  .internet-speed-container
    .internet-speed-content
    .flex-boxed
    .bar-speed
    .progress-container
    progress[value] {
    width: 486px;
  }
}
@media (max-width: 768px) {
  .internet-speed-container
    .internet-speed-content
    .flex-boxed
    .bar-speed
    .progress-container
    progress[value] {
    width: 100%;
  }
}
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .progress-container
  progress[value]::-webkit-progress-bar,
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .progress-container
  progress[value]::-moz-progress-bar {
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
  background-color: #e0e0e0;
}
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .progress-container
  progress[value]::-webkit-progress-bar {
  background-color: #e0e0e0;
}
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .progress-container
  progress[value]::-webkit-progress-value {
  background-color: #0094ff !important;
}
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .progress-container
  progress[value]::-moz-progress-value {
  background-color: #e0e0e0;
}
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .progress-container
  progress[value]:after {
  content: "";
  position: relative;
  display: inline-block;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  top: -10px;
  width: 13px;
  height: 13px;
  box-shadow: 0 1px 3px 0 rgba(235, 146, 146, 0.25);
  background-color: #0094ff;
}
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .progress-container
  .fal,
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .progress-container
  .far,
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .progress-container
  .fas {
  position: relative;
  width: 22px;
  height: 18px;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #225380;
}
@media (min-width: 769px) {
  .internet-speed-container
    .internet-speed-content
    .flex-boxed
    .bar-speed
    .progress-container
    .fal,
  .internet-speed-container
    .internet-speed-content
    .flex-boxed
    .bar-speed
    .progress-container
    .far,
  .internet-speed-container
    .internet-speed-content
    .flex-boxed
    .bar-speed
    .progress-container
    .fas {
    right: 49.6%;
    bottom: 27px;
  }
}
@media (max-width: 768px) {
  .internet-speed-container
    .internet-speed-content
    .flex-boxed
    .bar-speed
    .progress-container
    .fal,
  .internet-speed-container
    .internet-speed-content
    .flex-boxed
    .bar-speed
    .progress-container
    .far,
  .internet-speed-container
    .internet-speed-content
    .flex-boxed
    .bar-speed
    .progress-container
    .fas {
    position: absolute;
    bottom: 52px;
    right: 46.8%;
  }
}
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .plan-name {
  padding: 0;
  margin-bottom: 50px;
}
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .plan-name
  ul {
  padding-left: 0;
}
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .plan-name
  ul
  li {
  display: inline;
  padding-right: 65px;
}
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .plan-name
  ul
  li:nth-of-type(5) {
  padding-right: 0;
}
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .ps-info
  .title {
  padding: 0 0 24px 0;
}
@media (min-width: 769px) {
  .internet-speed-container
    .internet-speed-content
    .flex-boxed
    .bar-speed
    .ps-info
    .recommendations
    .recomendation-section {
    padding: 0;
    margin-bottom: 35px;
  }
}
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .ps-info
  .recommendations
  .recomendation-section
  .recomendation-list {
  padding-left: 30px;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .internet-speed-container
    .internet-speed-content
    .flex-boxed
    .bar-speed
    .ps-info
    .recommendations
    .recomendation-section
    .recomendation-list {
    padding-left: 20px;
  }
}
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .ps-info
  .recommendations
  .recomendation-section
  .recomendation-list
  .recomendation-li-element {
  padding: 6.5px 0;
  list-style: none;
}
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .ps-info
  .recommendations
  .recomendation-section
  .recomendation-list
  .recomendation-li-element:before {
  content: "\f00c";
  font-family: FontAwesomePro;
  display: inline-block;
  margin-left: -2.3em;
  padding-right: 16px;
}
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .ps-info
  .info {
  display: flex;
  flex-flow: row;
  align-items: baseline;
}
@media (max-width: 768px) {
  .internet-speed-container
    .internet-speed-content
    .flex-boxed
    .bar-speed
    .ps-info
    .info {
    margin-top: 50px;
    margin-bottom: 23px;
  }
}
.internet-speed-container
  .internet-speed-content
  .flex-boxed
  .bar-speed
  .ps-info
  .info
  span {
  margin-right: 9px;
}
@media (min-width: 769px) {
  .internet-speed-container
    .internet-speed-content
    .flex-boxed
    .ps-image
    .hidden-xs {
    width: 490px;
    height: 516px;
  }
}
@media (min-width: 769px) {
  .internet-speed-container
    .internet-speed-content
    .flex-boxed
    .ps-image
    .visible-xs {
    width: 328px;
    height: 312.8px;
  }
}
@media (max-width: 768px) {
  .internet-speed-container .internet-speed-content .flex-boxed .ps-image {
    margin-bottom: 50px;
  }
}
.question-header .question-header-title-container {
  width: 98%;
}
.question-header .question-header-title-container .question-header-title {
  display: inline-block;
  width: 100%;
  margin: 0 0 0 2%;
  padding: 0;
  text-align: left;
  position: relative;
}
@media (max-width: 768px) {
  .question-header .question-header-title-container .question-header-title {
    width: 95%;
    padding-right: 20px;
  }
}
.question-header .question-header-title-container .question-icon {
  font-size: 18px;
  float: right;
  height: 1px;
  transform: translateY(500%);
}
.store {
  padding-top: 50px;
  padding-bottom: 53px;
}
.store p,
.store h4 {
  margin: 0;
  color: #fff;
}
.store .benefits {
  display: flex;
  justify-content: center;
  align-items: center;
}
.store .benefits .card-1,
.store .benefits .card-2,
.store .benefits .card-3 {
  padding-top: 26px;
  border-radius: 5px;
  height: 250px;
}
.store .benefits .card-1,
.store .benefits .card-2 {
  margin: 0;
  margin-right: 30px;
}
.store .benefits .card-1 {
  padding: 26px 0 0 32px;
}
.store .benefits .card-1 .title-c1 p {
  font-family: Roboto-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #fff;
}
.store .benefits .card-1 .subtitle-c1 {
  padding-top: 11px;
}
.store .benefits .card-1 .subtitle-c1 h4 {
  width: 282px;
  font-family: Lato-Black;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #fff;
}
.store .benefits .card-1 .description-c1 {
  padding-top: 1px;
}
.store .benefits .card-1 .description-c1 ul {
  list-style: none;
}
.store .benefits .card-1 .description-c1 ul li {
  padding: 13px 0 0 0;
  font-family: Roboto-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #fff;
}
.store .benefits .card-1 .description-c1 ul li:before {
  content: "\f00c";
  font-family: FontAwesome;
  font-weight: normal;
  display: inline-block;
  margin-left: -3em;
  width: 1.3em;
  color: #3cd2f2;
}
.store .benefits .card-2 {
  background: #017da6;
  display: block;
}
.store .benefits .card-2 .icon-c2 {
  height: 80px;
  width: 84px;
  margin: 0 auto;
  margin-top: 13px;
  text-align: center;
}
.store .benefits .card-2 .icon-c2 i {
  font-size: 64px;
  color: #fff;
}
.store .benefits .card-2 .content .title-c2 h4 {
  font-family: Lato-Black;
  font-size: 24px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
}
.store .benefits .card-2 .content .description-c2 {
  text-align-last: center;
}
.store .benefits .card-2 .content .description-c2 p {
  font-family: Roboto-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  text-align: center;
  width: 205px;
  margin: 0 auto;
}
.store .benefits .card-2 .content .goto-c2 {
  padding-top: 20px;
  text-align-last: center;
}
.store .benefits .card-2 .content .goto-c2 a {
  font-family: Roboto-Bold;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #fff;
}
.store .benefits .card-3 {
  background: #333;
  display: block;
}
.store .benefits .card-3 .icon-c3 {
  height: 80px;
  width: 84px;
  margin: 0 auto;
  margin-top: 13px;
  text-align: center;
}
.store .benefits .card-3 .icon-c3 i {
  font-size: 64px;
  color: #fff;
}
.store .benefits .card-3 .content .title-c3 h4 {
  font-family: Lato-Black;
  font-size: 24px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
}
.store .benefits .card-3 .content .description-c3 p {
  font-family: Roboto-Regular;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  color: #ccc;
}
.store .benefits .card-3 .content .goto-c3 {
  padding-top: 20px;
  text-align-last: center;
}
.store .benefits .card-3 .content .goto-c3 a {
  font-family: Roboto-Bold;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #fff;
}
@media (max-width: 768px) {
  .store .benefits {
    flex-flow: row wrap;
  }
  .store .benefits .card-1,
  .store .benefits .card-2,
  .store .benefits .card-3 {
    margin: 0;
    margin-bottom: 16px;
  }
  .store .benefits .card-2,
  .store .benefits .card-3 {
    height: 145px;
    padding: 22px 10px 0 20px;
    align-items: flex-start;
  }
  .store .benefits .card-1 {
    height: 250px;
    padding: 29px 55px 0 30px;
  }
  .store .benefits .card-1 .title-c1 p {
    font-size: 14px;
    line-height: 20px;
  }
  .store .benefits .card-1 .subtitle-c1 {
    padding: 0;
  }
  .store .benefits .card-1 .subtitle-c1 h4 {
    font-size: 18px;
    width: auto;
    line-height: 24px;
  }
  .store .benefits .card-1 .description-c1 ul li {
    padding: 16px 0 0 0;
  }
  .store .benefits .card-2 {
    display: flex;
    justify-content: center;
  }
  .store .benefits .card-2 .icon-c2 {
    height: auto;
    width: 45px;
    margin: 0 auto;
    padding-top: 22px;
  }
  .store .benefits .card-2 .icon-c2 i {
    font-size: 32px;
  }
  .store .benefits .card-2 .content {
    padding-left: 22px;
  }
  .store .benefits .card-2 .content .title-c2 h4 {
    font-size: 18px;
    text-align: left;
    width: auto;
  }
  .store .benefits .card-2 .content .description-c2 {
    text-align-last: left;
    padding-top: 9px;
  }
  .store .benefits .card-2 .content .description-c2 p {
    width: auto;
    text-align: left;
  }
  .store .benefits .card-2 .content .goto-c2 {
    padding-top: 15px;
    text-align-last: left;
  }
  .store .benefits .card-3 {
    display: flex;
    justify-content: center;
  }
  .store .benefits .card-3 .icon-c3 {
    height: auto;
    width: 45px;
    margin: 0 auto;
    padding-top: 22px;
  }
  .store .benefits .card-3 .icon-c3 i {
    font-size: 32px;
  }
  .store .benefits .card-3 .content {
    padding-left: 22px;
  }
  .store .benefits .card-3 .content .title-c3 h4 {
    font-size: 18px;
    font-weight: normal;
    text-align: left;
    width: auto;
  }
  .store .benefits .card-3 .content .description-c3 {
    text-align-last: left;
    padding-top: 2px;
  }
  .store .benefits .card-3 .content .description-c3 p {
    width: auto;
    text-align: left;
    font-weight: normal;
  }
  .store .benefits .card-3 .content .goto-c3 {
    padding-top: 15px;
    text-align-last: left;
  }
}
.container-4g {
  /* background-image: url("/file/general/background-4g-personal.png"); */
  background-size: cover;
  height: 873px;
  text-align: center;
}
@media (max-width: 768px) {
  .container-4g {
    height: auto;
    background-size: auto;
  }
}
.container-4g .line-4g {
  margin: 20px;
  margin-top: 29px;
  height: 43px;
  width: 1px;
  background-color: #ccc;
}
.title-4g h2 {
  color: #fff;
  margin: 0;
  padding-top: 51px;
  padding-bottom: 32px;
}
@media (max-width: 768px) {
  .title-4g {
    margin-left: 28px;
    margin-right: 32px;
    width: 300px;
  }
  .title-4g h2 {
    font-size: 24px;
  }
}
.description-4g {
  display: inline-flex;
}
.description-4g .line-4g {
  margin-top: 2px;
  height: 56px;
}
.img-4g {
  padding-right: 11px;
}
.img-4g img {
  width: 80px;
  height: 48px;
}
.left-description-4g {
  display: inline-block;
  color: #fff;
}
.left-description-4g p {
  text-align: left;
  width: 75px;
  margin-top: 7px;
}
.right-description-4g {
  max-width: 601px;
}
.right-description-4g p {
  color: #fff;
  text-align: left;
}
.details-container {
  height: 350px;
  margin-top: 32px;
  display: inline-flex;
}
@media (max-width: 768px) {
  .details-container {
    display: block;
    height: auto;
    width: auto;
  }
}
.details-container .color-blue {
  color: #0089c9;
}
@media (max-width: 768px) {
  .detail-box {
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}
.detail-box .box-header {
  text-align: center;
  padding-top: 39px;
}
.detail-box .box-header i {
  font-size: 42px;
  color: #333;
}
.detail-box .box-header h3 {
  font-family: Lato-Bold;
  font-size: 20px;
}
.detail-box .box-body {
  display: inline-flex;
  margin-top: 39px;
  margin-bottom: 45px;
  width: 100%;
  justify-content: center;
}
.detail-box .box-content {
  display: grid;
  max-width: 140px;
}
.detail-box .box-content span {
  display: block;
}
.detail-box .box-content .box-content-header {
  height: 23px;
}
.detail-box .box-content .box-content-header span {
  display: inline;
  color: #0089c9;
}
.detail-box .box-content .box-content-body {
  padding-top: 12px;
  height: 48px;
  margin: 0 auto;
}
.detail-box .box-content .box-content-footer {
  height: 40px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .detail-box .box-content .box-content-footer {
    width: 140px;
  }
}
.footer-container {
  color: #fff;
  display: inline-flex;
  justify-content: space-around;
  margin-top: 98px;
  width: 100%;
}
@media (max-width: 768px) {
  .footer-container {
    display: block;
    overflow: auto;
    white-space: nowrap;
    margin-top: 64px;
    padding-bottom: 59px;
  }
}
.footer-item {
  width: 282px;
}
.footer-item i {
  font-size: 56px;
}
.footer-item h3,
.footer-item img {
  color: #fff;
}
.footer-item p {
  width: 255px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .footer-item {
    width: 270px;
    display: inline-block;
  }
  .footer-item p {
    white-space: normal;
    width: 200px;
  }
}
.img-personal {
  width: 53px;
  height: 56px;
}
.img-club {
  width: 46px;
  height: 56px;
}
.telecom__button--dodger-blue__filled {
  background-color: #0094ff;
  border-radius: 0;
}
.telecom__button--dodger-blue__filled:hover,
.telecom__button--dodger-blue__filled:focus,
.telecom__button--dodger-blue__filled:active,
.telecom__button--dodger-blue__filled:visited {
  color: #fff !important;
}
.telecom__button--dodger-blue__outlined {
  border: 1px solid #0094ff;
  background-color: transparent;
  border-radius: 0;
}
.telecom__button--dodger-blue__outlined:hover,
.telecom__button--dodger-blue__outlined:focus,
.telecom__button--dodger-blue__outlined:active {
  border-color: transparent;
  color: #fff !important;
}
.telecom__button--color-flow-primario__outlined {
  border: 1px solid #57dfb7;
  color: #57dfb7 !important;
  background-color: transparent;
  border-radius: 0;
}
.telecom__button--color-flow-primario__outlined:hover,
.telecom__button--color-flow-primario__outlined:focus,
.telecom__button--color-flow-primario__outlined:active {
  border-color: transparent;
  color: #fff !important;
  background-color: #57dfb7;
}
.telecom__button--color-flow-primario__outlined a:hover,
.telecom__button--color-flow-primario__outlined a:focus,
.telecom__button--color-flow-primario__outlined a:active {
  color: #57dfb7;
}
.telecom__button--white__outlined {
  border-radius: 0;
  border: 1px solid #fff;
  background-color: transparent;
}
.telecom__button--white__outlined:hover,
.telecom__button--white__outlined:focus,
.telecom__button--white__outlined:active {
  border-color: transparent;
  color: #fff !important;
}
.telecom__letter-button,
.telecom__letter-button--dodger-blue,
.telecom__letter-button--dodger-blue:hover,
.telecom__letter-button--dodger-blue:focus,
.telecom__letter-button--dodger-blue:active,
.telecom__letter-button--black,
.telecom__letter-button--black:hover,
.telecom__letter-button--black:focus,
.telecom__letter-button--black:active,
.telecom__letter-button--white,
.telecom__letter-button--white:hover,
.telecom__letter-button--white:focus,
.telecom__letter-button--white:active,
.telecom__letter-button--red,
.telecom__letter-button--flow-primario,
.telecom__letter-button--flow-primario:hover,
.telecom__letter-button--flow-primario:focus,
.telecom__letter-button--flow-primario:active {
  background-color: transparent;
  border: none;
  outline: none;
}
.telecom__letter-button--dodger-blue {
  color: #0094ff !important;
}
.telecom__letter-button--dodger-blue:hover,
.telecom__letter-button--dodger-blue:focus,
.telecom__letter-button--dodger-blue:active {
  color: #0094ff !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.telecom__letter-button--black {
  color: #333 !important;
}
.telecom__letter-button--black:hover,
.telecom__letter-button--black:focus,
.telecom__letter-button--black:active {
  color: #333 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.telecom__letter-button--white {
  color: #fff !important;
}
.telecom__letter-button--white:hover,
.telecom__letter-button--white:focus,
.telecom__letter-button--white:active {
  color: #fff !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.telecom__letter-button--red {
  color: #dd2534;
}
.telecom__letter-button--icon {
  padding-left: 9px !important;
}
.telecom__letter-button--flow-primario {
  color: #57dfb7 !important;
}
.telecom__letter-button--flow-primario:hover,
.telecom__letter-button--flow-primario:focus,
.telecom__letter-button--flow-primario:active {
  color: #57dfb7 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.bannerFlowApp {
  margin: 0;
  padding: 56px 0 1px 0;
  width: 100%;
  display: flow-root;
}
.bannerFlowApp .flowApp__section--gray-background {
  height: 131px;
  margin-top: -75px;
  background-color: #f5f5f5;
}
.bannerFlowApp .flowApp__section--title {
  margin: 0;
  padding: 26px 0 32px 0;
  line-height: 1.25;
  width: 420px;
}
.bannerFlowApp .flowApp__section--paragraph {
  padding-top: 57px;
}
.bannerFlowApp .flowApp__section--paragraph p {
  padding: 0;
  margin: 0;
}
.bannerFlowApp .flowApp__section--paragraph a {
  padding: 8px 0 0 0;
}
@media (max-width: 768px) {
  .bannerFlowApp {
    padding: 48px 0 48px 0;
  }
  .bannerFlowApp .flowApp__section--gray-background {
    display: none;
  }
  .bannerFlowApp .flowApp__section--white-background {
    padding: 0;
  }
  .bannerFlowApp .flowApp__section--left-content img {
    margin: 0 auto;
  }
  .bannerFlowApp .flowApp__section--title {
    width: auto;
    padding: 32px 0 24px 0;
    font-family: Lato-Bold;
    line-height: 1.17;
  }
}
.sports-agenda {
  background-color: #282629;
}
.sports-agenda .sports-agenda-title h3 {
  margin-bottom: 50px;
  margin-top: 28px;
}
@media (max-width: 768px) {
  .sports-agenda .sports-agenda-title h3 {
    margin-bottom: 25px;
    margin-top: 26px;
  }
}
@media (max-width: 768px) {
  .sports-agenda .sports-agenda-content .sports-agenda-img img {
    width: 100%;
  }
}
.sports-agenda .sports-agenda-content .sports-agenda-info {
  background-color: #1b1b1d;
  padding-top: 49px;
  padding-left: 55px;
  height: 453px;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .sports-agenda .sports-agenda-content .sports-agenda-info {
    padding-top: 26px;
    padding-left: 33px;
    margin-bottom: 24px;
    height: auto;
  }
}
.sports-agenda .sports-agenda-content .sports-agenda-info .date {
  margin-bottom: 44px;
}
@media (max-width: 768px) {
  .sports-agenda .sports-agenda-content .sports-agenda-info .date {
    margin-bottom: 17px;
  }
}
.sports-agenda .sports-agenda-content .sports-agenda-info .description {
  margin-bottom: 71px;
}
@media (max-width: 768px) {
  .sports-agenda .sports-agenda-content .sports-agenda-info .description {
    margin-bottom: 31px;
  }
}
.sports-agenda .sports-agenda-content .sports-agenda-info .channel {
  margin-bottom: 61px;
}
@media (max-width: 768px) {
  .sports-agenda .sports-agenda-content .sports-agenda-info .channel {
    margin-bottom: 39px;
  }
}
.sports-agenda .sports-agenda-button a {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .sports-agenda .sports-agenda-button a {
    padding: 8px;
  }
}
.premium-content {
  background-color: #282629;
  padding-bottom: 30px;
  width: 100%;
  text-align: center;
}
@media (max-width: 768px) {
  .premium-content {
    height: auto;
  }
}
.premium-content .title h3,
.premium-content .title h1,
.premium-content .title h4 {
  color: #fff;
  margin: 0;
  padding-top: 51px;
  padding-bottom: 10px;
}
@media (max-width: 768px) {
  .premium-content .title h3,
  .premium-content .title h1,
  .premium-content .title h4 {
    font-size: 24px;
  }
}
.premium-content .premium-content-carousel {
  padding-bottom: 35px;
}
@media (min-width: 769px) {
  .premium-content .premium-content-carousel {
    display: inline-flex;
  }
}
@media (max-width: 768px) {
  .premium-content .premium-content-carousel {
    margin-left: 17px;
  }
}
.premium-content .premium-content-box {
  margin: 15px;
  width: auto !important;
}
@media (max-width: 768px) {
  .premium-content .premium-content-box {
    margin: 8px;
  }
  .premium-content .premium-content-box img {
    width: 241px;
    height: 364px;
  }
}
.premium-content .premium-content-button a {
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 768px) {
  .premium-content .premium-content-button a {
    width: 328px;
  }
}
.premium-content .slick-dots {
  height: 5px !important;
  bottom: inherit !important;
  margin-top: 32px !important;
}
.premium-content .slick-dots li {
  height: 5px !important;
  width: 37px !important;
  margin: 0 !important;
  top: -10px;
  background-color: #c4c4c4;
}
.premium-content .slick-dots li button {
  padding: 0 !important;
  width: 100%;
  height: 5px;
  display: none !important;
  position: absolute;
}
.premium-content .slick-dots li button:before {
  content: inherit !important;
}
.premium-content .slick-dots li.slick-active button {
  display: block !important;
  border-radius: 10px !important;
  background-color: #0094ff;
}
.premium-content .slick-dots li:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.premium-content .slick-dots li:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.tvLayer1 {
  background: #222;
  display: flow-root;
}
.tvLayer1__tvLayer2 {
  height: 350px;
  /* background: url("/file/general/Background.png") no-repeat; */
  background-size: 100% 100%;
  float: right;
}
.tvLayer1__tvLayer2--shadow {
  width: 25%;
  height: 350px;
  background: rgba(44, 34, 34, 0);
  padding: 0;
  margin-left: -20px;
}
.tvLayer1__tvLayer3 {
  width: 100%;
  height: 350px;
  color: #fff;
  padding: 67px 0 67px 0;
}
.tvLayer1__tvLayer3__content {
  color: #fff;
}
.tvLayer1__tvLayer3__content__title h1 {
  color: #fff;
  font-family: Lato-Black;
  font-size: 40px;
  margin: 0;
}
.tvLayer1__tvLayer3__content__description {
  padding-top: 10px;
  display: contents;
}
.tvLayer1__tvLayer3__content__description p {
  width: 235px;
}
.tvLayer1__tvLayer3__content__AdditionalInfo {
  padding-top: 9px;
}
.tvLayer1__tvLayer3__content__botton {
  padding-top: 20px;
}
.tvLayer1__tvLayer3__content__botton button {
  width: 208px;
  height: 40px;
  border-radius: 5px;
  background: #0094ff;
}
@media (max-width: 768px) {
  .tvLayer1 {
    /* background: url("/file/general/Background.png") no-repeat; */
    background-size: 100% 100%;
    padding: 52px 16px 48px 16px;
  }
  .tvLayer1__tvLayer2 {
    display: none;
  }
  .tvLayer1__tvLayer3 {
    height: auto;
    padding: 0;
  }
  .tvLayer1__tvLayer3__content__title h1 {
    font-size: 32px;
  }
  .tvLayer1__tvLayer3__content__description {
    padding-top: 8px;
  }
  .tvLayer1__tvLayer3__content__description p {
    margin: 0;
    width: auto;
  }
  .tvLayer1__tvLayer3__content__AdditionalInfo {
    padding-top: 35px;
  }
  .tvLayer1__tvLayer3__content__AdditionalInfo p {
    font-size: 12px;
    margin: 0;
    width: auto;
  }
  .tvLayer1__tvLayer3__content__botton {
    padding-top: 20px;
  }
  .tvLayer1__tvLayer3__content__botton button {
    width: -webkit-fill-available;
  }
}
.hiring {
  padding-top: 74px;
  padding-bottom: 99px;
  background-color: #f5f5f5;
}
.hiring .hiring__title h1 {
  margin: 0;
}
.hiring .hiring__media {
  padding-top: 84px;
}
.hiring .hiring__media .media__element {
  display: flex;
}
.hiring .hiring__media .media__element .element__icon {
  margin-top: 5px;
}
.hiring .hiring__media .media__element .element__icon span {
  font-size: 32px;
  color: #225380;
}
.hiring .hiring__media .media__element .element__icon img {
  width: 17px;
  height: 51px;
}
.hiring .hiring__media .media__element .element__content {
  padding-left: 24px;
}
.hiring .hiring__media .media__element .element__content h2 {
  margin: 0;
}
@media (max-width: 768px) {
  .hiring {
    padding: 48px 0 58px 0;
  }
  .hiring .hiring__title {
    text-align: center;
  }
  .hiring .hiring__title h1 {
    font-size: 24px;
  }
  .hiring .hiring__media {
    padding: 0 32px 0 32px;
  }
  .hiring .hiring__media .media__element {
    padding-top: 30px;
  }
  .hiring .hiring__media .media__element .element__icon {
    width: 40px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .hiring .hiring__media .media__element .element__content h2 {
    font-size: 18px;
  }
  .hiring .hiring__media .media__element .element__content p {
    font-size: 12px;
    margin: 0;
  }
  .hiring .hiring__media .media__botton {
    padding-top: 8px;
  }
}
.miniForm .form-rapido-error {
  height: auto;
  background-color: #f9eaea;
  border: 1px solid #c33;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  top: 5px;
  padding: 13px;
}
.miniForm .form-rapido-error .cancel-icon {
  text-align: center;
  color: #c33;
  font-size: 16px;
}
@media (max-width: 768px) {
  .miniForm .form-rapido-error {
    padding: 19px 13px 10px 16px;
  }
}
.miniForm .form-rapido {
  background-color: #f5f5f5;
  border-radius: 10px;
  height: auto;
  padding-top: 33px;
  padding-bottom: 33px;
  margin-bottom: 56px;
}
.miniForm .form-rapido .left-content .image-container {
  padding-top: 17px;
  width: auto;
  height: auto;
}
.miniForm .form-rapido .left-content .image-text-content {
  padding-top: 70px;
}
.miniForm .form-rapido .left-content .image-text-content h4 {
  margin: 0;
}
.miniForm .form-rapido .left-content .image-text-content p {
  padding-top: 5px;
  margin: 0;
}
.miniForm .form-rapido .form-control::placeholder {
  color: #ccc;
}
.miniForm .form-rapido .form-group {
  padding: 0 11px 0;
  margin-bottom: 0;
}
.miniForm .form-rapido .form-group form {
  width: 291px;
}
.miniForm .form-rapido .form-group .form-group-1 label {
  font-size: 14px;
  margin-bottom: 8px;
  color: #555;
}
.miniForm .form-rapido .form-group .form-group-2 {
  padding-top: 17px;
  margin: 0;
}
.miniForm .form-rapido .form-group .form-group-2 .titles .title-1 label,
.miniForm .form-rapido .form-group .form-group-2 .titles .tittle-2 label {
  font-size: 14px;
  margin-bottom: 8px;
  color: #555;
}
.miniForm .form-rapido .form-group .form-group-2 .titles .tittle-2 {
  text-align: center;
}
.miniForm .form-rapido .form-group .form-group-2 .sel-1 {
  padding: 0 3px 0 0;
}
.miniForm .form-rapido .form-group .form-group-2 .sel-1 .selec-t select {
  padding: 10px 0 0 9px;
}
.miniForm .form-rapido .form-group .form-group-2 .sel-2 {
  padding: 0 5px 0;
}
.miniForm .form-rapido .form-group .form-group-2 input#exampleInputAmount {
  padding: 0;
  text-align: center;
}
.miniForm .form-rapido .form-group .form-group-2 .alerts {
  padding-bottom: 10px;
}
.miniForm .form-rapido .form-group .form-group-2 .time-select {
  padding: 0 0 10px 0;
}
.miniForm .form-rapido .form-group .form-group-2 .btn-call {
  clear: both;
  padding-top: 23px;
}
.miniForm .form-rapido .form-group .form-group-2 .btn-call button {
  font-family: Roboto-Medium;
  height: 40px;
}
@media (max-width: 768px) {
  .miniForm .form-rapido {
    padding: 44px 16px 45px 16px;
    margin: 0 0 48px 0;
    border-radius: 0;
  }
  .miniForm .form-rapido .left-content {
    display: inline-flex;
    margin-bottom: 47px;
  }
  .miniForm .form-rapido .left-content .image-container {
    padding: 0;
  }
  .miniForm .form-rapido .left-content .image-container img {
    width: 70px !important;
    height: 76px;
  }
  .miniForm .form-rapido .left-content .image-text-content {
    padding: 0 0 0 27px;
  }
  .miniForm .form-rapido .left-content .image-text-content h4 {
    min-width: max-content;
  }
  .miniForm .form-rapido .form-group {
    padding: 0 11px 0 11px;
    margin: 0;
  }
  .miniForm .form-rapido .form-group form {
    width: auto;
  }
  .miniForm .form-rapido .form-group-2 .alerts {
    padding-bottom: 23px;
  }
  .miniForm .form-rapido .time-select {
    padding-bottom: 33px !important;
  }
}
.back-container-formv1 {
  /* background-image: url(/file/general/white-bck.png); */
  background-repeat: repeat-y;
  background-size: 50% auto;
  background-color: #f5f5f5;
  display: flow-root;
  width: 100%;
}
.back-container-formv1 .form-lead {
  padding-top: 40px;
  background: none;
}
.back-container-formv1 .form-lead .widget-back-margin-top {
  padding: 0 15px 50px 15px;
}
.back-container-formv1 .form-lead .offer-container .tittle-offer-1 {
  line-height: 1.43;
  color: #888;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.back-container-formv1 .form-lead .offer-container .tittle-offer-2 {
  line-height: 40px;
  margin-bottom: 32px;
  color: #333;
}
.back-container-formv1 .form-lead .offer-container .tittle-offer-3 {
  line-height: 1.2;
  color: #333;
}
.back-container-formv1 .form-lead .offer-container .tittle-offer-4 {
  line-height: 1.14;
  color: #888;
  text-decoration: line-through;
}
.back-container-formv1 .form-lead .offer-container .tittle-offer-5 {
  float: right;
  padding: 8px 33px 8px 20px;
  border-radius: 20px 0 0 20px;
  margin-top: 10px;
}
.back-container-formv1 .form-lead .offer-container .tittle-offer-6 {
  line-height: 1.17;
  color: #888;
  vertical-align: super;
  margin-bottom: 0;
  padding-top: 5px;
}
.back-container-formv1 .form-lead .offer-container .percentage-discount-text {
  padding-top: 16px;
}
.back-container-formv1 .form-lead .offer-container .offer-box-selected {
  padding-top: 70px;
}
.back-container-formv1
  .form-lead
  .offer-container
  .offer-box-selected
  .offer-description-selected {
  display: inline-flex;
  padding: 42px 0 0 0;
}
.back-container-formv1
  .form-lead
  .offer-container
  .offer-box-selected
  .offer-description-selected
  .div-icon-tittle {
  display: block;
  font-size: 24px;
  height: 24px;
  text-align: center;
  width: 30px;
}
.back-container-formv1
  .form-lead
  .offer-container
  .offer-box-selected
  .offer-description-selected
  .div-subtittle-description {
  padding-left: 24px;
  width: 100%;
}
.back-container-formv1
  .form-lead
  .offer-container
  .offer-box-selected
  .offer-description-selected
  .div-subtittle-description
  p {
  padding-top: 4px;
  margin: 0;
}
.back-container-formv1 .form-lead .offer-container .additional-box {
  padding: 42px 0 55px 0;
}
.back-container-formv1
  .form-lead
  .offer-container
  .additional-box
  .additional-tittle {
  margin-bottom: 16px;
  text-transform: uppercase;
}
.back-container-formv1
  .form-lead
  .offer-container
  .additional-box
  .additional-descri {
  margin-bottom: 24px;
}
.back-container-formv1
  .form-lead
  .content-box-form
  .form-error-box
  .form-error-container {
  background-color: #f9eaea;
  border: 1px solid #c33;
  border-radius: 2px;
  height: 76px;
  padding: 20px 14px 16px 16px;
  margin-left: 0;
  margin-bottom: 15px;
  width: 410px;
}
.back-container-formv1
  .form-lead
  .content-box-form
  .form-error-box
  .form-error-container
  p {
  padding: 0 14px 0 16px;
  margin: -4px;
}
.back-container-formv1 .form-lead .content-box-form .box-content-form-v1 {
  width: 410px;
  margin-left: 0;
}
.back-container-formv1
  .form-lead
  .content-box-form
  .box-content-form-v1
  .box-head {
  height: 55px;
  padding: 19px 37px 12px 40px;
}
.back-container-formv1
  .form-lead
  .content-box-form
  .box-content-form-v1
  .box-head
  .hd-box,
.back-container-formv1
  .form-lead
  .content-box-form
  .box-content-form-v1
  .box-head
  .hd-box {
  margin: 0;
  padding: 0;
  text-align: left;
  font-family: roboto-bold;
}
.back-container-formv1
  .form-lead
  .content-box-form
  .box-content-form-v1
  .content-form {
  padding: 23px 43px 0 43px;
}
.back-container-formv1
  .form-lead
  .content-box-form
  .box-content-form-v1
  .content-form
  h2 {
  color: #555;
  font-family: Lato-Bold;
  font-size: 18px;
  margin: 0;
  padding: 0;
}
.back-container-formv1
  .form-lead
  .content-box-form
  .box-content-form-v1
  .content-form
  .form-group-all {
  padding-top: 27px;
  font-family: Roboto-Regular;
  display: grid;
}
.back-container-formv1
  .form-lead
  .content-box-form
  .box-content-form-v1
  .content-form
  .form-group-all
  .form-group
  label.dsk-label {
  font-size: 14px !important;
}
.back-container-formv1
  .form-lead
  .content-box-form
  .box-content-form-v1
  .content-form
  .form-group-all
  .form-group
  .form-control {
  font-family: Roboto-Regular;
  font-size: 14px;
  font-weight: normal;
  color: #555;
  line-height: 1.43;
}
.back-container-formv1
  .form-lead
  .content-box-form
  .box-content-form-v1
  .content-form
  .form-group-all
  .form-group
  .form-control::placeholder {
  color: #ccc;
}
.back-container-formv1
  .form-lead
  .content-box-form
  .box-content-form-v1
  .content-form
  .form-group-all
  .form-group
  .form-box-a,
.back-container-formv1
  .form-lead
  .content-box-form
  .box-content-form-v1
  .content-form
  .form-group-all
  .form-group
  .form-box-b {
  margin: 0;
}
.back-container-formv1
  .form-lead
  .content-box-form
  .box-content-form-v1
  .content-form
  .form-group-all
  .form-group
  .form-box-a
  .form-group-a,
.back-container-formv1
  .form-lead
  .content-box-form
  .box-content-form-v1
  .content-form
  .form-group-all
  .form-group
  .form-box-b
  .form-group-a,
.back-container-formv1
  .form-lead
  .content-box-form
  .box-content-form-v1
  .content-form
  .form-group-all
  .form-group
  .form-box-a
  .form-group-b,
.back-container-formv1
  .form-lead
  .content-box-form
  .box-content-form-v1
  .content-form
  .form-group-all
  .form-group
  .form-box-b
  .form-group-b {
  margin: 0;
}
.back-container-formv1
  .form-lead
  .content-box-form
  .box-content-form-v1
  .content-form
  .form-group-all
  .btn-form_Lead {
  padding-top: 51px;
}
.back-container-formv1
  .form-lead
  .content-box-form
  .box-content-form-v1
  .content-form
  .form-group-all
  .btn-form_Lead
  .btn-global {
  position: static;
  font-family: Roboto-Medium !important;
}
.back-container-formv1 .form-lead .border-top-solid {
  border-top: 1px solid #ccc;
}
.back-container-formv1 .form-lead .context-box-legales {
  text-align: left;
}
@media (max-width: 768px) {
  .back-container-formv1 {
    background: #fff;
    padding: 32px 0 0 0;
  }
  .back-container-formv1 .form-lead {
    padding: 0;
  }
  .back-container-formv1 .form-lead .widget-back-margin-top {
    padding: 0;
  }
  .back-container-formv1 .form-lead .offer-container {
    padding: 0;
  }
  .back-container-formv1 .form-lead .offer-container .offer-box {
    padding: 50px 0 74px 0;
  }
  .back-container-formv1 .form-lead .offer-container .form-mobile {
    padding: 37px 17px;
    background: #f5f5f5;
  }
  .back-container-formv1
    .form-lead
    .offer-container
    .form-mobile
    .content-box-form
    .form-error-box
    .form-error-container {
    width: auto;
    padding: 19px 18px 11px 20px;
  }
  .back-container-formv1
    .form-lead
    .offer-container
    .form-mobile
    .content-box-form
    .box-content-form-v1 {
    margin: 0 auto;
    padding: 0;
    width: 100%;
  }
  .back-container-formv1
    .form-lead
    .offer-container
    .form-mobile
    .content-box-form
    .box-content-form-v1
    .box-head {
    padding: 19px 15px 12px 15px;
  }
  .back-container-formv1
    .form-lead
    .offer-container
    .form-mobile
    .content-box-form
    .box-content-form-v1
    .content-form {
    padding: 23px 16px 41px;
  }
  .back-container-formv1 .form-lead .offer-container .offer-box-selected {
    padding-top: 36px;
  }
  .back-container-formv1
    .form-lead
    .offer-container
    .offer-box-selected
    .div-icon-tittle {
    float: left;
    width: 30px;
    text-align: center;
  }
  .back-container-formv1
    .form-lead
    .offer-container
    .offer-box-selected
    .div-subtittle-description {
    padding: 0 0 0 22px;
  }
  .back-container-formv1 .form-lead .offer-container .additional-box {
    padding: 46px 0 39px 0;
  }
}
.flow-tv-section {
  padding-top: 56px;
  padding-bottom: 110px;
}
@media (max-width: 768px) {
  .flow-tv-section {
    height: auto;
    padding-bottom: 56px;
  }
}
.flow-tv-section .title {
  padding-bottom: 61px;
}
.flow-tv-section .title h2 {
  color: #57dfb7;
}
@media (max-width: 768px) {
  .flow-tv-section .title {
    padding: 0 15px 52px 15px;
  }
}
@media (max-width: 768px) {
  .flow-tv-section .container,
  .flow-tv-section .container-on-demand,
  .flow-tv-section .container-flow,
  .flow-tv-section .container-flow-netflix,
  .flow-tv-section .container-flow-music-xp,
  .flow-tv-section .info,
  .flow-tv-section .info .description {
    padding: 0;
  }
}
.flow-tv-section .flow-separator {
  border-top: solid 2px #57dfb7;
  margin-top: 7px;
  margin-bottom: 15px;
}
@media (max-width: 768px) {
  .flow-tv-section .container .info .description {
    padding-bottom: 25px;
  }
}
.flow-tv-section .container-on-demand {
  padding-bottom: 42px;
}
.flow-tv-section .container-on-demand .content .image-on-demand {
  width: fit-content;
  position: relative;
}
@media (max-width: 768px) {
  .flow-tv-section .container-on-demand .content .image-on-demand {
    margin: auto;
  }
}
@media (min-width: 769px) and (max-width: 991.98px) {
  .flow-tv-section .container-on-demand .content .image-on-demand {
    width: unset;
  }
  .flow-tv-section .container-on-demand .content .image-on-demand a {
    font-size: 48px !important;
  }
}
.flow-tv-section .container-on-demand .content .image-on-demand a {
  position: absolute;
  font-size: 70px;
  color: #ccc;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  text-decoration: none;
}
@media (max-width: 768px) {
  .flow-tv-section .container-on-demand .content .image-on-demand a {
    font-size: 48px;
  }
}
.flow-tv-section .container-on-demand .content .image-on-demand a:active {
  box-shadow: none;
}
@media (max-width: 768px) {
  .flow-tv-section .container-on-demand {
    padding-bottom: 56px;
  }
}
@media (max-width: 768px) {
  .flow-tv-section .container-flow-netflix {
    padding-bottom: 56px;
  }
  .flow-tv-section .container-flow-netflix .content img {
    margin: auto;
  }
}
@media (max-width: 768px) {
  .flow-tv-section .container-flow-music-xp .content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.flow-tv-section .container-flow-music-xp .content img {
  margin: auto;
}
@media (min-width: 769px) and (max-width: 991.98px) {
  .flow-tv-section .container-on-demand .image-on-demand a {
    font-size: 48px;
  }
  .flow-tv-section .container-on-demand .image-on-demand img {
    margin: auto;
  }
  .flow-tv-section .container-flow-netflix {
    padding-bottom: 42px;
  }
  .flow-tv-section .container-flow-netflix .content img {
    margin: auto;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .flow-tv-section .container-flow-netflix img,
  .flow-tv-section .container-flow-music-xp img {
    width: 100% !important;
  }
  .flow-tv-section .container-flow-music-xp .content {
    padding: inherit !important;
  }
}
.emotional-sale {
  padding: 32px 0 66px 0;
  color: #fff;
}
.emotional-sale h2 {
  color: #fff;
}
.emotional-sale .title-container {
  padding: 0 0 13px 0;
}
.emotional-sale .title-container .emotional-title {
  margin: 0;
}
.emotional-sale .emotional-box .emotional-box-container {
  margin-top: 30px;
  height: 200px;
  vertical-align: middle;
  text-align: center;
}
.emotional-sale .emotional-box .emotional-box-container .emotional-box-content {
  padding: 42px 0 0 0;
}
.emotional-sale
  .emotional-box
  .emotional-box-container
  .emotional-box-content
  .box-title
  h2 {
  margin: 0;
}
.emotional-sale .emotional-box .emotional-box-container .box-description {
  padding: 8px 0 0 0;
}
.emotional-sale .emotional-box .emotional-box-container .box-description p {
  height: 43px;
  margin: 0;
}
.emotional-sale
  .emotional-box
  .emotional-box-container
  .box-description
  .read-more-button-container {
  padding: 15px 0 0 0;
}
.emotional-sale
  .emotional-box
  .emotional-box-container
  .box-description
  .read-more-button-container
  .read-more-button {
  background: transparent;
  border: none;
  height: 20px;
  padding: 0 11px;
  font-family: Roboto-Medium;
}
.emotional-sale
  .emotional-box
  .emotional-box-container
  .box-description
  .read-more-button-container
  .read-more-button
  a {
  color: #fff;
}
@media (max-width: 768px) {
  .emotional-sale {
    padding: 35px 16px 61px 16px;
  }
  .emotional-sale .title-container {
    padding: 0 0 8px 0;
  }
  .emotional-sale .emotional-box {
    padding: 0;
  }
  .emotional-sale .emotional-box .emotional-box-container {
    margin-top: 16px;
  }
  .emotional-sale
    .emotional-box
    .emotional-box-container
    .emotional-box-content {
    padding: 39px 0 0 0;
  }
}
.social-media-bar {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-media-bar .description-text {
  padding-right: 50px;
}
.social-media-bar .description-text span {
  color: #e0e0e0;
}
@media (max-width: 768px) {
  .social-media-bar .description-text {
    padding: 25px 0 20px 0;
  }
}
.social-media-bar .social-media-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-media-bar .social-media-content .network i {
  font-size: 16px;
  color: #fff;
}
.social-media-bar .social-media-content .network a {
  padding-left: 8px;
  padding-right: 8px;
}
.social-media-bar .social-media-content .network-flow {
  padding-right: 32px;
}
@media (max-width: 768px) {
  .social-media-bar .social-media-content .network-flow {
    padding-right: 42px;
  }
}
@media (max-width: 768px) {
  .social-media-bar {
    height: 111px;
    display: block;
  }
}
.help-center-container {
  box-sizing: border-box;
  padding: 57px 0 73px;
}
@media (max-width: 768px) {
  .help-center-container {
    padding: 40px 0 40px;
  }
}
.help-center-container h1,
.help-center-container h2,
.help-center-container h3,
.help-center-container h4 {
  margin: 0;
}
.help-center-container .help-input-container {
  padding-top: 62px;
}
@media (max-width: 768px) {
  .help-center-container .help-input-container {
    padding-top: 44px;
  }
}
.help-center-container .help-input-container {
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto;
}
@media (min-width: 769px) {
  .help-center-container .help-input-container {
    max-width: 820px;
  }
}
.help-center-container .help-input-container .help-input-styles {
  height: 37px;
  width: 800px;
  outline: none;
  border: none;
  border-bottom: 1px solid #ccc;
  padding-left: 0;
}
@media (max-width: 768px) {
  .help-center-container .help-input-container .help-input-styles {
    width: 300px !important;
  }
}
.help-center-container .help-input-container .help-input-styles::placeholder {
  padding-left: 6px;
  color: #555;
}
.help-center-container .help-input-container i {
  position: relative !important;
  margin-left: -25px;
  font-size: 16px;
  color: #0094ff;
  font-weight: 300;
  width: 16px;
  height: 16px;
  object-fit: contain;
}
@media (max-width: 768px) {
  .help-center-container .help-input-container i {
    position: absolute;
    line-height: 2;
    color: #0094ff;
  }
}
.help-center-container .help-input-container i:hover {
  cursor: pointer;
}
.help-center-container .help-labels-container {
  width: 802px;
  display: flex;
  margin: 56px auto;
}
@media (max-width: 768px) {
  .help-center-container .help-labels-container {
    width: 328px;
    display: inherit;
    margin: 20px auto;
  }
}
.help-center-container .help-labels-container .help-label-element {
  border-right: 1px solid #ccc;
  min-width: 177px;
  text-align: center;
  color: #0094ff;
  line-height: 3;
}
.help-center-container .help-labels-container .help-label-element a {
  text-decoration: none;
  color: #0094ff;
}
.help-center-container .help-labels-container .help-label-element a:active,
.help-center-container .help-labels-container .help-label-element a:focus,
.help-center-container .help-labels-container .help-label-element a:hover {
  color: #0094ff;
}
.help-center-container
  .help-labels-container
  .help-label-element:nth-of-type(4) {
  text-align: right;
  border-right: none;
}
@media (max-width: 768px) {
  .help-center-container .help-labels-container .help-label-element {
    border: none;
  }
  .help-center-container .help-labels-container .help-label-element a {
    text-decoration: none;
  }
  .help-center-container
    .help-labels-container
    .help-label-element:nth-of-type(4) {
    text-align: center;
    border-right: none;
  }
}
.section-terms-and-conditions {
  background-color: #f5f5f5;
}
.section-terms-and-conditions .title-section {
  height: 200px;
}
.section-terms-and-conditions .title-section h1 {
  font-size: 64px;
  line-height: 72px;
  font-weight: normal;
  padding: 64px 0;
}
.section-terms-and-conditions .information-section {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35);
  background-color: #fff;
  margin: 42px auto;
}
.section-terms-and-conditions .information-section p {
  margin: 45.2px 40px 37.4px 39.1px;
}
.section-terms-and-conditions .links-section .link-title {
  margin-top: 42px;
  margin-bottom: 24px;
}
.section-terms-and-conditions .links-section .link-title span {
  font-size: 28px;
  line-height: 32px;
}
.section-terms-and-conditions .box-container .boxes {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35);
  background: #fff;
  height: fit-content;
  margin-bottom: 8px;
}
.section-terms-and-conditions .box-container .boxes .box-left {
  width: 50px;
}
.section-terms-and-conditions .box-container .boxes .box-left .left-bar {
  width: 6px;
  background: #0081b7;
}
.section-terms-and-conditions .box-container .boxes .center-bar {
  display: flex;
  align-items: center;
}
.section-terms-and-conditions
  .box-container
  .boxes
  .center-bar
  .title-description {
  display: flex;
  align-items: center;
}
.section-terms-and-conditions
  .box-container
  .boxes
  .center-bar
  .title-description
  h4 {
  line-height: 30px;
  font-weight: normal;
  pointer-events: none;
}
.section-terms-and-conditions .box-container .boxes .right-bar {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #0081b7;
  font-size: 48px;
  width: auto;
  float: right;
  margin-right: 39px;
}
.section-terms-and-conditions .box-dropdown .boxes {
  padding-left: 57px;
}
.section-terms-and-conditions .box-dropdown .boxes .right-bar {
  cursor: pointer;
}
.section-terms-and-conditions .box-dropdown .boxes .content-dropdown {
  margin-right: 55px;
  border-top: 1px solid #ccc;
}
.section-terms-and-conditions
  .box-dropdown
  .boxes
  .content-dropdown
  .content-body {
  padding: 20px 0 25px;
}
.section-terms-and-conditions
  .box-dropdown
  .boxes
  .content-dropdown
  .content-body
  a {
  color: #0081b7;
}
.section-terms-and-conditions .container .box-container .boxes .left-bar,
.section-terms-and-conditions .container .box-container .boxes .center-bar,
.section-terms-and-conditions .container .box-container .boxes .right-bar {
  height: 80px !important;
}
@media (min-width: 769px) and (max-width: 991px) {
  .section-terms-and-conditions .box-container .boxes .box-left {
    width: 17px;
  }
}
@media (max-width: 768px) {
  .section-terms-and-conditions .title-section h1 {
    font-family: Lato-Regular;
    font-size: 32px;
    line-height: 40px;
    padding: 64px 16px;
  }
  .section-terms-and-conditions .information-section {
    margin: 42px 16px;
  }
  .section-terms-and-conditions .information-section p {
    margin: 22px 13px 34px 19px;
  }
  .section-terms-and-conditions .links-section {
    margin: auto 16px;
  }
  .section-terms-and-conditions .links-section .link-title span {
    font-size: 24px;
    line-height: 30px;
  }
  .section-terms-and-conditions .box-container .boxes .box-left {
    width: 17px;
  }
  .section-terms-and-conditions .box-container .boxes .center-bar {
    display: flex;
    align-items: center;
  }
  .section-terms-and-conditions
    .box-container
    .boxes
    .center-bar
    .title-description
    h4 {
    font-size: 18px;
    font-family: Roboto-Regular;
    line-height: 24px;
    margin-right: 40px;
  }
  .section-terms-and-conditions .box-container .boxes .right-bar {
    padding: 8px;
    font-size: 32px;
    margin-right: 9px;
  }
  .section-terms-and-conditions .box-dropdown .boxes {
    padding-left: 17px;
  }
  .section-terms-and-conditions .box-dropdown .boxes .content-dropdown {
    margin-right: 18px;
  }
  .section-terms-and-conditions .container .box-container .boxes .left-bar,
  .section-terms-and-conditions .container .box-container .boxes .center-bar,
  .section-terms-and-conditions .container .box-container .boxes .right-bar {
    height: 110px !important;
  }
}
.section-terms-and-conditions.last {
  padding-bottom: 80px;
}
.fibertel-zone-section {
  width: 100%;
}
.fibertel-zone-section .banner-section {
  width: auto;
  height: 350px;
  padding: 81px 0;
}
.fibertel-zone-section .banner-section .title-section h1 {
  font-size: 64px;
  line-height: 72px;
  font-weight: normal;
}
.fibertel-zone-section .banner-section .description-section h4 {
  line-height: 32px;
  font-weight: normal;
}
.fibertel-zone-section .content-section .title-section h2,
.fibertel-zone-section .step-section .title-section h2 {
  font-size: 40px;
  line-height: 48px;
}
.fibertel-zone-section .step-section {
  margin-bottom: 124px;
}
.fibertel-zone-section .step-section .step-img {
  margin-bottom: 30px;
}
.fibertel-zone-section .step-section .step-progress .step:first-child {
  text-align: right;
}
.fibertel-zone-section .step-section .step-progress .step:last-child {
  text-align: left;
}
.fibertel-zone-section .step-section .step-progress .progress-dot {
  border-radius: 100%;
  border: 2px solid #0081b7;
  width: 12px;
  height: 12px;
  background-color: #fff;
  display: inline-block;
  position: relative;
}
.fibertel-zone-section .step-section .step-progress .progress-line {
  border: 1px solid #333;
  background: #333;
  width: 50%;
  height: 1px;
  margin-bottom: 5px;
  border-radius: 0;
  display: inline-block;
  margin-left: -3px;
  margin-right: -3px;
}
.fibertel-zone-section .step-section .step-progress .progress-line-center {
  width: 49%;
}
@media (min-width: 991px) and (max-width: 1068px) {
  .fibertel-zone-section .step-section .step-progress .progress-line-center {
    width: 48%;
  }
}
@media (max-width: 991px) {
  .fibertel-zone-section .banner-section {
    padding-top: 93px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .fibertel-zone-section .banner-section .title-section h1 {
    font-size: 32px;
    line-height: 40px;
    font-weight: normal;
  }
  .fibertel-zone-section .content-section,
  .fibertel-zone-section .step-section {
    padding-left: 16px;
    padding-right: 16px;
  }
  .fibertel-zone-section .content-section .title-section span,
  .fibertel-zone-section .step-section .title-section span {
    font-size: 24px;
    line-height: 30px;
  }
  .fibertel-zone-section .content-section .description-section span,
  .fibertel-zone-section .step-section .description-section span {
    font-size: 16px;
  }
  .fibertel-zone-section .step-section {
    margin-bottom: 0;
  }
  .fibertel-zone-section .step-section .step-img {
    margin-bottom: 0;
  }
  .fibertel-zone-section .step-section .step-img .step {
    display: inline-flex;
    justify-content: center;
    margin-bottom: 144px;
  }
  .fibertel-zone-section .step-section .step-progress .step {
    height: 266px;
    padding-top: 54px;
  }
  .fibertel-zone-section .step-section .step-progress .step:last-child {
    padding-top: 0;
  }
  .fibertel-zone-section
    .step-section
    .step-progress
    .step:last-child
    .progress-line {
    height: 54px;
  }
  .fibertel-zone-section .step-section .step-progress .step-center {
    padding-top: 0;
  }
  .fibertel-zone-section .step-section .step-progress .progress-dot {
    display: block;
  }
  .fibertel-zone-section .step-section .step-progress .progress-line {
    margin: 0;
    margin-left: 5px;
    width: 1px;
    height: 200px;
    display: block;
  }
  .fibertel-zone-section .step-section .step-progress .progress-line-center {
    height: 54px;
  }
  .fibertel-zone-section
    .step-section
    .step-progress
    .progress-line-center:last-child {
    height: 200px;
  }
  .fibertel-zone-section .step-section .step-description .step-content {
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 50px;
    height: 266px;
  }
  .fibertel-zone-section
    .step-section
    .step-description
    .step-content
    .step-title
    span {
    font-family: Roboto-Bold;
    font-size: 18px;
    line-height: 24px;
  }
  .fibertel-zone-section
    .step-section
    .step-description
    .step-content
    .step-subtitle
    h3 {
    font-weight: 400;
    line-height: 21px;
  }
  .fibertel-zone-section
    .step-section
    .step-description
    .step-content
    .step-description
    span {
    font-size: 16px;
  }
}
.spacing-xs {
  margin-bottom: 8px;
}
.spacing-m {
  margin-bottom: 16px;
}
.spacing-l {
  margin-bottom: 24px;
}
.spacing-xxl {
  margin-bottom: 42px;
}
.spacing-xxl-2 {
  margin-bottom: 56px;
}
.legals-section .legals {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35);
  margin-bottom: 39px;
  padding-left: 104px;
}
.legals-section .legals .center-bar {
  display: flex;
  align-items: center;
}
.legals-section .legals .center-bar,
.legals-section .legals .right-bar {
  height: 80px;
}
.legals-section .legals .right-bar {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #0081b7;
  font-size: 32px;
  width: auto;
  float: right;
  margin-right: 112px;
}
.legals-section .legals .legals-dropdown {
  margin-right: 112px;
  border-top: 1px solid #ccc;
}
.legals-section .legals .legals-dropdown .content-body {
  padding: 24px 0 20px;
}
@media (max-width: 768px) {
  .legals-section {
    margin: auto 16px;
  }
  .legals-section .legals {
    margin-bottom: 42px;
    padding-left: 17px;
    padding-right: 16px;
  }
  .legals-section .legals .center-bar .title-description h4 {
    font-family: Roboto-Regular;
    font-size: 18px;
  }
  .legals-section .legals .center-bar,
  .legals-section .legals .right-bar {
    height: 66px;
  }
  .legals-section .legals .right-bar {
    margin-right: 0;
    padding-right: 0;
  }
  .legals-section .legals .legals-dropdown {
    margin-right: 0;
  }
  .legals-section .legals .legals-dropdown .content-body .info p {
    font-size: 14px;
  }
}
@media (min-width: 769px) and (max-width: 1200px) {
  .legals-section .right-bar {
    margin-right: 0 !important;
  }
}
.typ-decline-form {
  margin-top: 73px;
}
.typ-decline-form .image-container {
  margin-bottom: 7px;
}
.typ-decline-form .button-container {
  margin-bottom: 98px;
}
.typ-decline-form .button-container button {
  width: 289px;
  max-height: 50px !important;
  height: 50px;
  padding: 8px 24px;
  border-radius: 0 !important;
  border: none;
  text-transform: none;
}
@media (max-width: 768px) {
  .typ-decline-form .content-info {
    margin-bottom: 40px;
  }
  .typ-decline-form .button-container {
    margin-bottom: 71px;
  }
  .typ-decline-form .button-container button {
    width: 232px;
    height: 40px;
    font-size: 16px;
  }
}
.spacing-xxl-3 {
  margin-bottom: 64px;
}
.magazine-detail {
  padding: 63px 0 42px 0;
}
.magazine-detail .magazine-container .detail-image {
  padding-top: 9px;
  text-align: right;
}
.magazine-detail .magazine-container .detail-image img {
  width: 184px;
  height: 260px;
  margin-right: 6px;
}
.magazine-detail .magazine-container .detail-text h5 {
  margin: 0;
  font-family: Lato-Light;
  color: #333;
  font-size: 40px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
}
.magazine-detail .magazine-container .detail-text ol {
  padding: 29px 0 0 0;
  margin: 0;
  list-style: none;
}
.magazine-detail .magazine-container .detail-text ol li {
  font-family: Roboto-Regular;
  color: #555;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: normal;
  content: "\f00c";
  position: relative;
  padding: 0 0 29px 45px;
}
.magazine-detail .magazine-container .detail-text ol li::before {
  font-family: FontAwesomePro;
  font-size: 28px;
  font-weight: 300;
  padding-right: 20px;
  content: counter(lista-personalizada);
  counter-increment: lista-personalizada;
  position: absolute;
  left: 0;
  top: 0;
  color: #555;
  content: "\f00c";
}
@media (max-width: 768px) {
  .magazine-detail {
    padding: 56px 0 41px 0;
  }
  .magazine-detail .magazine-container .detail-image {
    text-align: center;
  }
  .magazine-detail .magazine-container .detail-image img {
    width: 154px;
    height: 217px;
    margin-right: 6px;
  }
  .magazine-detail .magazine-container .detail-text {
    padding: 37px 0 0 0;
  }
  .magazine-detail .magazine-container .detail-text h5 {
    text-align: center;
    font-size: 24px;
  }
  .magazine-detail .magazine-container .detail-text ol {
    padding-top: 29px;
  }
  .magazine-detail .magazine-container .detail-text ol li {
    padding-bottom: 24px !important;
    font-size: 16px;
    text-align: left;
    padding-left: 32px;
  }
  .magazine-detail .magazine-container .detail-text ol li::before {
    font-size: 24px;
    padding-right: 20px;
  }
}
.telco-checkbox {
  display: none;
}
.telco-checkbox + label {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
}
.telco-checkbox + label:active,
.telco-checkbox:checked + label:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 1px 3px rgba(0, 0, 0, 0.1);
}
.telco-checkbox:checked + label {
  background-color: #0081b7;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1);
  color: #99a1a7;
}
.telco-checkbox:checked + label:after {
  content: "\f00c";
  font-family: FontAwesomePro;
  font-size: 15px;
  position: absolute;
  top: -1px;
  left: 3px;
  color: #fff;
}
.telco-radio {
  display: none;
}
.telco-radio + label {
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 9px;
  border-radius: 50px;
  display: inline-block;
  position: relative;
}
.telco-radio:checked + label:after {
  content: " ";
  width: 12px;
  height: 12px;
  border-radius: 50px;
  position: absolute;
  top: 3px;
  background: #0081b7;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
  text-shadow: 0;
  left: 3px;
  font-size: 32px;
}
.telco-radio:checked + label {
  background-color: #fff;
  color: #0081b7;
  border: 1px solid #0081b7;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0 -15px 10px -12px rgba(0, 0, 0, 0.05),
    inset 15px 10px -12px rgba(255, 255, 255, 0.1),
    inset 0 0 10px rgba(0, 0, 0, 0.1);
}
.telco-radio + label:active,
.telco-radio:checked + label:active {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 1px 3px rgba(0, 0, 0, 0.1);
}
.unsubscribe-request-container .unsubscribe-request-form {
  padding-top: 42px;
  padding-bottom: 56px;
}
.unsubscribe-request-container .unsubscribe-request-form .error-send {
  border-radius: 5px 5px 5px 5px;
  border: 1px solid #c33;
  background: #c33;
  height: 96px;
  margin-bottom: 42px;
}
.unsubscribe-request-container .unsubscribe-request-form .error-button {
  text-align: center;
  padding-top: 20px;
}
.unsubscribe-request-container .unsubscribe-request-form .error-button button {
  border: none;
  background: none;
}
.unsubscribe-request-container .unsubscribe-request-form .error-button i {
  color: #fff;
  font-size: 24px;
}
.unsubscribe-request-container .unsubscribe-request-form .error-message {
  background: #fff;
  height: -webkit-fill-available;
  border-radius: 0 5px 5px 0;
  padding: 16px 31px 16px 16px;
}
.unsubscribe-request-container .unsubscribe-request-form .error-message h5 {
  font-family: Roboto-Bold;
  font-size: 16px;
  margin: 0;
}
.unsubscribe-request-container .unsubscribe-request-form .error-message p {
  font-family: Roboto-Regular;
  font-size: 14px;
  margin: 0;
  padding-top: 4px;
}
.unsubscribe-request-container .unsubscribe-title {
  font-family: Lato-Light;
  font-size: 28px;
  color: #333;
}
.unsubscribe-request-container .unsubscribe-subtitle {
  font-family: Lato-Light;
  font-size: 24px;
  color: #333;
}
@media (max-width: 769px) {
  .unsubscribe-request-container .unsubscribe-title {
    font-size: 24px;
  }
  .unsubscribe-request-container .unsubscribe-subtitle {
    font-size: 20px;
  }
  .unsubscribe-request-container .element-form-input,
  .unsubscribe-request-container .element-form-label {
    font-size: 14px;
  }
  .unsubscribe-request-container .dsk-body {
    font-size: 12px;
  }
  .unsubscribe-request-container .form-check-label,
  .unsubscribe-request-container .reason-label {
    font-size: 16px;
  }
}
.unsubscribe-request-container .data-client .title p {
  margin: 0;
  font-weight: 300;
  color: #333;
}
.unsubscribe-request-container .data-client .title .subtitle {
  font-size: 16px;
  font-family: Roboto-Regular;
  font-weight: normal;
  color: #c33;
  padding-top: 8px;
}
.unsubscribe-request-container .data-client .subtitle {
  padding-top: 61px;
}
.unsubscribe-request-container .data-client .subtitle h3 {
  font-family: Lato-Light;
  font-size: 24px;
  font-weight: 300;
  color: #333;
}
.unsubscribe-request-container .data-client .line-input {
  padding-top: 24px;
}
.unsubscribe-request-container
  .data-client
  .line-input
  .input-element
  .element-form-label {
  font-size: 16px;
  font-family: Roboto-Regular;
  font-weight: normal;
  height: 20px;
  margin-bottom: 8px;
  color: #555 5;
}
.unsubscribe-request-container
  .data-client
  .line-input
  .input-element
  .element-form-label
  .error {
  color: #c33;
}
.unsubscribe-request-container
  .data-client
  .line-input
  .input-element
  .element-form-input {
  color: #555;
}
.unsubscribe-request-container
  .data-client
  .line-input
  .input-element
  .element-form-input
  input {
  height: 50px;
  border: solid 1px #ccc;
  font-family: Roboto-Regular;
  font-size: 16px;
  color: #555;
}
.unsubscribe-request-container
  .data-client
  .line-input
  .input-element
  .element-form-input
  input.error {
  border-color: #c33;
}
.unsubscribe-request-container
  .data-client
  .line-input
  .input-element
  .element-form-input
  input.error-right {
  border-left: none;
}
.unsubscribe-request-container
  .data-client
  .line-input
  .input-element
  .element-form-select
  select {
  height: 50px;
  font-family: Roboto-Regular;
  border: solid 1px #ccc;
  border-radius: 0;
  -webkit-appearance: none;
  width: 178px;
  padding: 0 0 0 16px;
}
.unsubscribe-request-container
  .data-client
  .line-input
  .input-element
  .element-form-select
  .angle-down:after {
  content: "\f107";
  font-family: "FontAwesome";
  color: #0081b7;
  position: absolute;
  font-size: 24px;
  pointer-events: none;
  left: 0;
  right: 0;
  top: 44px;
  text-align: right;
  padding-right: 38px;
}
.unsubscribe-request-container .selec-t {
  height: 50px !important;
}
.unsubscribe-request-container .unsubscribe-product {
  padding-top: 91px;
  margin-bottom: 64px;
}
.unsubscribe-request-container .unsubscribe-product .title {
  font-family: Lato-light;
  font-size: 28px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #333;
  text-align: center;
  padding-bottom: 24px;
}
.unsubscribe-request-container .unsubscribe-product .body-product {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35);
  margin-top: 0;
}
.unsubscribe-request-container
  .unsubscribe-product
  .body-product
  .product-header {
  background: #fff;
}
.unsubscribe-request-container
  .unsubscribe-product
  .body-product
  .product-header
  .product-title {
  padding-top: 26px;
  background-color: #fff;
  padding-left: 60px;
}
.unsubscribe-request-container
  .unsubscribe-product
  .body-product
  .product-header
  .product-title
  i {
  font-size: 32px;
  margin-right: 16px;
}
@media (max-width: 769px) {
  .unsubscribe-request-container
    .unsubscribe-product
    .body-product
    .product-header
    .product-title {
    padding-left: 14.5px;
  }
  .unsubscribe-request-container
    .unsubscribe-product
    .body-product
    .product-header
    .product-title
    i {
    font-size: 24px;
  }
  .unsubscribe-request-container
    .unsubscribe-product
    .body-product
    .product-header
    .product-title
    span {
    font-size: 18px;
  }
}
.unsubscribe-request-container
  .unsubscribe-product
  .body-product
  .product-header
  hr {
  background-color: #ccc;
  height: 1px;
  border: 0;
  margin-left: 16px;
  margin-right: 16px;
  margin-bottom: 0;
}
.unsubscribe-request-container
  .unsubscribe-product
  .body-product
  .product-checkboxs {
  padding: 11px 0 10px 0;
  background: #f5f5f5;
  padding-left: 120px;
}
.unsubscribe-request-container
  .unsubscribe-product
  .body-product
  .product-checkboxs
  .form-check {
  padding: 21px 0 21px 0;
}
.unsubscribe-request-container
  .unsubscribe-product
  .body-product
  .product-checkboxs
  .form-check
  .form-checkbox {
  top: 5px;
  margin-bottom: 0;
}
.unsubscribe-request-container
  .unsubscribe-product
  .body-product
  .product-checkboxs
  .form-check
  .form-check-label {
  margin-left: 16px;
  font-weight: normal;
}
@media (max-width: 769px) {
  .unsubscribe-request-container .error-send {
    width: 100%;
    height: 87px;
    margin-bottom: 26px;
    display: inline-flex;
  }
  .unsubscribe-request-container .error-send .error-button {
    width: 44px;
    padding-top: 16px;
  }
  .unsubscribe-request-container .error-send .error-button .button-error i {
    font-size: 18px;
  }
  .unsubscribe-request-container .error-send .error-message {
    width: -webkit-fill-available;
    padding: 16px;
  }
  .unsubscribe-request-container .error-send .error-message h5 {
    font-size: 14px;
  }
  .unsubscribe-request-container .error-send .error-message p {
    font-size: 12px;
  }
  .unsubscribe-request-container .line-input {
    padding-top: 0 !important;
  }
  .unsubscribe-request-container .line-input .input-element {
    margin-top: 27px !important;
    padding: 0;
  }
  .unsubscribe-request-container
    .line-input
    .input-element
    .element-form-select
    select {
    height: 40px !important;
    width: 100% !important;
  }
  .unsubscribe-request-container
    .line-input
    .input-element
    .element-form-select
    .angle-down:after {
    top: 40px !important;
    padding-right: 8px !important;
  }
  .unsubscribe-request-container
    .line-input
    .input-element
    .element-form-input
    input {
    height: 40px !important;
  }
  .unsubscribe-request-container .line-input .input-element-left {
    padding-left: 15px;
  }
  .unsubscribe-request-container
    .unsubscribe-product
    .body-product
    .product-checkboxs {
    padding-left: 50px;
  }
}
.unsubscribe-reasons {
  background-color: #f5f5f5;
}
.unsubscribe-reasons .btn-global {
  padding: 8px 24px;
  border-radius: 0 !important;
  border: none;
  text-transform: none !important;
  padding: 15px 49px;
  max-height: 50px;
}
.unsubscribe-reasons .title {
  margin-top: 57px;
}
.unsubscribe-reasons .title p {
  font-family: Lato-light;
  font-size: 28px;
  font-weight: 300;
  color: #333;
  text-align: center;
  padding-bottom: 24px;
}
.unsubscribe-reasons .reason-box {
  box-shadow: 0 1px 2px 0;
  margin-bottom: 8px;
  margin-top: 8px;
  background-color: #fff;
  height: 50px;
}
@media (max-width: 769px) {
  .unsubscribe-reasons .reason-box {
    margin-bottom: 16px;
  }
}
.unsubscribe-reasons .reason-box label {
  top: 20%;
  margin-left: 35px;
}
.unsubscribe-reasons .reason-box .reason-label {
  padding-top: 13px !important;
  margin-left: 15px;
  font-family: Roboto-regular;
  font-weight: normal;
  font-size: 18px;
}
.unsubscribe-reasons .unsubscribe-button {
  text-align: center;
  margin-top: 56px;
  margin-bottom: 56px;
}
@media (max-width: 769px) {
  .unsubscribe-reasons .unsubscribe-button button {
    width: 100%;
  }
}
.unsubscribe-reasons .btn-global-enabled {
  border: 1px solid #0081b7 !important;
  background-color: #0081b7 !important;
  color: #fff;
}
.unsubscribe-reasons .btn-global-disabled {
  background-color: #ccc !important;
  color: #767676;
  border-radius: 0 !important;
  padding: 15px 49px;
  text-transform: none;
  border-color: #ccc !important;
}
.cucarda-destaque {
  border-radius: 3px;
  height: 20px;
  min-width: 10px;
  text-align: center;
  display: inline-block;
}
.cucarda-destaque .text {
  font-size: 10px;
  margin: 2px 7px;
  vertical-align: middle;
}
.html-offers-content {
  padding: 74px 21px 101px 21px;
}
.html-offers-content .title {
  margin-bottom: 25px;
}
.html-offers-content .html-box {
  margin-bottom: 16px;
}
.html-offers-content .html-box span a {
  color: #0081b7;
  cursor: pointer;
}
.html-offers-content .content-more {
  border-top: solid 1px #ccc;
  padding: 0;
  padding-top: 16px;
  margin: 15px;
  color: #dd2534;
  cursor: pointer;
}
.html-offers-content .content-more i {
  font-size: 24px;
  vertical-align: bottom;
  padding-left: 2px;
}
.html-offers-content .content-more .show-content {
  display: inline;
}
.html-offers-content .content-more .hide-content {
  display: none;
}
@media (max-width: 768px) {
  .html-offers-content {
    padding-top: 39px;
    padding-bottom: 60px;
  }
  .html-offers-content .title {
    text-align: left;
    margin-bottom: 17px;
  }
  .html-offers-content .html-box {
    padding: 0;
    margin-bottom: 11px;
  }
  .html-offers-content .content-more {
    margin: 0;
  }
}
#OCS_DisneyEmotionalContent {
  background-color: #282629;
}
#OCS_DisneyEmotionalContent .white {
  color: #fff;
}
#OCS_DisneyEmotionalContent .content {
  margin-top: 766px;
  position: relative;
}
@media (max-width: 768px) {
  #OCS_DisneyEmotionalContent .content {
    margin-top: 111.5%;
  }
}
#OCS_DisneyEmotionalContent .title {
  padding-top: 0;
}
@media (max-width: 768px) {
  #OCS_DisneyEmotionalContent .title h1 {
    margin-bottom: 16px;
  }
  #OCS_DisneyEmotionalContent .title p {
    margin-bottom: 25px;
  }
}
#OCS_DisneyEmotionalContent .banner {
  position: absolute;
  width: 100%;
}
#OCS_DisneyEmotionalContent .banner .img-banner {
  max-width: -webkit-fill-available;
  width: 100%;
}

@media (min-width: 769px) {
  #OCS_DisneyEmotionalContent .banner .img-banner {
    max-width: 1067px;
  }
}
#OCS_DisneyEmotionalContent .banner .img-logo {
  max-width: 328px;
}
@media (min-width: 769px) {
  #OCS_DisneyEmotionalContent .banner .img-logo {
    max-width: 595px;
    position: relative;
    top: 40px;
  }
}
#OCS_DisneyEmotionalContent .banner-footer {
  margin-top: 50px;
}
#OCS_DisneyEmotionalContent .banner-footer h3 {
  margin-bottom: 8px;
  line-height: 32px;
}
@media (max-width: 768px) {
  #OCS_DisneyEmotionalContent .banner-footer h3 {
    margin-bottom: 16px;
    line-height: 24px;
  }
}
#OCS_DisneyEmotionalContent .banner-footer a {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  display: block;
  color: #80cfe9;
}
#OCS_DisneyEmotionalContent .btn-disney {
  background-color: #80cfe9;
  border-radius: 5px;
  color: #203840 !important;
  margin-top: 28px;
  margin-bottom: 56px;
  min-width: 150px;
  font-family: Roboto-Medium;
}
@media (max-width: 768px) {
  #OCS_DisneyEmotionalContent .btn-disney {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
#OCS_DisneyEmotionalContent .btn-disney:active {
  background-color: #002b53;
  color: #fff;
}
@media (max-width: 768px) {
  #OCS_DisneyEmotionalContent .dsk-title-s {
    font-size: 20px;
  }
  #OCS_DisneyEmotionalContent .dsk-title-black-sm {
    line-height: 32px;
  }
}
.mono-product-banner .banner-button-disney {
  border-radius: 5px !important;
  margin: auto;
  background-color: #80cfe9;
  color: #203840 !important;
  padding: 6px 16px !important;
  min-width: 216px;
}
@media (max-width: 768px) {
  .mono-product-banner .banner-button-disney {
    margin-left: 0;
    width: inherit;
  }
}
@media (max-width: 767.98px) {
  .mono-product-banner .no-offer .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media (max-width: 575.98px) {
  .mono-product-banner .no-offer .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.mono-product-banner .no-offer .alert.alert-no-offer {
  color: #555;
  border-radius: 2px;
  border: solid 1px #c33;
  background-color: #f9eaea;
  padding-top: 17px;
  padding-bottom: 19px;
  margin-top: 59px;
  margin-bottom: 56px;
}
.mono-product-banner .no-offer .alert.alert-no-offer .fa-times-circle {
  height: 50px !important;
}
@media (max-width: 767.98px) {
  .mono-product-banner .no-offer .alert.alert-no-offer {
    margin-top: 33px;
    margin-bottom: 29px;
  }
  .mono-product-banner .no-offer .alert.alert-no-offer .fa-times-circle {
    height: 70px !important;
    margin-right: 17px !important;
  }
  .mono-product-banner .no-offer .alert.alert-no-offer .dsk-body {
    padding-right: 15px !important;
  }
}
@media (max-width: 575.98px) {
  .mono-product-banner .no-offer .alert.alert-no-offer {
    margin-top: 33px;
    margin-bottom: 29px;
  }
  .mono-product-banner .no-offer .alert.alert-no-offer .fa-times-circle {
    height: 70px !important;
    margin-right: 17px !important;
  }
  .mono-product-banner .no-offer .alert.alert-no-offer .dsk-body {
    padding-right: 15px !important;
  }
}
.banner-button-disney:hover {
  background-color: #5dbee4 !important;
  border-color: #5dbee4 !important;
}
.banner-button-disney:active {
  background-color: #92a4c2 !important;
  border-color: #92a4c2 !important;
}
.section-product-detail-disney {
  padding-top: 56px;
  padding-bottom: 56px;
  width: 100%;
}
.section-product-detail-disney .title {
  color: #fff;
  padding-bottom: 16px;
}
.section-product-detail-disney .product-details {
  padding-top: 105px;
}
.section-product-detail-disney .product-details .box .icon {
  height: 67px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.section-product-detail-disney .product-details .box .icon img {
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.section-product-detail-disney .product-details .box .icon i {
  font-size: 48px;
  font-style: normal;
}
.section-product-detail-disney .product-details .box p {
  padding-top: 10px;
}
@media (max-width: 768px) {
  .section-product-detail-disney {
    padding-top: 23px;
    padding-bottom: 0;
  }
  .section-product-detail-disney .title {
    color: #f5f5f5;
    padding-bottom: 8px;
    font-size: 24px;
    line-height: 28px;
  }
  .section-product-detail-disney span {
    color: #ccc;
    line-height: 20px;
  }
  .section-product-detail-disney .product-details {
    padding-top: 56px;
  }
  .section-product-detail-disney .product-details .box {
    margin-bottom: 37px;
  }
  .section-product-detail-disney .product-details .box p {
    color: #f5f5f5;
    padding-top: 8px;
  }
}
.trailer-section-disney {
  padding-top: 56px;
  padding-bottom: 105px;
}
.trailer-section-disney .title h2 {
  color: #fff;
}
.trailer-section-disney .disney-separator {
  border-top: solid 2px #f5f5f5;
  margin-top: 7px;
  margin-bottom: 15px;
}
.trailer-section-disney .container-disney {
  padding-bottom: 56px;
}
.trailer-section-disney .container-disney .content .image-disney {
  width: fit-content;
  position: relative;
}
@media (min-width: 769px) and (max-width: 991.98px) {
  .trailer-section-disney .container-disney .content .image-disney {
    width: unset;
  }
  .trailer-section-disney .container-disney .content .image-disney a {
    font-size: 48px !important;
  }
}
.trailer-section-disney .container-disney .content .image-disney a {
  position: absolute;
  font-size: 70px;
  color: #ccc;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  text-decoration: none;
}
.trailer-section-disney .container-disney .content .image-disney a:active {
  box-shadow: none;
}
.trailer-section-disney .container-disney-2-box h3 {
  margin-right: -21px;
}
.trailer-section-disney .container-disney-2-box .content {
  padding-left: 20px;
}
.trailer-section-disney .container-disney-3 .content img {
  margin: auto;
}
@media (max-width: 768px) {
  .trailer-section-disney {
    height: auto;
    padding-top: 20px;
    padding-bottom: 69px;
  }
  .trailer-section-disney .disney-separator {
    margin-left: 16px;
  }
  .trailer-section-disney .container,
  .trailer-section-disney .container-disney,
  .trailer-section-disney .container-disney-2,
  .trailer-section-disney .container-disney-2-box,
  .trailer-section-disney .container-disney-3,
  .trailer-section-disney .info,
  .trailer-section-disney .info .description {
    padding: 0;
  }
  .trailer-section-disney .container .info .description {
    padding-bottom: 25px;
  }
  .trailer-section-disney .container-disney {
    padding-bottom: 56px;
  }
  .trailer-section-disney .container-disney .content .image-disney {
    margin: auto;
  }
  .trailer-section-disney .container-disney .content .image-disney a {
    font-size: 48px;
  }
  .trailer-section-disney .container-disney-2-box {
    padding-bottom: 56px;
  }
  .trailer-section-disney .container-disney-2-box .content img {
    margin: auto;
  }
  .trailer-section-disney .container-disney-3 .content {
    padding-left: 15px;
    padding-right: 15px;
  }
  .trailer-section-disney .info h3 {
    padding-left: 16px;
  }
}
@media (max-width: 768px) and (max-width: 768px) {
  .trailer-section-disney .title {
    padding: 0 15px 32px 15px;
  }
}
@media (min-width: 769px) and (max-width: 991.98px) {
  .trailer-section-disney .container-disney .image-disney a {
    font-size: 48px;
  }
  .trailer-section-disney .container-disney .image-disney img {
    margin: auto;
  }
  .trailer-section-disney .container-disney-2-box {
    padding-bottom: 42px;
  }
  .trailer-section-disney .container-disney-2-box .content img {
    margin: auto;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .trailer-section-disney .container-disney-2-box img,
  .trailer-section-disney .container-disney-3 img {
    width: 100% !important;
  }
  .trailer-section-disney .container-disney-3 .content {
    padding: inherit !important;
  }
}
.disney-header-container {
  padding: 36px 0;
}
@media (max-width: 768px) {
  .disney-header-container {
    padding: 24px 0;
  }
}
.disney-footer-container {
  color: #ccc;
  padding: 23px 0 21px 0;
}
.disney-footer-container .disney-footer-help i {
  padding-right: 6px;
}
@media (max-width: 768px) {
  .disney-footer-container .disney-footer-help {
    font-size: 12px;
  }
}
.disney-footer-container .disney-footer-trademark {
  line-height: 22px;
}
