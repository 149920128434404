#Offer .bannerPrincip {
    // padding-top: 49px;
    min-height: 300px;
    background-image: linear-gradient(to right, #0c1220, #7a1348);
    clear: both;
    background-image: inherit; 
    background-color: #1b1b1d; 
    padding-top: 53px;
}

#Offer .banner-title-left {
    color: #fff
}

#Offer .banner-title {
    font-family: Lato-Black;
    font-size: 40px;
    line-height: 48px
}

#Offer .banner-greeting-logged {
    font-family: Lato-Black;
    font-size: 32px;
    line-height: 40px
}

#Offer .banner-title-logged {
    font-family: Lato-Regular;
    font-size: 24px;
    line-height: 32px;
    margin: 16px 0 7px 0
}

#Offer .banner-discount-div {
    padding-bottom: 10px
}

#Offer .banner-discount-mobile {
    margin-right: -30px;
    margin-top: -80px
}

#Offer .banner-discount-mobile-2 {
    margin-right: -15px;
    margin-top: -30px
}

#Offer .banner-sale-price {
    font-family: Lato-Black;
    font-size: 58px;
    line-height: 79px
}

#Offer .banner-sale-price-logged {
    font-family: Lato-Black;
    font-size: 24px;
    line-height: 32px
}

#Offer .banner-list-price-div {
    font-size: 9px
}

#Offer .banner-list-price-div-2 {
    font-size: 12px
}

#Offer .banner-icon-text {
    font-family: Roboto-Medium;
    font-size: 14px;
    padding-top: 13px;
    color: #fff
}

#Offer .banner-little-text {
    font-family: Roboto-Regular;
    font-size: 14px;
    line-height: 15px
}

#Offer .banner-little-text-logged {
    font-family: Lato-Regular;
    font-size: 18px;
    line-height: 32px
}

#Offer .mini-banner-right {
    padding-top: 125px;
    padding-left: 100px;
    display: flex;
    justify-content: center
}

#Offer .icon-family {
    position: relative
}

#Offer .plus {
    position: relative;
    color: #fff;
    font-size: 20px
}

#Offer .banner-ico {
    font-size: 43px;
    color: #fff
}

#Offer .text-discount {
    font-family: Roboto-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.27;
    letter-spacing: normal;
    color: #fff
}

#Offer .big-discount {
    font-family: Lato-Black;
    font-size: 52px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.12;
    letter-spacing: normal;
    color: #fff
}

#Offer .previous-price {
    display: inline-block
}

#Offer .data-user-name {
    text-transform: capitalize
}

#Offer .js-product-icons-separator {
    margin-top: 15px
}

#Offer .logo-disney-app {
    width: 50px;
    margin-top: -18px
}

#Offer .disney-banner-combo-button {
    float: left;
    width: 150px
}

@media (max-width:768px) {
    #Offer .disney-banner-combo-button {
        display: block;
        width: 100%;
        margin-bottom: 57px
    }
}

#Offer .disney-banner-combo-button:active {
    border: solid 1px #555;
    background-color: rgba(255, 255, 255, 0.1)
}

#Offer .disney-banner-combo-button:hover {
    border: solid 1px #ccc;
    background-color: #282629
}

#Offer .disney-banner-combo-button:focus {
    border: solid 1px #ccc;
    background-color: #282629
}

@media (max-width:768px) {
    #Offer .logo-disney-app {
        width: 33px;
        margin-top: -4px
    }

    #Offer .bannerPrincip {
        padding-top: 35px;
        margin-top: -5px
    }

    #Offer .banner-title {
        font-size: 32px;
        line-height: 40px
    }

    #Offer .banner-greeting-logged {
        font-size: 24px;
        line-height: 28px
    }

    #Offer .banner-title-logged {
        font-size: 18px;
        line-height: 24px;
        margin: 20px 0 3px 0
    }

    #Offer .banner-sale-price {
        font-size: 48px;
        line-height: 56px
    }

    #Offer .banner-sale-price-logged {
        font-size: 48px;
        line-height: 56px
    }

    #Offer .banner-title-left {
        text-align: center
    }

    #Offer .mini-banner-right {
        padding-top: 20px
    }

    #Offer .banner-ico {
        font-size: 27.7px
    }

    #Offer .banner-ico-2 {
        font-size: 20px
    }

    #Offer .banner-icon-text {
        font-size: 9px;
        padding-top: 8px
    }
}