#login .loginTape {
    background: #225380;
    text-align: center;
    width: 100%
}

#login .loginTape .contentLogin {
    height: 45px;
    margin: 0 auto;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center
}

#login .loginTape .contentLogin .s-text .s-title,
#login .loginTape .contentLogin .s-login a,
#login .loginTape .contentLogin .s-descr,
#login .loginTape .contentLogin .s-bottom .s-close {
    font-family: Roboto-Regular;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #fff;
    background: none;
    border: none
}

#login .loginTape .contentLogin .s-text {
    width: 100%
}

#login .loginTape .contentLogin .s-text .s-title {
    font-family: Roboto-Bold
}

#login .loginTape .contentLogin .s-login a {
    text-decoration: underline;
    cursor: pointer
}

#login .loginTape .contentLogin .s-bottom .s-close {
    font-size: 16px
}

@media (max-width:768px) {
    #login .loginTape .contentLogin {
        width: 100%;
        text-align: left;
        padding: 0
    }

    #login .loginTape .contentLogin .s-text {
        width: 100%;
        padding-left: 16px
    }

    #login .loginTape .contentLogin .s-bottom .s-close {
        padding-right: 14px
    }

    #login .loginTape .contentLogin .s-descr.visible-xs {
        display: inline !important
    }
}