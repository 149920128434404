#header {
    height: 108px;
}

#header .fusion .navbar-default .navbar-nav > .active > a:focus,
#header .navbar-default .navbar-nav > .active > a:hover {
    color: #333;
}

#header .navbar.navbar-default.navbar-fixed-top.neotabs.fusion {
    height: 107px;
    box-shadow: 0 4px 4px 0 rgba(101, 101, 101, 0.2);
}

@media (max-width: 768px) {
    #header .navbar.navbar-default.navbar-fixed-top.neotabs.fusion {
        height: 96px;
    }
}

#header body .container.mega-menu.fusion,
#header .fusion #atencion-al-cliente {
    top: 65px;
}

#header .pos-relative {
    position: relative;
}

#header .pointer {
    cursor: pointer;
}

#header #mi-cuenta-2.mega-menu-productos.container-fluid.mega-menu.fusion.hidden-xs.neosub h2 {
    margin-top: 0;
    margin-left: 0;
    color: #333;
}

#header #mi-cuenta-2.mega-menu-productos.container-fluid.mega-menu.fusion.hidden-xs.neosub .in-box {
    margin-top: 62px;
}

#header #atencion-al-cliente-2 .container-help-menu {
    height: 392px;
}

#header #atencion-al-cliente-2 .container-help-menu h2 {
    margin: 0;
}

#header #atencion-al-cliente-2.fusion {
    background: no-repeat bottom right #fff;
    background-size: 280px;
    margin-top: 106px !important;
    min-height: 447px;
    padding: 0;
    height: auto !important;
}

#header #atencion-al-cliente-2 h4 {
    margin-top: 0;
}

#header #atencion-al-cliente-2 .help-container {
    background-color: #f5f5f5;
    height: 40px;
    position: relative;
    padding: 12px 0;
}

#header #atencion-al-cliente-2 .help-container a.help-center {
    color: #555;
    display: inline-block;
    width: 100%;
    text-align: center;
    line-height: 1.3;
}

#header #atencion-al-cliente-2 .help-container a.help-center:hover {
    color: #00b0ea;
}

#header
    #atencion-al-cliente-2
    .mega-menu-productos.container-fluid.mega-menu.fusion.hidden-xs.neosub
    .in-box {
    margin-top: 42px;
}

#header
    #atencion-al-cliente-2
    .mega-menu-productos.container-fluid.mega-menu.fusion.hidden-xs.neosub
    .in-box
    h2 {
    margin-left: 0;
    color: #333;
}

#header .neosub.mega-menu-productos .menuflex .productos-container-wrapper#container-personal h2 {
    height: auto;
}

#header .neosub.mega-menu-productos .menuflex .productos-container-wrapper h4 {
    color: #767676 !important;
    letter-spacing: normal;
    margin-bottom: 0;
    padding: 17px 0 0 6px;
    text-transform: uppercase;
}

#header .fusion {
    letter-spacing: normal;
}

#header .fusion .unbutton {
    background-color: transparent;
    border: none;
    padding: 0;
}

#header .fusion .unbutton:focus {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
}

#header .fusion .unbutton span:hover {
    font-family: Roboto-Medium;
    color: #00b0ea;
}

#header .fusion .container.menu-cont.second-nav {
    height: 53px;
    padding: 0;
}

#header .fusion nav.menu-top ul {
    float: left;
    opacity: 1;
    position: static;
    right: auto;
    right: initial;
    top: 62px;
    width: 50%;
    z-index: 1;
}

#header .fusion nav.menu-top .userdata {
    float: left;
    min-height: 260px;
    opacity: 1;
    padding: 40px 30px 26px 30px;
    position: static;
    right: auto;
    right: initial;
    text-align: center;
    width: 50%;
    z-index: 1;
    border-right: 1px solid #e6e6e6;
}

#header .fusion nav.menu-top .userdata p.to_date {
    padding: 0;
    margin: 0;
}

#header .fusion nav.menu-top .userdata p span.money {
    background: none;
    border: none;
    height: auto;
    height: initial;
    margin: 0;
    padding: 0;
    width: auto;
    width: initial;
    font-family: Lato;
    font-size: 18px;
    font-weight: bold;
}

#header .fusion nav.menu-top .userdata p:last-child {
    margin: 10px 0 0;
}

#header .fusion nav.menu-top .userdata .cv-btn {
    display: inline-block;
    width: 100%;
}

#header .fusion nav.menu-top .userdata span {
    display: block;
    margin: 0 auto 15px;
}

#header .fusion nav.menu-top .userdata span img {
    border-radius: 30px;
    border: 2px solid #00b0ea;
}

#header .fusion nav.menu-top .inner {
    background-color: #fff;
    border-radius: 2px;
    margin-top: -8px !important;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    width: 398px;
    z-index: 6;
}

#header .fusion nav.menu-top .inner ul {
    padding: 0 20px;
}

#header .fusion nav.menu-top .inner ul li a {
    color: #555;
    padding: 30px 0 0 15px;
    line-height: 16px;
}

#header .fusion nav.menu-top .inner:before {
    -webkit-transform: rotate(45deg);
    background: #fff;
    border-left: 1px solid #e74c3c;
    border-top: 1px solid #e74c3c;
    content: "";
    height: 13px;
    margin: auto;
    position: absolute;
    right: 22px;
    top: 4px;
    transform: rotate(45deg);
    width: 13px;
}

#header .fusion nav.menu-top ul.items-search {
    width: 100%;
}

#header .fusion nav.menu-top .inner.open {
    border: none;
    height: 263px;
    display: block;
    opacity: 1;
}

#header .fusion nav.menu-top .inner-wrapper {
    margin-top: 10px;
    border-radius: 2px;
    border: 1px solid #ccc;
    border-top: 1px solid #00b0ea;
    display: flow-root;
    background: #fff;
}

#header .fusion nav.menu-top ul.dropdown-menu-estasen {
    top: auto;
    top: initial;
    position: absolute;
    display: none;
}

#header .fusion nav.menu-top ul.dropdown-menu-estasen.open {
    display: block;
    width: 182px;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    right: 0;
    top: 34px;
}

#header .fusion nav.menu-top ul.dropdown-menu-estasen.open li a {
    background-color: #fff;
    color: #555;
    padding: 10px 20px;
    text-transform: none;
    text-transform: initial;
    text-decoration: none;
}

#header .fusion nav.menu-top ul.dropdown-menu-estasen.open li a:hover {
    color: #00b0ea;
}

#header .fusion nav.menu-top .imagedropdw {
    margin-left: 5px;
}

#header .fusion nav.menu-top.c-baja {
    border-top: 3px solid #1b1b1d;
    background-image: linear-gradient(to top, #9da3b7, #e4e4e4, #fcfcfc, #ffffff);
    height: initial;
    box-shadow: 0 1px 1px 0 rgba(101, 101, 101, 0.2);
    height: 53px;
}

#header .fusion nav.menu-top.c-baja .menu-top-name {
    color: #4a4a4a;
    padding-top: 19px;
    background: no-repeat right;
    padding-right: 0;
    width: auto;
}

#header .fusion nav.menu-top.c-baja .loggedIn-top-name {
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: 0 8px;
    margin-right: 0;
    width: initial;
    padding-right: 0;
}

#header .fusion nav.menu-top.c-baja .menu-top-name .name {
    border: none;
    position: relative;
    padding-right: 26px;
    text-transform: none;
    text-transform: initial;
}

#header .fusion nav.menu-top .buscar {
    float: right;
}

#header .fusion nav.menu-top .preh ul li a {
    background-color: transparent;
    text-transform: none;
    text-transform: initial;
}

#header .fusion nav.menu-top .preh ul li a:hover {
    text-decoration: underline;
    color: #00b0ea;
}

#header .fusion nav.menu-top .preh .inner ul {
    margin: 0;
    min-height: 260px;
    border-left: 1px solid #e6e6e6;
}

#header .fusion .cd-logo {
    width: 100%;
    height: 53px;
    overflow: auto;
    position: absolute;
}

@media (max-width: 768px) {
    #header .fusion .cd-logo {
        height: 50px;
    }
}

#header .fusion .cd-logo a {
    position: relative;
    display: block;
    margin-top: -2px;
    left: 36%;
    margin-left: 0;
    width: 325px;
}

#header .fusion .cd-logo a img {
    position: relative;
    display: block;
    // margin-top: 9px;
    margin-left: 0;
    cursor: pointer;
}

@media (max-width: 768px) {
    // #header .fusion .cd-logo a img {
    //     // margin-top: 6px;
    // }
}

#header .fusion .navbar-header .dropdown {
    float: left;
    top: 17px;
    cursor: pointer;
    letter-spacing: normal;
}

#header .fusion #search-form {
    margin-left: -40px;
}

#header .fusion.hover {
    right: 115px !important;
}

#header .fusion #search-input {
    display: none;
    background: #fff;
}

#header .fusion .hover #search-input {
    display: block;
    text-transform: none;
    text-transform: initial;
    font-size: 14px;
    padding-bottom: 10px;
}

#header .fusion.neotabs .navbar-nav.nav-nivel1 {
    margin: 0;
    display: block;
    height: 56px;
}

#header .fusion.neotabs .navbar-nav.nav-nivel1 li a {
    padding: 18px 16px 0 16px;
    height: 52px;
}

#header .fusion.neotabs .navbar-nav.nav-nivel1 li a img {
    width: 20px;
    padding: 0 5px;
}

#header .fusion.neotabs .navbar-nav.nav-nivel1 li a::after {
    display: block;
    width: 0;
    height: 3px;
    content: "";
    background: #00b0ea;
    transition: width 0.3s;
    margin-top: 12px;
}

#header .fusion.neotabs .navbar-nav.nav-nivel1 li a:hover,
#header .fusion.neotabs .navbar-nav.nav-nivel1 li.active a {
    background: none;
    color: #333;
}

#header .fusion.neotabs .navbar-nav.nav-nivel1 li a:hover::after,
#header .fusion.neotabs .navbar-nav.nav-nivel1 li.active a::after {
    width: 100%;
}

#header .fusion.neotabs .navbar-collapse.collapse.navbar-left {
    margin-left: 30%;
}

#header .fusion #localidad-btn {
    padding-top: 18px;
    padding-bottom: 10px;
    padding-right: 0;
    text-align: right;
    background-position: 24px;
    font-size: 12px;
    font-family: Roboto-Regular;
}

#header .fusion #localidad-btn i {
    font-size: 16px;
    padding-right: 9px;
    vertical-align: -2px;
}

#header .fusion #localidad-btn:hover {
    color: #00b0ea;
    font-family: Roboto-Medium;
}

#header .fusion .buscar-top {
    padding-top: 17px;
    float: right;
    position: relative;
    width: auto;
    margin-right: 10px;
}

#header .fusion.not-logged .search-form-top {
    margin-right: 14px;
}

#header .fusion.not-logged .c-baja .menu-top-name {
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: 0 8px;
    margin-right: 0;
    width: initial;
    padding-right: 0;
}

#header .fusion.not-logged .c-baja .menu-top-name .name {
    width: auto;
    width: initial;
    height: 20px;
    padding-left: 0;
    padding-right: 0 !important;
    color: #555;
    line-height: 16px;
}

#header .fusion.not-logged .c-baja .menu-top-name:hover {
    background-repeat: no-repeat;
}

#header .fusion.not-logged .c-baja .menu-top-name:not(.logged) .name:hover,
#header .fusion.not-logged .c-baja .menu-top-name:not(.logged) :hover,
#header .fusion.not-logged .c-baja .menu-top-name:not(.logged) p:hover {
    color: #00b0ea !important;
}

#header .fusion .search-form-top {
    float: right;
    width: auto;
}

#header .fusion .search-form-top #search-form-top {
    position: relative;
    margin-bottom: 14px;
    width: auto;
    cursor: pointer;
}

#header .fusion .search-form-top #search-form-top.hover {
    width: 240px;
    float: left;
    margin-bottom: 6px;
}

#header .fusion .search-form-top #search-form-top.hover p.text-search {
    display: none;
}

#header .fusion .search-form-top #search-form-top.hover #search-icon {
    top: 2px;
}

#header .fusion .search-form-top #search-form-top.hover .close {
    display: block;
}

#header .fusion .search-form-top #search-form-top p.text-search {
    line-height: 16px;
    margin: 0;
    margin-top: 2px;
    text-align: right;
    color: #555;
}

#header .fusion .search-form-top #search-form-top #search-icon {
    left: 8px;
    top: -8px;
}

#header .fusion .search-form-top #search-form-top #search-input {
    margin-top: -10px;
    border: none;
    outline: none;
    box-shadow: none;
    padding-top: 10px;
    margin-bottom: -4px;
}

#header .fusion .search-form-top #search-form-top ul.items-search {
    position: absolute;
    top: 40px;
    z-index: 7;
}

#header .fusion .search-form-top #search-form-top ul.items-search li a {
    background: #fff;
    color: #555;
    text-transform: none;
    text-transform: initial;
}

#header .fusion .search-form-top #search-form-top ul.items-search li a:hover,
#header .fusion .search-form-top #search-form-top ul.items-search li a:hover span {
    color: #dd3520;
}

#header .fusion .search-form-top #search-form-top .close {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 13px;
    right: -9px;
    background-repeat: no-repeat;
    background-size: 15px 15px;
    display: none;
    opacity: 1;
}

#header .fusion .search-form .form-group.hover {
    height: 46px;
}

#header .fusion .search-form .form-group.hover input.form-control {
    height: 46px;
}

#header .fusion #localidad-icon {
    top: 10px;
}

#header .fusion .items-localidad {
    top: 53px;
    margin-top: 2px;
    background-color: #fff;
}

#header .fusion .items-localidad li.result,
#header .fusion .items-map li.result,
#header .fusion .items-search li.result {
    background-color: #fff;
    padding: 5px 0 5px 45px;
}

#header .fusion .c-baja .menu-top-name {
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: 23px 6px;
    margin-right: -1%;
}

#header .fusion .menu-top-name img.arro {
    position: absolute;
    right: 7px;
    top: 5px;
    width: 17px;
}

#header .fusion .menu-top-name img.arro.tranff {
    -webkit-transform: rotate(180deg);
    right: 12px;
    transform: rotate(180deg);
}

#header .fusion .menu-top-name p {
    color: #555;
    display: inline-block;
    line-height: 16px;
    margin: 0;
    text-transform: initial;
    text-transform: none;
}

#header .fusion .in-box.container .row {
    position: relative;
}

#header .fusion .in-box {
    padding: 0;
    padding-top: 61px;
}

@media (max-width: 768px) {
    #header .fusion .in-box {
        padding-top: 0;
    }
}

#header .fusion .in-box .title-padding {
    padding-left: 15px;
    margin: 0;
}

#header .fusion .in-box h4 {
    color: #888;
    margin: 16px 0;
    text-transform: uppercase;
}

#header .fusion .in-box p {
    margin: 0;
    padding: 17px 0 33px;
}

#header .fusion .in-box a {
    color: #4a4a4a;
    display: block;
    display: inline;
    margin-top: 25px;
}

#header .fusion .in-box a:hover {
    color: #00b0ea;
    display: inline;
}

#header .fusion .in-box .extra {
    border-top: 1px solid #ccc;
    margin-top: 40px;
    padding-top: 35px;
    width: 160%;
}

#header .fusion .in-box .col-combos a span {
    border-bottom: 1px solid #fff;
    clear: both;
    display: inline-block;
    float: left;
    letter-spacing: normal;
    margin: 16px 0;
    padding: 0;
}

#header .fusion .in-box .soft + .soft {
    margin-top: 10px;
}

#header .fusion .in-box .soft span {
    color: #888;
    font-size: 16px;
}

#header .fusion .in-box .col-side {
    background-color: transparent;
    height: 366px;
    right: 0;
    top: 28px;
}

@media (min-width: 768px) {
    #header .fusion .in-box .col-side {
        position: absolute;
        float: right;
        width: 23%;
    }
}

#header .fusion .in-box .col-side a {
    height: auto;
    height: initial;
}

#header .fusion .in-box .col-side a img {
    background-color: #ccc;
    display: block;
    max-width: 100%;
}

#header .fusion .in-box h3 {
    margin: 30px 0;
}

@media (min-width: 768px) {
    #header .fusion .in-box .col-combos {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        float: left;
        padding-bottom: 30px;
        width: 75%;
    }
}

#header .fusion .in-box .col-combos .comb.without-h4 {
    display: block;
    margin-top: 36px;
}

#header .fusion .in-box .col-combos img.gplay {
    float: left;
    height: 44px;
    max-width: 144px;
    width: calc(45%);
}

#header .fusion .in-box .col-combos img.astore {
    float: left;
    height: 44px;
    margin-left: 10px;
    max-width: 144px;
    width: calc(45%);
}

@media (max-width: 768px) {
    #header .fusion .in-box .col-combos img.astore {
        margin-left: 6px;
    }
}

#header .fusion .in-box .col-combos .col-combos {
    -ms-flex: 1;
    -webkit-box-flex: 1;
    flex: 1;
    margin-top: 0;
}

#header .fusion .in-box .col-combos .col-combos .col-md-3 {
    padding: 0;
}

#header .fusion .in-box .col-combos .col-combos .col-md-4 {
    padding: 0;
}

#header .fusion .in-box .col-combos .col-combos p {
    color: #555;
}

#header .fusion .in-box .col-combos.large {
    padding-top: 30px;
    width: 100%;
}

#header .fusion .in-box .col-combos.large .bloque-items {
    display: flow-root;
}

#header .fusion .estasen {
    padding: 15px 10px 0 0;
    width: 320px;
}

#header .fusion .estasen span {
    color: #555;
    float: left;
    font-size: 14px;
    margin-top: -1px;
    padding-right: 5px;
}

#header .fusion .estasen ul {
    background-color: #fff;
}

#header .fusion .estasen ul li.first a {
    padding: 0;
}

#header .fusion .estasen ul li a {
    color: #555;
    padding: 10px;
}

#header .fusion .estasen li:hover {
    background-color: #fff;
}

#header .fusion .estasen .caret {
    float: right;
    margin-top: -8px;
}

#header .fusion .title-wrapper {
    margin-bottom: 0;
    margin-top: 44px;
    padding-left: 15px;
    position: relative;
}

#header .fusion .title-wrapper.line {
    margin-top: 0;
    padding-left: 1px;
    margin-left: 15px;
}

#header .fusion .title-wrapper:before {
    border-radius: 15px;
    content: "";
    height: 100%;
    left: -5px;
    position: absolute;
    top: 0;
    width: 6px;
}

#header .fusion .title-wrapper.line:before {
    left: 0;
    height: 37px;
}

#header .fusion .title-wrapper.cablevisión:before {
    background: #57dfb7;
}

#header .fusion .title-wrapper.personal:before {
    background: #00b0ea;
}

#header .fusion .title-wrapper.fibertel:before {
    background: #225380;
}

#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper .title-wrapper h4 {
    margin-bottom: 0;
    margin: 0;
    padding: 0;
    height: 37px;
    text-transform: none !important;
    color: #333 !important;
}

#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper .title-wrapper h2 {
    margin-top: 10px;
    line-height: 1;
}

#header .fusion .neomobile .productos-item-cucarda {
    display: inline-block;
    padding-left: 3px;
}

#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper .productos-item-bold a {
    font-family: Roboto-Medium;
}

#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper .productos-item-cucarda {
    display: inline-block;
    margin-left: 3px;
}

#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper .productos-item a {
    border-bottom: 1px solid #fff;
    display: inline;
    letter-spacing: normal;
    padding-left: 0;
}

#header
    .fusion.mega-menu-productos
    .menuflex
    .productos-container-wrapper
    .productos-item
    a.cyber-monday {
    border-radius: 6px;
    border: 2px solid #30007e;
    display: inline;
    padding: 6px;
    position: relative;
}

#header
    .fusion.mega-menu-productos
    .menuflex
    .productos-container-wrapper
    .productos-item
    a.cyber-monday:before {
    background: #fff;
    color: #30007e;
    content: "Cyber Monday";
    font-family: Lato-Bold;
    font-size: 11px;
    left: 5px;
    left: auto;
    position: absolute;
    top: -13px;
}

#header
    .fusion.mega-menu-productos
    .menuflex
    .productos-container-wrapper
    .productos-item
    a.cyber-monday:hover {
    border-bottom: 2px solid #30007e;
}

#header .fusion.mega-menu-productos .cablevisión-section {
    border: none;
    height: 100%;
    padding: 0;
    width: 25%;
}

#header .fusion.mega-menu-productos .fibertel-section {
    border: none;
    height: 100%;
    padding: 0;
    width: 25%;
}

#header .fusion.mega-menu-productos .personal-section {
    border: none;
    height: 100%;
    padding: 0;
    width: 25%;
}

#header .fusion.mega-menu-productos .banner-container {
    background-color: transparent;
    height: 366px;
    margin-top: 40px;
    width: 100%;
}

#header .fusion.mega-menu-productos .banner-container img.banner {
    max-width: 100%;
}

#header .fusion .neosub.mega-menu-productos .menuflex .productos-container-wrapper h2 {
    letter-spacing: normal;
    margin: 0;
    padding: 0;
}

#header .fusion#mega-menu-productos .menuflex .productos-container-wrapper .productos-item {
    margin: 0;
    padding: 27px 0 0 5px;
}

#header .fusion.mega-menu-productos .menuflex {
    height: 100%;
}

#header .fusion.mega-menu-productos .cablevisión-section h4,
#header .fusion.mega-menu-productos .cablevisión-section .productos-container .productos-item a,
#header .fusion.mega-menu-productos .cablevisión-section:hover h4,
#header
    .fusion.mega-menu-productos
    .cablevisión-section:hover
    .productos-container
    .productos-item
    a,
#header .fusion.mega-menu-productos .fibertel-section h4,
#header .fusion.mega-menu-productos .fibertel-section .productos-container .productos-item a,
#header .fusion.mega-menu-productos .fibertel-section:hover h4,
#header .fusion.mega-menu-productos .fibertel-section:hover .productos-container .productos-item a,
#header .fusion.mega-menu-productos .personal-section h4,
#header .fusion.mega-menu-productos .personal-section .productos-container .productos-item a,
#header .fusion.mega-menu-productos .personal-section:hover h4,
#header .fusion.mega-menu-productos .personal-section:hover .productos-container .productos-item a,
#header
    .fusion.mega-menu-productos
    .menuflex
    .productos-container-wrapper
    .productos-item-clasico
    a,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper .productos-item-hd a,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper .productos-item-50m a,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper .productos-item-100m a,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper .productos-item-500m a,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper .productos-item-7gb a,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper .productos-item-4gb a,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper .productos-item-2gb a {
    color: #555 !important;
}

#header .fusion.mega-menu-productos .cablevisión-section {
    background-color: transparent;
}

#header .fusion.mega-menu-productos .cablevisión-section h2 {
    color: #00b0ea !important;
}

#header .fusion.mega-menu-productos .cablevisión-section .productos-item-secondary {
    border-top: 1px solid #ccc;
}

#header .fusion.mega-menu-productos .cablevisión-section:hover {
    background-color: #fff;
}

#header .fusion.mega-menu-productos .cablevisión-section:hover h2 {
    color: #00b0ea !important;
}

#header
    .fusion.mega-menu-productos
    .cablevisión-section:hover
    .productos-container
    .productos-item
    a {
    opacity: 1;
}

#header .fusion.mega-menu-productos .fibertel-section {
    background-color: transparent;
}

#header .fusion.mega-menu-productos .fibertel-section h2 {
    color: #225380 !important;
}

#header .fusion.mega-menu-productos .fibertel-section:hover {
    background-color: #fff;
}

#header .fusion.mega-menu-productos .fibertel-section:hover h2 {
    color: #225380 !important;
}

#header .fusion.mega-menu-productos .fibertel-section:hover .productos-container .productos-item a {
    opacity: 1;
}

#header .fusion.mega-menu-productos .personal-section {
    background-color: transparent;
}

#header .fusion.mega-menu-productos .personal-section h2 {
    color: #00b0ea !important;
}

#header .fusion.mega-menu-productos .personal-section:hover {
    background-color: #fff;
}

#header .fusion.mega-menu-productos .personal-section:hover h2 {
    color: #00b0ea !important;
}

#header .fusion.mega-menu-productos .personal-section:hover .productos-container .productos-item a {
    opacity: 1;
}

#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper.flow,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper.clasico,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper.digital,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper.hd,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper.productos-3m,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper.productos-6m,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper.productos-12m,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper.productos-25m,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper.productos-50m,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper.productos-100m,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper.productos-pblack,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper.productos-p10gbb,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper.productos-p10gb,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper.productos-p7gb,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper.productos-p4gb,
#header .fusion.mega-menu-productos .menuflex .productos-container-wrapper.productos-p2gb {
    background: none;
}

#header
    .fusion
    .mega-menu-productos.container-fluid.mega-menu.fusion.prog-menu.hidden-xs.neosub.open
    .row.cont {
    height: 100%;
}

#header #mega-menu-productos.fusion,
#header #mi-cuenta-2.fusion {
    background: no-repeat bottom right #fff;
    background-size: 280px;
    margin-top: 106px !important;
    min-height: 447px;
    padding: 0;
    padding-bottom: 37px;
}

#header .fusion.neosub.mega-menu-productos .productos-container-wrapper .bottom-hover {
    display: none;
    position: absolute;
    bottom: 0;
    border-radius: 15px 15px 0 0;
    height: 10px;
    width: 80%;
}

#header .fusion.neosub.mega-menu-productos .productos-container-wrapper:hover .bottom-hover {
    display: block;
}

#header .fusion.neosub.mega-menu-productos .menuflex .productos-container-wrapper {
    position: relative;
}

#header .fusion.neosub.mega-menu-productos .menuflex .productos-container-wrapper .menu-details {
    padding: 0 0 0 9px;
}

#header .fusion.mega-menu-productos .fibertel-section .productos-container .productos-item a:hover {
    color: #225380 !important;
    font-family: Roboto-Medium;
}

#header
    .fusion.mega-menu-productos
    .cablevisión-section
    .productos-container
    .productos-item
    a:hover {
    color: #57dfb7 !important;
    font-family: Roboto-Medium;
}

#header .fusion.mega-menu-productos .personal-section .productos-container .productos-item a:hover {
    color: #00b0ea !important;
    font-family: Roboto-Medium;
}

#header
    .fusion.mega-menu-productos
    .personal-section
    .productos-container
    .productos-item.productos-item-black
    a:hover {
    color: #00b0ea !important;
    font-family: Roboto-Medium;
}

#header
    .fusion.mega-menu-productos
    .cablevisión-section
    .productos-container
    .productos-item.productos-item-flow
    a:hover {
    color: #57dfb7 !important;
    font-family: Roboto-Medium;
}

#header .fusion .menu-top-name img {
    padding-right: 5px;
}

#header .fusion .form-group.hover #search-icon,
#header .form-group.hover #localidad-icon {
    top: 10px;
    background-size: 16px 20px;
}

#header .fusion #search-icon {
    left: 77px;
    background-size: 16px;
    top: 12px;
}

#header .fusion .buscar p.text-search {
    position: relative;
    top: 17px;
    left: 100px;
}

#header .fusion .buscar .hover p.text-search {
    display: none;
}

#header .fusion.not-logged .buscar p.text-search {
    left: 445%;
}

#header .fusion #search-form.hover .fusion #search-form.hover p.text-search {
    display: none;
}

#header .fusion #search-form {
    max-width: 262px;
    right: 24%;
    margin-left: -298px;
    height: 46px;
}

#header .see-invoice {
    font-size: 14px;
    font-family: Roboto-Medium;
    font-weight: 500;
    color: #00b0ea;
}

#header .fusion .userdata a.budget {
    font-size: 14px;
    font-family: Roboto-Medium;
    font-weight: 500;
    color: #00b0ea;
    text-transform: uppercase;
    margin-left: 5px;
}

#header .fusion .userdata a.budget i {
    margin-left: 5px;
}

#header .fusion .navbar-nav > li a {
    color: #333;
}

#header .back-navigation-programacion a {
    padding-top: 19px !important;
}

#header .fusion.not-logged #search-icon {
    left: 370%;
}

#header .fusion.not-logged #search-form {
    right: 174px;
}

#header .fusion.not-logged #search-form.hover {
    height: 46px;
    background-color: #fff;
    border: none;
}

#header .fusion .estasen ul li.first a img {
    right: 8px;
    top: 10px;
    width: 9px;
}

#header .fusion.neotabs .navbar-nav.nav-nivel1 li.active a img {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

#header .fusion.neotabs .navbar-nav.nav-nivel1 li.active a i {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

#header .fusion.neotabs .navbar-nav.nav-nivel1 .menu-top-name .name i.fa-angle-down {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

#header .content-mobile-localidades {
    height: 30px;
}

#header .hover #localidad-icon {
    position: absolute;
    top: 5px;
    left: -1px;
    padding: 3px 3px;
}

#header .fusion .cucarda-destaque .text {
    vertical-align: top;
}

#header
    .fusion
    .content-mobile-localidades
    ul.nav.navbar-nav.nav-nivel1.menu-white.hidden-lg::before {
    width: 20px;
    height: 20px;
    position: absolute;
    background-size: 18px;
    background-repeat: no-repeat;
    top: 11px;
    left: 15px;
    padding: 2px 3px;
}

#header a#localidad-btn {
    padding-left: 45px;
    padding-right: 16px;
}

#header .navbar-default .navbar-nav.menu-white > li > a {
    font-size: 14px;
    line-height: 10px;
}

#header .navbar-nav.menu-white .localidad {
    padding-left: 10px;
    background-repeat: no-repeat;
    background-position: right;
    padding-left: none;
    margin-right: none;
    background-size: 12px;
}

#header #localidad-menu-mobile {
    background-color: #fff;
}

#header #localidad-menu-mobile .back-navigation-localidad {
    background-color: #fff;
    background-size: 10px !important;
    background-position: 15px 50% !important;
    height: 41px;
    float: left;
}

#header .fusion #localidad-menu-mobile li {
    padding: 5px 20px;
}

#header .fusion #localidad-menu-mobile li p {
    margin: 0;
    color: #333;
}

#header .fusion #localidad-menu-mobile li span {
    color: 14px;
}

#header #localidad-input-mobile {
    display: block;
    float: left;
    border: none;
    border-bottom: 1px solid #eee;
    margin-top: 50px;
    padding-left: 30px;
    width: 100%;
    box-shadow: none;
    border-radius: 0;
}

#header #localidad-input-mobile:focus {
    border-bottom: 1px solid #002b53;
}

#header .fusion .search-form .form-group {
    width: auto;
}

#header .fusion .search-form .form-group.hover input.form-control,
#header .fusion .search-form .form-group.hover {
    background-color: #fff;
    left: auto;
    left: initial;
    text-transform: none;
    text-transform: initial;
    right: 0;
    color: #888;
}

#header .fusion .navbar-right .form-group.hover .close {
    right: 0;
}

#header .fusion .items-localidad a,
#header .fusion .items-map a,
#header .items-search a {
    padding-top: 5px;
    padding-bottom: 10px;
}

#header .fusion .items-localidad a:hover,
#header .fusion .items-map a:hover,
#header .fusion .items-search a:hover,
#header .fusion .items-localidad li.result a:hover span,
#header .fusion .items-localidad li.result a:hover,
#header .fusion .items-map li.result a:hover,
#header .fusion .items-search li.result a:hover {
    background: #fff;
    color: #dd3520;
}

#header .ropts {
    background-color: #fff;
    border: 1px solid #e4e4e4;
    position: absolute;
    z-index: 6;
    top: 34px;
    width: 150px;
}

#header .ropts a {
    display: block;
    padding: 12px 15px 0;
    text-align: left;
    width: 100%;
    color: #444;
    height: 40px;
    position: relative;
    z-index: 1;
}

#header .ropts a.tv:hover {
    color: #00b0ea;
}

#header .ropts a.it:hover {
    color: #225380;
}

#header .ropts a span {
    float: right;
    width: 13px;
    height: 13px;
    background-repeat: no-repeat;
    margin-top: 1px;
}

#header .ropts .triag + a {
    border-bottom: 1px solid #e4e4e4;
}

#header .ropts .triag {
    position: absolute;
    top: -10px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid #fff;
    left: 43%;
}

#header .ropts a img {
    display: none;
}

#header .cuenta-rebrand .opti {
    padding: 40px 90px;
}

#header .cuenta-rebrand img {
    float: left;
    width: 35px;
    margin-right: 20px;
    margin-top: -3px;
}

#header .cuenta-rebrand img.hov {
    display: none;
}

#header .cuenta-rebrand img.arr {
    float: right;
    width: 20px;
    margin-top: 5px;
}

#header .cuenta-rebrand span {
    float: left;
    font-size: 18px;
    color: #444;
}

#header .cuenta-rebrand .opti:first-child::after {
    content: " ";
    position: absolute;
    width: 1px;
    background-color: #ccc;
    height: 40px;
    right: 0;
    top: 35px;
}

#header .cuenta-rebrand .opti:hover span.hover {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #00b0ea;
}

#header .cuenta-rebrand .opti.it:hover span.hover {
    background-color: #225380;
}

#header .cuenta-rebrand .opti:hover img {
    display: none;
}

#header .cuenta-rebrand .opti:hover img.arr,
#header .cuenta-rebrand .opti:hover img.hov {
    display: block;
}

#header .nav-nivel2 {
    display: none;
    height: 100vh;
}

#header .navbar-nav {
    float: left;
    margin: 0;
    position: relative;
}

#header .navbar-nav > li.localidad {
    position: static;
}

#header .navbar-nav > li.localidad a {
    color: #888;
}

#header .search-form {
    z-index: 0;
}

#header .search-form .form-group {
    float: right !important;
    transition: width 0.25s, right 0.25s, background 0.25s;
    width: 32px;
    height: 55px;
    background-color: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    border-radius: 25px;
    border: 1px solid #ccc;
    position: absolute;
    top: 0;
    border-radius: 0;
    border: none;
    background: none;
    left: 0;
    box-shadow: none;
}

#header a#localidad-btn {
    z-index: 4;
}

#header .search-form-container {
    width: 232px;
    display: none;
}

#header .search-form-container .search-form {
    position: relative;
    height: 55px;
}

#header .search-form-container .search-form #localidad-form {
    width: 100%;
    top: 2px;
    display: flex;
    justify-content: flex-end;
}

#header .search-form-container .location-list-container {
    background: #fff;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    border-bottom: 32px solid #fff;
    padding: 32px 22px 0;
    height: 190px;
    width: 232px;
    overflow: auto;
}

#header .search-form-container .location-list-container li {
    padding-bottom: 32px;
}

#header .search-form-container .location-list-container li :hover {
    color: #00b0ea;
}

#header .input-box-shadow {
    box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.1) !important;
    position: relative;
    top: 4px;
    width: 232px;
}

#header .mobile-search-form-container .localidad-form-mobile li :hover {
    color: #00b0ea;
}

#header .localidad-desktop .search-form .fa-location-circle,
#header .localidad-desktop .search-form .fa-times {
    padding-top: 19px;
    margin-right: 9px;
    padding-right: 0;
    display: none;
    font-size: 16px;
}

#header #localidad-dsk-input {
    display: none;
    visibility: inherit;
    padding: 0;
    float: left;
    padding: 0 8px;
    letter-spacing: 0;
    font-size: 12px;
    font-family: Roboto-Regular;
    margin-right: 8px;
    margin-top: 10px;
    height: 30px;
    background-color: #f5f5f5;
}

#header #search-btn-mobile {
    color: #555 !important;
}

#header .search-form .form-group input.form-control {
    padding-right: 20px;
    border: 0 none;
    background: transparent;
    box-shadow: none;
    height: 55px;
    display: block;
    letter-spacing: 0.8px;
    width: 85px;
    padding: 0 0 0 85px;
}

#header .search-form .form-group.hover input.form-control {
    width: 100%;
    padding: 0 20px 0 45px;
    height: 56px;
    display: block;
}

#header .search-form .form-group input.form-control {
    padding-right: 20px;
    border: 0 none;
    background: transparent;
    box-shadow: none;
    height: 55px;
    display: block;
    letter-spacing: 0.8px;
    width: 85px;
    padding: 0 0 0 85px;
}

#header .mega-menu-productos {
    display: none;
    transition: opacity 0.3s ease;
    margin-top: -55px;
    opacity: 0;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    left: 0;
    opacity: 0.8;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

#header .mega-menu-productos .menuflex .productos-container-wrapper .productos-item {
    text-align: center;
    cursor: pointer;
    display: inline-block;
    flex: 0;
    width: 100%;
    -webkit-box-flex: 0;
    text-align: left;
    margin-bottom: 0.3rem;
    margin-top: 0.3rem;
}

#header ol,
#header ul {
    list-style: none;
    padding: 0;
}

#header a:link {
    text-decoration: none;
}

#header .c-baja .menu-top-name {
    display: inline-block;
    width: 279px;
    float: right;
    padding: 7px 28px 7px 0;
}

#header .menu-top-name {
    padding-right: 28px;
}

#header .menu-top-name {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.07;
    color: #fff;
    text-align: right;
    padding: 8px 15px;
}

#header nav.menu-top ul li a {
    display: block;
    width: 100%;
    background: #4a4a4a no-repeat 12px center;
    background-size: 16px;
    text-transform: uppercase;
    padding: 10px 0 10px 39px;
    color: #fff;
    cursor: pointer;
    transition: background-color 250ms linear 0s;
}

#header .container.preh {
    top: 0;
}

#header .container.preh {
    padding: 0;
    position: relative;
    top: -2px;
}

#header .container > .navbar-collapse.collapse.navbar-right .navbar-nav {
    float: right;
    position: static;
}

#header a#localidad-btn {
    padding-left: 45px;
    padding-right: 16px;
}

#header .navbar-collapse.collapse.navbar-left {
    margin-left: 80px;
}

#header .navbar-nav > li > a {
    padding-top: 10px;
    padding-right: 0;
}

#header .navbar-default {
    background-color: #fff;
    border: none;
    box-shadow: none;
    border-bottom: solid 1px #ecede9;
}

#header #menu-shade {
    z-index: 10;
    display: none;
    transition: opacity 0.6s linear 0s, z-index 0s linear 0.6s;
    right: 0;
}

#header #menu-shade,
#header .modal .shadow {
    position: fixed;
    background-color: rgba(60, 60, 60, 0.9);
    width: 100%;
    height: 100%;
    top: 0;
}

#header .neotabs .container > .navbar-collapse.collapse.navbar-right {
    width: 225px;
    position: relative;
}

#header .neotabs .container > .navbar-collapse.collapse.navbar-right .navbar-nav {
    margin: 0;
}

#header a#localidad-btn {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

#header .fusion .search-form .form-group {
    width: auto;
}

#header .collapse input#localidad-input {
    visibility: visible;
}

#header .no-padding {
    padding: 0;
}

#header .no-margin {
    margin: 0;
}

#header .float-right {
    float: right;
}

#header .float-left {
    float: left;
}

#header body.openmenu {
    overflow: hidden;
}

#header .loggedIn-account-info {
    font-size: 48px;
    border: solid 2px #00b0ea;
    border-radius: 50%;
    background-color: #e0e0e0;
    color: #fff;
    line-height: 1;
}

#header .hello {
    padding: 13px 0 36px 0;
    margin: 0;
}

@media (max-width: 768px) {
    #header #atencion-al-cliente-mobile .help-center {
        text-align: center;
        background-color: #f5f5f5;
        margin-bottom: 80x;
        padding: 15px 22px;
    }

    #header .title-menu-open {
        margin-left: -28px;
    }

    #header .c-baja .menu-top-name.ingresar {
        float: none;
        width: 100%;
    }

    #header .c-baja .menu-top-name.ingresar .name {
        width: 100%;
    }

    #header .ropts {
        left: 0;
        width: 100%;
        border: none;
    }

    #header .ropts .triag {
        display: none;
    }

    #header .ropts a {
        position: relative;
        z-index: 1;
        width: 50%;
        float: left;
        height: 86px;
        padding-top: 23px;
        background-color: #595959;
        color: #fff;
        text-align: center;
    }

    #header .ropts .triag + a {
        border-right: 1px solid #fff;
        border-bottom: none;
    }

    #header .ropts a:hover,
    #header .ropts a:active {
        color: #595959 !important;
        background-color: #fff;
    }

    #header .ropts a img {
        display: block;
        margin: 0 auto 5px;
        padding: 0;
    }

    #header .ropts a img.active {
        display: none;
    }

    #header .ropts a:hover img,
    #header .ropts a:active img {
        display: none;
    }

    #header .ropts a:hover img.active,
    #header .ropts a:active img.active {
        display: block;
    }

    #header .neotabs.navbar-default #micuenta-menu-mobile.navbar-nav > li > a img {
        margin-right: 15px;
    }

    #header .neotabs.navbar-default #micuenta-menu-mobile.navbar-nav > li {
        background-color: #e5e7e2;
    }

    #header .neotabs.navbar-default #micuenta-menu-mobile.navbar-nav > li:first-child {
        padding-left: 40px;
        background-position: 22px 50% !important;
    }

    #header .fusion #ayuda h2 {
        font-size: 24px;
    }

    #header .fusion.neotabs .estasen {
        position: relative;
        bottom: 4%;
        width: 100%;
    }

    #header .fusion.neotabs .navbar-nav.nav-nivel1 {
        height: 55px;
        width: 100%;
    }

    #header .fusion.neotabs .navbar-nav.nav-nivel1 li a {
        padding: 15px 30px 15px 20px;
    }

    #header .submenu.menuopen {
        opacity: 1;
        display: block !important;
        position: absolute;
        left: 0;
    }

    #header #navbar {
        display: none;
    }

    #header #navbar .menu-white {
        display: none !important;
    }

    #header .submenu {
        opacity: 0;
        display: none;
        transition: top 0.3s, opacity 0.3s;
    }

    #header .fusion .side-menu li a,
    #header .side-menu .current a {
        background-color: #fff;
        padding-left: 20px;
    }

    #header .fusion .navbar-collapse.collapse.navbar-left {
        position: absolute;
        background: #f5f5f5;
        width: 100%;
        left: 0;
        margin: 0;
        overflow: auto !important;
        min-height: 92vh !important;
    }

    #header .navbar-collapse.navbar-right .buscar,
    #header .navbar-collapse.navbar-right .ingresar,
    #header .navbar-collapse.navbar-right .localidad {
        display: none;
    }

    #header .navbar-nav {
        background: #f5f5f5;
    }

    #header .navbar-nav.menu-white {
        display: block;
        background: #fff;
        padding-left: 0;
        margin-top: 10px !important;
    }

    #header .navbar-nav.menu-white .localidad {
        background-repeat: no-repeat;
        background-position: 21px 15px;
        padding-left: 27px;
        margin-right: 10px;
        background-size: 20px 20px;
    }

    #header .navbar-nav.menu-white .ingresar {
        background-repeat: no-repeat;
        background-position: 21px 15px;
        padding-left: 27px;
        margin-right: 10px;
        background-size: 20px 20px;
    }

    #header .navbar-nav.menu-white .ingresar a {
        color: #e82429 !important;
    }

    #header .navbar-nav.menu-white .chat {
        background-color: #e32130;
        float: left;
        width: 100%;
        text-align: left;
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: 90% 17px;
        letter-spacing: 0;
    }

    #header .navbar-nav.menu-white .chat a {
        color: #fff;
    }

    #header .navbar-nav.menu-white .llamar {
        background-color: #cb1b28;
        float: left;
        width: 100%;
        text-align: left;
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: 90% 17px;
        letter-spacing: 0;
        margin-bottom: 5px;
    }

    #header .navbar-nav.menu-white .llamar a {
        color: #fff;
    }

    #header .navbar-nav > li {
        float: left;
        width: 100%;
        text-align: left;
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: 90% 17px;
        letter-spacing: 0;
    }

    #header .navbar-default .navbar-nav.menu-white > li > a {
        padding-left: 23px;
        line-height: 25px;
    }

    #header .navbar-default .navbar-nav > li > a:hover {
        background: none;
    }

    #header .nav-tabs > li {
        background-color: #cacaca;
        font-size: 18px;
        letter-spacing: 1.6px;
        line-height: 25px;
        width: 50%;
        text-align: center;
        margin-bottom: -1px;
    }

    #header .nav-tabs > li > a {
        color: #fff;
        border: 0;
        border-radius: 0;
        padding: 14px;
        margin-right: 0;
    }

    #header .nav-tabs > li > a:hover {
        border: 0;
        background-color: #a0a0a0;
    }

    #header .nav-tabs > li > a:focus {
        border: 0;
    }

    #header .nav-tabs > li:focus,
    #header .nav-tabs > li:hover {
        border: 0;
    }

    #header .nav-tabs > li.active {
        border: 0;
    }

    #header .nav-tabs > li.active > a {
        color: #0047b4;
        border: 0;
    }

    #header .nav-tabs > li.active > a:focus,
    #header .nav-tabs > li.active > a:hover {
        border: 0;
        color: #0047b4;
    }

    #header .nav-tabs > li:first-child.active > a {
        color: #e02224;
        border: 0;
    }

    #header .tab-content .navbar-nav li {
        background-color: #fff;
        background-image: none;
        margin-right: 0;
    }

    #header .tab-content .navbar-nav li a {
        color: #717171;
    }

    #header .tab-content .navbar-nav li .productos-box-logo {
        color: #f24041;
        letter-spacing: 0.8px;
        line-height: 20px;
        text-transform: uppercase;
        font-weight: 600;
    }

    #header .tab-content .navbar-nav li .productos-box-logo img {
        vertical-align: bottom;
    }

    #header .nav-nivel2 {
        display: none;
        right: -100%;
        opacity: 1;
        width: 100%;
        position: absolute !important;
        top: 0;
    }

    #header .nav-nivel2 .li-flow {
        background: #fff;
    }

    #header .nav-nivel2 .li-flow a {
        border-left: 6px solid #57dfb7;
        padding-left: 15px !important;
    }

    #header .nav-nivel2 .li-ondemand {
        background: #fff;
    }

    #header .nav-nivel2 .li-ondemand a {
        color: #4b2e81 !important;
        border-left: 6px solid #4b2e81;
        padding-left: 15px !important;
    }

    #header .nav-nivel2 .li-play {
        background: #fff;
    }

    #header .nav-nivel2 .li-play a {
        color: #1e1e1f !important;
        border-left: 6px solid #1e1e1f;
        padding-left: 15px !important;
    }

    #header .nav-nivel2 .li-series {
        background: #fff;
    }

    #header .nav-nivel2 .li-series a {
        color: #ef3d37 !important;
        border-left: 6px solid #ef3d37;
        padding-left: 15px !important;
    }

    #header .nav-nivel2 .li-miradas {
        background: #fff;
    }

    #header .nav-nivel2 .li-miradas a {
        color: #e76302 !important;
        border-left: 6px solid #fce442;
        padding-left: 15px !important;
    }

    #header .nav-nivel2 .li-clasico {
        background: #fff;
    }

    #header .nav-nivel2 .li-clasico a {
        color: #717171 !important;
        border-left: 6px solid #f80;
        padding-left: 15px !important;
    }

    #header .nav-nivel2 .li-digital {
        background: #fff;
    }

    #header .nav-nivel2 .li-digital a {
        color: #717171 !important;
        border-left: 6px solid #f05323;
        padding-left: 15px !important;
    }

    #header .nav-nivel2 .li-hd {
        background: #fff;
    }

    #header .nav-nivel2 .li-hd a {
        color: #717171 !important;
        border-left: 6px solid #e12223;
        padding-left: 15px !important;
    }

    #header .nav-nivel2 .li-3m {
        background: #fff;
    }

    #header .nav-nivel2 .li-3m a {
        color: #717171 !important;
        border-left: 6px solid #00a2e3;
        padding-left: 15px !important;
    }

    #header .nav-nivel2 .li-6m {
        background: #fff;
    }

    #header .nav-nivel2 .li-6m a {
        color: #717171 !important;
        border-left: 6px solid #0164b7;
        padding-left: 15px !important;
    }

    #header .nav-nivel2 .li-12m {
        background: #fff;
    }

    #header .nav-nivel2 .li-12m a {
        color: #717171 !important;
        border-left: 6px solid #21388f;
        padding-left: 15px !important;
    }

    #header .nav-nivel2 .li-25m {
        background: #fff;
    }

    #header .nav-nivel2 .li-25m a {
        color: #717171 !important;
        border-left: 6px solid #141547;
        padding-left: 15px !important;
    }

    #header .nav-nivel2 .li-50m {
        background: #fff;
    }

    #header .nav-nivel2 .li-50m a {
        color: #717171 !important;
        border-left: 6px solid #141547;
        padding-left: 15px !important;
    }

    #header .nav-nivel2 > li {
        background: #fff;
        background-image: none;
    }

    #header .nav-nivel2 .search-form-mobile,
    #header .nav-nivel2 .localidad-form-mobile {
        padding: 0;
        margin-bottom: 1px;
        margin-top: 39px;
    }

    #header .nav-nivel2 .search-form-mobile .form-control,
    #header .nav-nivel2 .localidad-form-mobile .form-control {
        width: 100%;
        height: 60px;
        padding-left: 50px;
        border: none;
        background-repeat: no-repeat;
        background-position: 20px 21px;
        background-size: 20px;
        padding-top: 11px;
    }

    #header .nav-nivel2 ul.search-mobile-results > li.result,
    #header .nav-nivel2 ul.localidad-mobile-results > li.result {
        background: transparent;
        background-image: none;
        float: left;
        width: 100%;
        text-align: left;
        letter-spacing: 0;
    }

    #header .nav-nivel2 ul.search-mobile-results > li.result :hover,
    #header .nav-nivel2 ul.localidad-mobile-results > li.result :hover {
        color: #00b0ea;
    }

    #header .nav-nivel2 ul.search-mobile-results > li.result a,
    #header .nav-nivel2 ul.localidad-mobile-results > li.result a {
        display: inline-block;
        color: #555;
        padding-left: 20px;
        font-size: 14px;
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 25px;
        transition: background-color 250ms linear;
        font-family: "Roboto-regular";
    }

    #header .nav-nivel2 ul.search-mobile-results > li.result.active,
    #header .nav-nivel2 ul.localidad-mobile-results > li.result.active {
        background: #f03229;
    }

    #header .nav-nivel2 .items-cuenta-mobile {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    #header .nav-nivel2 .items-cuenta-mobile li.result {
        padding-left: 40px;
    }

    #header .nav-nivel2 .items-cuenta-mobile li.result a {
        font-size: 21px;
        line-height: 25px;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    #header .nav-nivel2 .items-cuenta li.result.micuenta-datos {
        background-repeat: no-repeat;
        background-position: 20px 18px;
        background-size: 20px 20px;
    }

    #header .nav-nivel2 .items-cuenta li.result.micuenta-pagos {
        background-repeat: no-repeat;
        background-position: 20px 18px;
        background-size: 20px 20px;
    }

    #header .nav-nivel2 .items-cuenta li.result.micuenta-servicios {
        background-repeat: no-repeat;
        background-position: 20px 18px;
        background-size: 20px 20px;
    }

    #header .nav-nivel2 .items-cuenta li.result.micuenta-tramites {
        background-repeat: no-repeat;
        background-position: 20px 18px;
        background-size: 20px 20px;
    }

    #header .nav-nivel2 .items-cuenta li.result.micuenta-cerrar {
        background-repeat: no-repeat;
        background-position: 20px 18px;
        background-size: 20px 20px;
    }

    #header .nav-nivel2 .search-form-mobile {
        padding: 0 5px 0 20px;
        margin-bottom: 1px;
    }

    #header .nav-nivel2 .search-form-mobile i {
        font-size: 17px;
        padding-top: 7px;
    }

    #header .nav-nivel2 ul.search-mobile-results {
        display: none;
    }

    #header .nav-nivel2 ul.search-mobile-results > li.result {
        background: transparent;
        background-image: none;
        float: left;
        width: 100%;
        text-align: left;
        letter-spacing: 0;
    }

    #header .nav-nivel2 ul.search-mobile-results > li.result .result-title {
        color: #fff;
        background: #545552;
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 20px;
        display: block;
        text-transform: uppercase;
        text-align: left;
        font-size: 16px;
        cursor: default;
    }

    #header .nav-nivel2 ul.search-mobile-results > li.result.active {
        background: #f03229;
    }

    #header .navbar-default .navbar-nav > .active > a {
        color: #fff;
        background: #f03229;
    }

    #header .navbar-default .navbar-nav > .active > a:focus,
    #header .navbar-default .navbar-nav > .active > a:hover {
        color: #fff;
        background: #f03229;
    }

    #header .fusion.neotabs .estasen {
        overflow: auto;
        font-weight: bold;
        position: relative;
        width: 100%;
        padding: 15px 30px 15px 20px;
        border-top: 1px solid #eee;
        color: #555;
    }

    #header .fusion .navbar-nav.menu-white {
        margin-top: 0 !important;
    }

    #header .fusion .navbar-nav.menu-white .localidad {
        padding-left: 10px;
        padding-right: 30px;
        width: 100%;
    }

    #header .fusion .estasen ul li.first a {
        margin-top: -3px;
    }

    #header .fusion .estasen ul li.first a img {
        top: 15px;
        right: 27%;
    }

    #header .fusion .tab-pane {
        margin-left: 6px;
    }

    #header .fusion .tab-pane.fade.in.active h4.title {
        margin-top: 20px;
    }

    #header .fusion #navbar {
        width: 100%;
        z-index: 5;
        margin: 0;
        padding: 0;
    }

    #header .fusion .cd-logo {
        margin-top: 14px;
    }

    #header .fusion .cd-logo a {
        left: 0;
        max-width: 100%;
    }

    #header
        .fusion
        .content-mobile-localidades
        ul.nav.navbar-nav.nav-nivel1.menu-white.hidden-lg::before {
        width: 20px;
        height: 20px;
        position: absolute;
        background-size: 18px;
        background-repeat: no-repeat;
        top: 13px;
        left: 15px;
        padding: 2px 3px;
        font-size: 14px;
        color: #888;
    }

    #header
        .fusion.not-logged
        .content-mobile-localidades
        ul.nav.navbar-nav.nav-nivel1.menu-white.hidden-lg::before {
        left: 15px;
    }

    #header .nav-nivel2 .search-form-mobile .form-control,
    #header .nav-nivel2 .localidad-form-mobile .form-control {
        background-position: 0 21px;
    }

    #header .navbar.navbar-default.navbar-fixed-top.neotabs.fusion {
        height: auto;
    }

    #header .fusion .banner-container {
        margin: 45px 0 0 0;
        text-align: center;
    }

    #header .fusion .banner-container img {
        max-width: 380px;
        width: 100%;
    }

    #header .fusion .neomobile h4 {
        text-transform: uppercase;
        margin: 0 0 0 27px;
        padding: 15px 0;
        color: #767676 !important;
    }

    #header .fusion .neomobile .tab-content > .active {
        padding-left: 15px;
    }

    #header .fusion .neomobile .separator {
        margin: 10px 0;
        width: 151px;
        border-color: #ccc;
        border-top: 0;
    }

    #header .fusion .neomobile .cyber-monday {
        background-color: #30007e;
        margin-left: 10px;
    }

    #header .fusion .neomobile .left-line-wrapper h4,
    #header .fusion .neomobile ul.principal,
    #header .fusion .neomobile ul.adicional {
        margin-left: 25px;
    }

    #header .fusion .neomobile #ayuda,
    #header .fusion .neomobile #mi-cuenta {
        padding-left: 20px;
    }

    #header .fusion .neomobile #ayuda h4,
    #header .fusion .neomobile #mi-cuenta h4 {
        margin-left: 0;
    }

    #header .fusion .neomobile #ayuda ul.principal,
    #header .fusion .neomobile #mi-cuenta ul.principal,
    #header .fusion .neomobile #ayuda ul.adicional,
    #header .fusion .neomobile #mi-cuenta ul.adicional {
        margin-left: 0;
    }

    #header .fusion .neomobile #ayuda .apps-img,
    #header .fusion .neomobile #mi-cuenta .apps-img {
        margin: 50px 0;
        overflow: auto;
    }

    #header .fusion .neomobile #ayuda .apps-img img,
    #header .fusion .neomobile #mi-cuenta .apps-img img {
        width: 160px;
        height: 50px;
    }

    #header .fusion .neomobile #ayuda .mi-cuenta-banner,
    #header .fusion .neomobile #mi-cuenta .mi-cuenta-banner {
        margin-bottom: 60px;
    }

    #header .fusion .neomobile li {
        padding: 15px 0;
    }

    #header .fusion .neomobile .dsk-title {
        font-weight: normal;
    }

    #header .fusion .neomobile .dsk-title.cablevisión {
        color: #00b0ea;
    }

    #header .fusion .neomobile .dsk-title.fibertel {
        color: #225380;
    }

    #header .fusion .neomobile .dsk-title.personal {
        color: #00b0ea;
    }

    #header .fusion.neotabs .navbar-nav.nav-nivel1 li a {
        height: auto;
    }

    #header .fusion.neotabs .navbar-nav.nav-nivel1 li a::after {
        display: none;
    }

    #header .fusion .container.menu-cont.second-nav {
        height: auto;
    }

    #header .fusion .c-baja .menu-top-name,
    #header .fusion .buscar-top,
    #header .fusion .estasbtn {
        display: none;
    }

    #header .fusion .menu-nivel1 li a i {
        display: none;
    }

    #header .fusion .estasbtn-mobile {
        overflow: auto;
        padding: 15px 30px 15px 20px;
        display: block;
    }

    #header .fusion .estasbtn-mobile .unbutton {
        padding: 0 0 10px 0;
        margin-top: 10px;
    }

    #header .fusion .estasbtn-mobile .dropdown-menu-estasen-mobile {
        display: none;
    }

    #header .fusion .estasbtn-mobile .dropdown-menu-estasen-mobile.open {
        display: block !important;
        width: 100%;
        position: relative;
        padding-left: 40px;
        z-index: 8;
    }

    #header .fusion .estasbtn-mobile .dropdown-menu-estasen-mobile.open li {
        padding: 15px 0;
    }

    #header .fusion .estasbtn-mobile .dropdown-menu-estasen-mobile.open li a {
        background-color: #fff;
        color: #555;
        padding: 5px 20px;
        text-transform: none;
        text-transform: initial;
    }

    #header .fusion .estasbtn-mobile .dropdown-menu-estasen-mobile.open li a:hover {
        color: #00b0ea;
    }

    #header .fusion .menu-top.c-baja {
        height: auto;
    }

    #header .fusion .cd-logo {
        margin-top: 8px;
        overflow: auto;
        width: 80%;
        float: left;
    }

    #header .fusion .cd-logo img {
        overflow: auto;
        position: relative;
        left: 0;
        top: 0;
        margin-top: 7px;
        max-width: 325px;
        width: 100% !important;
        height: auto !important;
    }

    #header .fusion .content-mobile-localidades {
        height: auto;
    }

    #header .fusion a#localidad-btn {
        width: 100%;
        padding-left: 0;
        text-align: left;
        padding-top: 12px;
        padding-bottom: 12px;
        height: auto;
        color: #888;
    }

    #header .fusion .navbar.navbar-default.navbar-fixed-top.neotabs.fusion {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
        border-bottom: 0;
    }

    #header .fusion .menu-bar-mobile {
        float: right;
        font-size: 24px;
        height: auto;
        padding: 13.9px 16px 10.1px;
    }

    #header .fusion #nav-icon span {
        display: block;
        position: relative;
        height: 2px;
        width: 28px;
        margin-top: 6px;
        background: #333;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: 0.25s ease-in-out;
        -moz-transition: 0.25s ease-in-out;
        -o-transition: 0.25s ease-in-out;
        transition: 0.25s ease-in-out;
    }

    #header .fusion .fusion #nav-icon.open span {
        width: 24px;
    }

    #header .fusion #nav-icon.open span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    #header .fusion #nav-icon.open span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        margin-top: -2px;
    }

    #header .fusion #nav-icon.open span:nth-child(4) {
        top: 18px;
        width: 0;
        left: 50%;
    }

    #header .fusion .fusion #nav-icon.open span:first-child,
    #header .fusion .fusion #nav-icon.open span:last-child {
        width: 0;
    }

    #header .fusion #nav-icon.open span:nth-child(1) {
        top: 18px;
        width: 0;
        left: 50%;
    }

    #header .fusion .loginbox {
        width: 100%;
        background: #f5f5f5;
        height: auto;
    }

    #header .fusion .loginbox .say-hello {
        padding-top: 25px;
    }

    #header .fusion .loginbox .say-hello i {
        font-size: 45px;
        margin: 10px 12px 40px 12px;
    }

    #header .fusion .loginbox .say-hello span {
        padding: 15px 0;
        float: left;
        color: #333;
    }

    #header .fusion .loginbox .cv-ingresar {
        color: #00b0ea;
        padding-top: 0;
        padding-bottom: 30px;
    }

    #header .fusion .back-navigation-search,
    #header .fusion .back-navigation-programacion {
        text-align: center;
        border-bottom: 1px solid #e4e4e4;
        height: 56px;
    }

    #header .fusion .back-navigation-search i,
    #header .fusion .back-navigation-programacion i {
        margin-top: 3px;
        margin-left: 2px;
        width: 6px;
        height: 20px;
        font-size: 18px;
    }

    #header .fusion .nav-nivel2 .title-wrapper {
        margin-top: 0;
        padding: 0;
    }

    #header .fusion .nav-nivel2 .title-wrapper.line {
        margin-top: 44px;
    }

    #header .fusion #search-input-mobile {
        display: block;
        float: left;
        border: none;
        margin-top: 0;
        font-size: 21px;
        visibility: inherit;
        padding-left: 30px;
        width: calc(65%);
        box-shadow: none;
        height: auto;
        padding: 1px 6px;
        border-radius: 0;
    }

    #header .fusion #search-input-mobile .search-input-container {
        height: auto;
        overflow: auto;
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
    }

    #header .fusion #search-input-mobile .search-input-container i {
        font-size: 19px;
    }

    #header .fusion .navbar-collapse.collapse.navbar-left.active-geolocalization {
        min-height: 100vh !important;
    }

    #header .fusion .active-geolocalization {
        top: 0 !important;
        opacity: 1;
        display: block !important;
    }

    #header .fusion .active-geolocalization #localidad-menu-mobile {
        top: 0;
        left: 0;
        z-index: 1;
        position: fixed;
    }

    #header .fusion .active-geolocalization #localidad-menu-mobile .back-navigation-localidad {
        padding: 5px 15px;
        margin-top: 15px;
        margin-bottom: 25px;
    }

    #header .fusion .active-geolocalization #localidad-menu-mobile .back-navigation-localidad a {
        padding: 0;
    }

    #header .fusion .active-geolocalization #localidad-menu-mobile .back-navigation-localidad a i {
        font-size: 18px;
        padding-left: 0;
        color: #888;
    }

    #header .fusion .active-geolocalization #localidad-menu-mobile .search-input-container {
        height: auto;
        overflow: auto;
        border-bottom: 1px solid #eee;
        padding-bottom: 10px;
    }

    #header .fusion .active-geolocalization #localidad-menu-mobile .search-input-container i {
        font-size: 19px;
    }

    #header .fusion .active-geolocalization #localidad-menu-mobile #localidad-input-mobile {
        display: block;
        float: left;
        border: none;
        border-left: 1px solid #002b53;
        margin-top: 0;
        visibility: inherit;
        padding-left: 30px;
        width: calc(65%);
        box-shadow: none;
        height: auto;
        padding: 1px 6px;
        border-radius: 0;
    }
}

@media (max-width: 768px) and (max-width: 768px) {
    #header .fusion.neotabs .navbar-nav.nav-nivel1 li a {
        font-size: 16px;
    }
}

@media (max-width: 768px) and (max-width: 768px) {
    #header .fusion .navbar-nav.menu-white .localidad {
        padding-left: 16px;
    }
}

@media (max-width: 768px) and (max-width: 768px) {
    #header .fusion .banner-container {
        margin: 0 0 45px 0;
        padding: 45px 0;
    }
}

@media (max-width: 768px) and (max-width: 768px) {
    #header .fusion .menu-nivel1 {
        height: 273px !important;
    }
}

@media only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
    #header #atencion-al-cliente-mobile .help-center {
        margin-bottom: 120px !important;
    }
}

#header .left-line-wrapper {
    position: relative;
    width: 100%;
    padding-left: 22px;
    margin-top: 46px;
    margin-bottom: 90px;
}

#header .left-line-wrapper:before {
    content: "";
    width: 6px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 15px;
}

#header .bg-cablevisión:before {
    background: #57dfb7;
}

#header .bg-fibertel:before {
    background: #225380;
}

#header .bg-personal:before {
    background: #00b0ea;
}

#header i {
    object-fit: contain;
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
}

#header #localidad-form {
    height: auto;
}

@media (max-width: 768px) {
    #header {
        clear: both;
        box-sizing: border-box;
        height: 65px !important;
        width: 100%;
    }

    #header .fusion nav.menu-top.c-baja {
        height: 65px !important;
        padding-top: 13px !important;
    }

    #header .fusion nav.menu-top.c-baja .container .row .cd-logo {
        margin: 0;
    }

    #header .fusion nav.menu-top.c-baja .container .row .cd-logo a {
        margin: -7px;
    }

    #header .fusion nav.menu-top.c-baja .container .row .cd-logo a img {
        margin: 0 !important;
    }

    #header .fusion a#localidad-btn {
        padding-top: 9px !important;
    }

    #header .fusion .loginbox {
        padding: 26px 0 28px 17px;
    }

    #header .fusion .loginbox .say-hello {
        padding: 0;
    }

    #header .fusion .loginbox .say-hello i {
        padding: 0;
        margin: 0;
    }

    #header .fusion .loginbox .say-hello span {
        padding: 5px 0 0 15px;
    }

    #header .fusion .loginbox .user-login {
        padding: 40px 0 0 0;
    }

    #header .fusion .loginbox .user-login a {
        padding: 0;
        color: #00b0ea;
    }

    #header .fusion .loginbox .user-register {
        padding: 30px 0 0 0;
    }

    #header .fusion .loginbox .user-register a {
        padding: 0;
    }

    #header .fusion ul.nav.navbar-nav.nav-nivel1.menu-nivel1 {
        padding: 20px 0 41px;
        height: auto !important;
    }

    #header .fusion .estasbtn-mobile {
        padding: 25px 16px 85px;
    }

    #header #nav-icon {
        padding: 0 16px 0 0;
    }

    #header #nav-icon span:nth-child(1) {
        margin-top: 5px;
    }

    #header #nav-icon span:nth-child(2),
    #header #nav-icon span:nth-child(3) {
        margin-top: 8px;
    }

    #header #nav-icon span:nth-child(3) {
        margin-bottom: 5px;
    }

    #header #nav-icon span i {
        font-size: 32px;
        color: #000;
    }

    #header .content-mobile-localidades {
        height: 40px !important;
    }

    #header .content-mobile-localidades ul {
        height: 40px !important;
    }

    #header .content-mobile-localidades ul li {
        height: 40px !important;
    }

    #header .content-mobile-localidades ul li a#localidad-btn {
        padding-bottom: 0;
    }

    #header
        .navbar-collapse
        .collapse
        .navbar-left
        .submenu
        .menuopen
        nav
        navbar-nav
        nav-nivel1
        menu-white
        visible-xs {
        height: 40px;
    }

    #header .greeting-user-ico {
        margin-left: 16px;
        vertical-align: super;
    }

    #header .loggedIn-menu-item {
        margin-top: 20px;
        margin-bottom: 20px;
        padding-left: 1px;
        padding-top: 0 !important;
        padding-bottom: 0;
    }

    #header .loggedIn-menu-ico {
        font-size: 16px;
        width: 15px;
    }

    #header .loggedIn-menu-span {
        margin-left: 15px;
    }

    #header .salir {
        color: #333;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        margin-top: 18px;
    }
}
