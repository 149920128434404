.fa-white {
    color: #FFFFFF;
}

.b-button {
    border-bottom: solid 1px #cccccc;
    width: 95%;
  
}

.text_nav_mobile{
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.25);
    font-family: Lato-Bold;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #333333 !important;
    margin-left: 2%;

}