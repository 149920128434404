﻿@font-face {
  font-family: "FontAwesome";
  src: url("../fonts/fontawesome-webfont.woff?v=4.7.0")
      format("woff");
  font-weight: normal;
  font-style: normal;
} 
@font-face {
  font-family: "FontAwesomePro";
  src: url("../fonts/fa-light-300.woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "FontAwesomeProBrand";
  src: url("../fonts/fa-brands-400.woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lato-Black";
  src: url("../fonts/Lato-Black.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lato-Bold";
  src: url("../fonts/Lato-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Lato-Light";
  src: url("../fonts/Lato-Light.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lato-Regular";
  src: url("../fonts/Lato-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Bold";
  src: url("../fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Medium";
  src: url("../fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Roboto-Regular";
  src: url("../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Tahoma-Regular";
  src: url("../fonts/Tahoma-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Tahoma-Bold";
  src: url("../fonts/Tahoma-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}