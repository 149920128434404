#pre-footer .prefooter-row {
    background-color: #ecede9;
    text-align: center
}

#pre-footer .prefooter-data-row {
    padding-top: 51px;
    position: relative;
    min-height: 362px
}

#pre-footer .prefooter-title {
    color: #333
}

#pre-footer .prefooter-title-box {
    height: 60px;
    padding-right: 10%
}

#pre-footer .prefooter-link {
    color: #767676 !important;
    padding: 2px 0
}

#pre-footer .prefooter-link a {
    text-decoration: none
}

#pre-footer .col-centered {
    display: inline-block;
    float: none;
    text-align: left;
    vertical-align: top;
    margin-right: -4px
}

#pre-footer .col-centered a {
    text-decoration: none;
    display: block
}

#pre-footer .prefooter-data-mbl-row {
    margin-bottom: 40px
}

@media (max-width:768px) {
    #pre-footer .prefooter-data-mbl-row {
        margin-bottom: 35px;
        padding: 5px 0
    }
}

#pre-footer .prefooter-data-mbl-header {
    padding: 25px 0;
    color: #333
}

@media (max-width:768px) {
    #pre-footer .prefooter-data-mbl-header {
        padding: 36px 26px 30px 26px;
        text-align: left
    }
}

#pre-footer .prefooter-data-mbl-btn {
    background: transparent;
    border: 0;
    outline: 0;
    width: 98%;
    text-align: left;
    position: relative;
    padding-left: 26px
}

@media (max-width:768px) {
    #pre-footer .prefooter-data-mbl-btn {
        padding: 0
    }
}

#pre-footer .prefooter-data-mbl-angle {
    height: 16px;
    font-size: 18px;
    color: #000;
    position: absolute;
    right: 22px;
    transform: translateY(-50%);
    top: 50%
}

@media (max-width:768px) {
    #pre-footer .prefooter-data-mbl-angle {
        right: -5px
    }
}

#pre-footer .prefooter-data-mbl-body {
    padding-left: 23px;
    text-align: left;
    margin: 0 -15px
}

@media (max-width:768px) {
    #pre-footer .prefooter-data-mbl-body {
        margin: 0;
        padding: 0 26px 0 26px
    }
}

#pre-footer .prefooter-data-mbl-detail {
    display: inline-block;
    padding-bottom: 19px;
    color: #555
}

#pre-footer .prefooter-data-mbl-subdetail {
    padding: 10px 0;
    color: #888
}

#pre-footer #prefooterOne .prefooter-data-mbl-body {
    border-bottom: solid #ccc 1px
}

#pre-footer #prefooterTwo .prefooter-data-mbl-body {
    padding-left: 32px;
    border-bottom: solid #ccc 1px
}

@media (max-width:768px) {
    #pre-footer #prefooterTwo .prefooter-data-mbl-body {
        padding-left: 26px
    }
}

#pre-footer #prefooterTwo .prefooter-data-mbl-subdetail {
    padding-left: 16px
}

#pre-footer .prefooter-detail {
    padding-right: 10%
}