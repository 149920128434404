.border-top-solid {
    border-top: 1px solid #ccc;
}


.m-35 {
    margin-bottom: 35px;
}

.text-legal{
    text-align: left !important;
}