.grey {
    background-color: #1b1b1d
}

.text-plan {
    font-size: 14px;
}

.title-center-xs{
    display        : flex;
    justify-content: center;
    align-items    : center;
}


.margin-top-15-xs{
    margin-top: 16px;
}