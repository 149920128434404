.banner-sm {
  padding-top        : 40px;
  padding-bottom     : 136px;
  min-height         : 350px;
  background-position: center;
  background-size    : cover;
  background-image   : url("../../../assets/images/disney_banner2.png");
}

.banner-xs {
  padding-top     : 30px;
  min-height      : 300px;
  background-size : cover;
  background-image: linear-gradient(to bottom, #060f18, #134aa0);
}

.navbar-items {
  display: flex;
  justify-content: center;
  text-align: center;
}

.nav > li > a:hover,
.nav > li > a:focus {
  text-decoration: none;
  background-color: transparent;
  text-shadow: 1px 1px 0 #fff;
}

a:hover {
  color: #c70005 !important;
  text-shadow: 1px 1px 0 #fff;
}

.nav_bar_Uy {
  padding: 10px;
  border-radius: 60px;
  top: 17.5px;
  width                     : 1186px;
  height                    : 79px;
  // margin: 0 0 0 4%;
  // padding: 18px 268px 19px 351px;
  background-image: linear-gradient(to top, #bac2d4, #e4e4e4, #fcfcfc, #ffffff);
}

.nav_bar_1 {
  margin-left: 1.5px;
  position: relative;
  top: 29px;
  margin-left: 60px;
  border-radius: 10px;
  width                     : 1220px;
  height: 56px;
  background-image: linear-gradient(to top, #9da3b7, #e4e4e4, #fcfcfc, #ffffff);
  
}

.nav_bar_2 {
  bottom: 35px;
  position: relative;
  margin-left: 63px;
  width                     : 1210px;
  height: 72px;
  border-radius: 30% / 12%;
  background-image: linear-gradient(to top, #9da3b7, #e4e4e4, #fcfcfc, #ffffff);
  //background-color: red;
}

.row_navbar {
  display        : flex;
  justify-content: center;
  align-items    : center;
}

.Rectangle-464 {
  width           : 360px;
  height          : 64px;
  padding         : 0 0 0 11px;
  background-image: linear-gradient(to top, #9da3b7, #e4e4e4, #fcfcfc, #ffffff);
}

.logo_Uy {
  position: relative;
  right: 6px;
  width        : 166px;
  height       : 72px;
}
.nohover {
  padding-right: 25px;
}

.nohover2 {
  padding-right: 90px;
}

.margin-left {
  margin-left: 90px;
}

.label_nav {
  width         : 80px;
  height        : 22px;
  text-shadow   : 1px 1px 0 rgba(255, 255, 255, 0.25);
  font-family   : Lato;
  font-size     : 18px;
  font-weight   : bold;
  font-stretch  : normal;
  font-style    : normal;
  line-height   : normal;
  letter-spacing: normal;
  text-align    : center;
  color         : #333333;
  padding-top   : 30px;
}

.padleft-200 {
  padding-left: 200px;
}

.button_Uy {
  border-radius   : 5px !important;
  background-color: #80cfe9;
  color           : #203840 !important;
  padding         : 6px 16px !important;
  min-width       : 216px;

}
#nav-banner .banner-sm ul{
  margin: revert !important
}

.padding-t-15 {
  padding-top: 15px;
}

.margin-bottom-58{
margin-bottom: 58px;
}


@media (max-width:1220) {

  .nav_size {
    width: 100%;
  }

}

@media (min-width:1221) {

  .nav_size {
    width: 1202px !important;
  }
}