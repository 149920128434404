@import-normalize;
@import "assets/styles/fonts.scss";
@import "assets/styles/style.scss";
@import "assets/styles/base.scss";
@import "assets/styles/common.scss";
@import "assets/styles/custom.scss";

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

.outline{
    outline: 0px !important;
}