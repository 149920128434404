#footer-full footer.footer.fusion .footer-white {
    /* padding: 30px 0; */
    background-color: #f5f5f5;
}

#footer-full .footer-white .logo-triple {
    padding-top: 20px;
    cursor: pointer
}

#footer-full .no-margin {
    margin: 0
}

#footer-full .no-padding {
    padding: 0
}
#footer-full .no-padding .background-white {
    padding: 0;
    height: 200px;
    line-height: 14px;
    padding-top: 24px;
    background:transparent  url(../../../assets/images/category-nav-back.png) 0 0 no-repeat;
}

#footer-full .padding-top-48 {
    padding-top: 48px
}

#footer-full footer.footer.fusion .footer-cream {
    border-top: 1px solid #ccc;
    background-color: #f5f5f5;
    position: relative
}

#footer-full footer.footer.fusion .footer-cream .container {
    padding: 50px 0
}

#footer-full .footer-cream .footer-link-text {
    padding: 0 30px 25px 0
}

#footer-full .footer-cream .footer-link-text>a {
    color: #767676 !important
}

#footer-full .footer-link-text {
    padding: 0 30px 25px 0
}

#footer-full .footer-link-text>a {
    color: #ccc !important;
    opacity: unset !important
}

#footer-full .footer-link-text>a:hover {
    opacity: 1
}

/* #footer-full .footer-phone {
    color: #333;
    text-decoration: none
} */
.center-social{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.text-social {
    font-size: 12px;
    font-family: Roboto-Regular;
    color:#666666;
    font-weight: normal;
  }
  .text-social-mobile {
    font-size: 10.7px;
    font-family: Roboto-Regular;
    color:#666666;
    font-weight: normal;
  }
.line {
    width: 80%;
    height: 0;
    border: 1px solid #C4C4C4;
    display: flex;
    margin-bottom: 24px;
    margin-top:24px;
  }
.padding-icon{
    padding: 5px;
}
.color-customer-social{
    color:#333333 !important
}
#footer-full footer.footer.fusion .more-info {
    position: absolute;
    width: 168px;
    height: 39px;
    top: -37px;
    left: 50%;
    margin-left: -84px;
    cursor: pointer;
    padding-inline-start: 0;
    outline: 0
}

#footer-full footer.footer.fusion .more-info li {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    margin: 0;
    padding: 11px 29px;
    border: 1px solid #ccc;
    display: inline-block;
    letter-spacing: 0;
    line-height: 1
}

#footer-full footer.footer.fusion .more-info li.up {
    background: #f5f5f5;
    border-bottom: 1px solid #f5f5f5
}

#footer-full footer.footer.fusion .more-info li.down {
    background: #1b1b1d;
    border-bottom: 1px solid #1b1b1d;
    color: #ccc
}

#footer-full footer.footer.fusion .more-info li .up span,
#footer-full footer.footer.fusion .more-info li .down span {
    margin: 0 auto
}

#footer-full footer.footer.fusion .more-info .more-info-ico {
    position: relative;
    left: 80px;
    top: -12px;
    color: #ccc
}

#footer-full footer.footer.fusion .more-info .fa-angle-up.more-info-ico {
    color: inherit
}

/* #footer-full footer.footer.fusion .footer-title {
    margin: 0 0 32px 0
} */

#footer-full footer.footer.fusion ul.no-list-style {
    list-style: none;
    padding: 0
}

/* #footer-full footer.footer.fusion ul li {
    padding-bottom: 20px
} */

#footer-full footer.footer.fusion ul li.padding-top-20 {
    padding-top: 20px
}

#footer-full footer.footer.fusion .logos .stores {
    margin: 0 0 16px 0;
    display: block
}

#footer-full footer.footer.fusion a.dgdpc,
#footer-full footer.footer.fusion p.dgdpc {
    line-height: 1.71;
    margin-bottom: 0
}

#footer-full a:link {
    text-decoration: none
}
#footer-full footer.footer.fusion  > li > a:hover{
    text-decoration: none !important;
}

.footer-title {
    color: #666666 !important
}
.item-footer-grey > a:visited{
    color: #666666 !important
} 
#footer-full footer.footer.fusion  > li > a:visited{
    color: #666666 !important
}
#footer-full a:link{
    color: #666666 !important
}
#footer-full a{
    color: #666666 !important
}
#footer-full footer.footer.fusion a.dgdpc,
#footer-full footer.footer.fusion p.dgdpc,
#footer-full footer.footer.fusion p.dgdpc a {
    color: #ccc;
    margin: 0;
    display: block;
    padding-left: 38px
}

#footer-full footer.footer.fusion p.dgdpc a {
    display: inline;
    padding-left: 0
}

#footer-full footer.footer.fusion .qr-div {
    padding: 10px 0 0 30px
}

#footer-full footer.footer.fusion .qr-foot {
    display: inline-block;
    margin: 30px 10px auto;
    width: 60px;
    position: initial;
    float: left
}

#footer-full footer.footer.fusion .qr-foot img {
    margin: 0;
    height: 80px
}

#footer-full footer.footer.fusion .disclaimer-cont {
    background-color: #f5f5f5;
    padding-bottom: 30px;
    padding-top: 10px;
   /*  border-top: 1px solid #fff; */
    /* padding: 19px 0 */
}

#footer-full footer.footer.fusion .disclaimer {
  
    color: #666666
}

.d-none {
    display: none !important;
}

.d-block {
    display: block !important;
}
.color-black{
    color:black
}
.fa-facebook-square {
    color: #3b5998;
    background-image: linear-gradient( to bottom, transparent 20%, white 20%, white 93%, transparent 93% );
    background-size: 55%;
    background-position: 70% 0;
    background-repeat: no-repeat;
  }
  .fa-twitter, .fa-twitter-square {
    color: #00aced
}
.ma-bot-24{
    margin-bottom: 24px;
}
.phone-icon{
    display: inline-flex;
}
@media (max-width:767px) {
    #footer-full footer.footer.fusion .more-info li.down span {
        color: #ccc !important
    }
    #footer-full footer.footer.fusion .more-info li.down,
    #footer-full footer.footer.fusion .more-info li.up {
        width: 184px;
        height: 38px;
        margin: 0 auto;
        padding: 0;
        padding-top: 15px;
        text-align: center
    }

    #footer-full footer.footer.fusion .more-info li.down span,
    #footer-full footer.footer.fusion .more-info li.up span {
        font-display: Roboto-Medium;
        font-size: 14px;
        color: #555;
        display: table-cell;
        vertical-align: middle;
        margin: 0 auto;
        width: inherit;
        text-align: center;
        padding-bottom: 7px
    }

    #footer-full footer.footer.fusion .more-info .more-info-ico {
        left: 88px;
        font-size: 16px
    }

    #footer-full footer.footer.fusion .footer-white {
       /*  padding: 20px 20px; */
        /* background-color: #1b1b1d */
    }

    #footer-full footer.footer.fusion .disclaimer-cont {
        /* padding: 21px 22px 11px 21px */
    }
    #footer-full .no-padding .background-white{
        background: transparent;
    }
   /*  #footer-full footer.footer.fusion .media-padding-left-40 {
        padding-left: 41px
    } */

    #footer-full footer.footer.fusion .margin-top-20 {
        margin: -20px 0px 0px 0px;
    }
    #footer-full footer.footer.fusion .footer-title {
        margin: 0 0 10px 0
    }

    #footer-full .mbl-title-l-24 {
        font-size: 24px;
        font-family: Lato-Black;
        color: #333
    }
   
    #footer-full footer.footer.fusion .footer-white .logo-triple {
        padding: 0 30px 30px 30px;
        max-width: 500px;
        display: block;
        margin: auto
    }

    #footer-full footer.footer.fusion .downloadApp {
        display: block;
        margin-left: auto
    }

    #footer-full footer.footer.fusion a.dgdpc,
    #footer-full footer.footer.fusion p.dgdpc,
    #footer-full footer.footer.fusion p.dgdpc a {
        padding: 0 0 0 30px;
        line-height: 15px;
        font-size: 12px
    }

    #footer-full footer.footer.fusion .qr-foot {
        margin: 0 10px 0 0
    }

    #footer-full footer.footer.fusion p.dgdpc a {
        padding-left: 0
    }

    #footer-full footer.footer.fusion .image-container-m {
        padding-left: 28px
    }

    /* #footer-full footer.footer.fusion ul.no-list-style.footer-title-sub-mbl {
        padding-left: 20px
    } */

    #footer-full footer.footer.fusion ul li.padding-bottom-0 {
        padding-bottom: 0
    }

    /* #footer-full footer.footer.fusion .footer-col-border-top {
        padding-top: 50px;
        border-top: 1px solid #ccc
    } */

    #footer-full footer.footer.fusion .logos {
        margin-bottom: 35px
    }

    #footer-full footer.footer.fusion .logos .stores {
        width: 88px;
        height: 30px;
        margin: 0 0 32px 0;
        display: inline-block
    }

    #footer-full footer.footer.fusion .footer-span-descarga {
        padding: 0 0 12px 0
    }

    #footer-full footer.footer.fusion .qr-foot img {
        display: block;
        margin: 0 auto 10px
    }

    #footer-full footer.footer.fusion .footer-links {
        padding-left: 0
    }

    #footer-full footer.footer.fusion .disclaimer {
        text-align: left;
        line-height: 20px
    }
}